import { Component, Input } from '@angular/core';
import { Route } from 'src/app/models/route';
import { Profile } from 'src/app/models/profile';
import { isMyRoute } from 'src/app/utils/route';

@Component({
  selector: 'route-state',
  templateUrl: './route-state.component.html',
  styleUrls: ['./route-state.component.scss'],
})
export class RouteStateComponent {

  @Input() route: Route;
  @Input() profile: Profile;

  constructor(
  ) { }

  isMyRoute() {
    return isMyRoute(this.route, this.profile);
  }
}
