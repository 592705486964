import { Pipe, PipeTransform } from '@angular/core';
import { PricePipe } from './price.pipe';
import { Travel } from '../models/travel';

@Pipe({
  name: 'travelPrice'
})
export class TravelPricePipe extends PricePipe implements PipeTransform {

  constructor() {
    super();
  }

  transform(travel: Travel): any {
    const { price = 0, priceUpdatedAt = '' } = travel || {};
    if (!priceUpdatedAt) {
      return '€ - ';
    }
    return super.transform(price);
  }

}
