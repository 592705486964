import { Directive } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalHtmlDirective } from './modal-html.directive';

@Directive({
  selector: '[politicallyExposedPerson]'
})

export class PoliticallyExposedPerson extends ModalHtmlDirective {

  constructor(
    protected modal: ModalController
    ) {

    super(modal);

    this.titleKey = 'PRIVACY_POLICY';
    this.contentKeys = ['PRIVACY_POLICY_TEXT'];

  }

}
