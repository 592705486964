import { Component, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { IonInfiniteScroll, IonRefresher, Events } from '@ionic/angular';
import { ROUTE_STATE, ROUTE_TYPE } from 'src/app/constants';
import { Route } from 'src/app/models/route';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RouteParams } from 'src/app/models/routeParams';
import { Subscription } from 'rxjs';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-my-carries',
  templateUrl: './my-carries.page.html',
  styleUrls: ['./my-carries.page.scss'],
})
export class MyCarriesPage implements OnDestroy {

  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher, { static: false }) refresher: IonRefresher;

  routeStates = Object.keys(ROUTE_STATE);
  routes: Route[] = [];
  offset = 0;
  state: ROUTE_STATE = ROUTE_STATE.waiting;
  previousUrl: string;
  subs: Subscription[] = [];
  profile: Profile;

  constructor(
    public spinner: SpinnerService,
    private auth: AuthService,
    private routeService: RouteService,
    private events: Events,
    private profileService: ProfileService,
    private zone: NgZone,
  ) {

    this.profileService.getProfile().then(p => this.profile = p);

    events.subscribe('carry:upsert', ({ id }) => {
      if (!id) {
        this.state = ROUTE_STATE.waiting;
      }
      this.onTabChange();
    });

    events.subscribe('carry:delete', ({ id }) => this.onTabChange());
    events.subscribe('carry:refresh', ({ id }) => this.onTabChange());

    this.fetch();
  }

  ngOnDestroy() {
    this.events.unsubscribe('carry:upsert');
    this.events.unsubscribe('carry:delete');
    this.events.unsubscribe('carry:refresh');
  }

  async fetch() {
    if (this.spinner.spinning) { return; }

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();

      const params: RouteParams = {
        token,
        type: ROUTE_TYPE.carry,
        state: this.state,
        offset: this.offset,
      };

      const { body, headers } = await this.routeService.fetch(params);
      this.routes = this.routes.concat(body);
      this.offset += body.length;

      await this.spinner.dismiss();

      const count = parseInt(headers.get('x-total-count'), 10);
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = this.routes.length >= count;

    } catch (err) {
      this.infiniteScroll.complete();
      this.spinner.dismissWithAlertError(err);
    }

  }

  onTabChange() {
    this.zone.run(async () => {
      this.routes = [];
      this.offset = 0;

      this.refresher.complete();
      this.refresher.disabled = true;
      await this.fetch();
      this.refresher.disabled = false;
    });
  }

}
