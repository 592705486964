import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FLEET_TYPES } from '../options';
import { ModalController } from '@ionic/angular';
import { ProfileFormBaseComponent } from '../profile-form-base.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ExtLinkService } from 'src/app/services/ext-link/ext-link.service';
import moment from 'moment';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'profile-form-professional',
  templateUrl: './profile-form-professional.component.html',
  styleUrls: ['./../profile-form-base.component.scss'],
})
export class ProfileFormProfessionalComponent extends ProfileFormBaseComponent implements OnInit {

  fleetTypes = FLEET_TYPES;
  minExpiration = moment().add(1, 'day').format('YYYY-MM-DD');

  constructor(
    protected fb: FormBuilder,
    protected modal: ModalController,
    protected translate: TranslateService,
    protected alert: AlertService,
    protected extLink: ExtLinkService,
    protected storage: Storage,
  ) {
    super(fb, modal, translate, alert, extLink, storage);
  }

  ngOnInit() {
    super.ngOnInit();
    const disabled = !this.isCreating();

    this.form.addControl('vatInfo', this.fb.group({
      companyName: [{ value: '', disabled }, Validators.required],
      vatNumber: [{ value: '', disabled }, Validators.required],
      phone: [{ value: '', disabled }],
      pec: [{ value: '', disabled }, this.pecValidator],
      sdiCode: [{ value: '', disabled }, this.sdiValidator],
      companyWebsite: [{value: '', disabled}]
    }));

    this.form.addControl('professionalInfo', this.fb.group({
      licenseNumber: [{ value: '', disabled }, Validators.required],
      reaNumber: [{ value: '', disabled }, Validators.required],
      insuranceNumber: [{ value: '', disabled }],
      insuranceCompany: [{ value: '', disabled }],
      insuranceExpiration: [{ value: '', disabled }],
      fleetType: [{ value: FLEET_TYPES[0], disabled }],
      partners: [{ value: false, disabled }],
    }));

    if (this.profile) {
      const { birthDate } = this.profile;
      this.form.patchValue({
        ...this.profile,
        birthDate: birthDate ? moment(birthDate).format(this.dateFormat) : birthDate,
      });
    }

    this.setValidatorsPecSdi();
  }
}
