import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthFactory } from './services/auth/auth.factory';
import { AuthService } from './services/auth/auth.service';
import { Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateConfigService } from './services/translate/translate-config.service';
import { ProfileService } from './services/profile/profile.service';
import { ProfilePage } from './pages/profile/profile.page';
import { HomePage } from './pages/home/home.page';
import { TabsPage } from './pages/tabs/tabs.page';
import { SwgHeaderComponent } from './components/swg-header/swg-header.component';
import { CallbackPage } from './pages/callback/callback.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProfileSelectTypeComponent } from './components/profile-select-type/profile-select-type.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { SelectCityComponent } from './components/modal-select-city/select-city.component';
import { ProfileFormPrivateComponent } from './components/profile-form/profile-form-private/profile-form-private.component';
import { ProfileFormCompanyComponent } from './components/profile-form/profile-form-company/profile-form-company.component';
import { ProfileFormProfessionalComponent } from './components/profile-form/profile-form-professional/profile-form-professional.component';
import { ModalContractComponent } from './components/modal-contract/modal-contract.component';
import { AlertService } from './services/alert/alert.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SendPage } from './pages/send/send.page';
import { MySendsPage } from './pages/my-sends/my-sends.page';
import { MyCarriesPage } from './pages/my-carries/my-carries.page';
import { CarryPage } from './pages/carry/carry.page';
import { SendUpsertPage } from './pages/send-upsert/send-upsert.page';
import { ModalDateTimeComponent } from './components/modal-select-datetime/modal-datetime.component';
import { NgbModule, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { MyDatepickerI18n } from './components/modal-select-datetime/MyDatepickerI18n';
import { SelectCityDirective } from './directives/select-city.directive';
import { SelectDateDirective } from './directives/select-date.directive';
import { PrivacyPolicyDirective } from './directives/privacy-policy.directive';
import { ModalHtmlComponent } from './components/modal-html/modal-html.component';
import { TermsConditionsDirective } from './directives/terms-conditions.directive';
import { RouteCardComponent } from './components/route-card/route-card.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { RouteService } from './services/routes/route.service';
import { AuthGuard } from './services/auth/guard.service';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { TransportIconPipe } from './pipes/transport-icon.pipe';
import { RouteDetailPage } from './pages/route-detail/route-detail.page';
import { OwnerPipe } from './pipes/owner.pipe';
import { RouteStateComponent } from './components/route-state/route-state.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CarryUpsertPage } from './pages/carry-upsert/carry-upsert.page';
import { FindCarryPage } from './pages/find-carry/find-carry.page';
import { RouteNotFoundComponent } from './components/route-not-found/route-not-found.component';
import { RouteCardFoundComponent } from './components/route-card-found/route-card-found.component';
import { FindSendPage } from './pages/find-send/find-send.page';
import { CarryDetailPage } from './pages/route-found/carry-detail/carry-detail.page';
import { TransportPipe } from './pipes/transport-type.pipe';
import { CompanyGuard } from './services/profile/guard-company.service';
import { ChatPage } from './pages/chat/chat.page';
import { TravelService } from './services/travel/travel.service';
import { PreviousRouterService } from './services/previous-router/previous-router.service';
import { SendDetailPage } from './pages/route-found/send-detail/send-detail.page';
import { ProfileCreateSuccessComponent } from './components/profile-create-success/profile-create-success.component';
import { IdentityCardPage } from './pages/identity-card/identity-card.page';
import { Camera } from '@ionic-native/camera/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { IbanPage } from './pages/iban/iban.page';
import { ChatService } from './services/chat/chat.service';
import { ChatBubbleComponent } from './components/chat-bubble/chat-bubble.component';
import { PricePipe } from './pipes/price.pipe';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { NotificationService } from './services/notification/notification.service';
import { RouteWaitingComponent } from './components/route-detail/route-waiting/route-waiting.component';
import { RouteActiveComponent } from './components/route-detail/route-active/route-active.component';
import { RouteDeliveredComponent } from './components/route-detail/route-delivered/route-delivered.component';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { PaymentSummaryPage } from './pages/payment-summary/payment-summary.page';
import { ThankYouPage } from './pages/thankyou/thankyou.page';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GeoLocationService } from './services/geolocation/geolocation.service';
import { MapPage } from './pages/map/map.page';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { RatingService } from './services/rating/rating.service';
import { ModalHtmlDirective } from './directives/modal-html.directive';
import { ReportComponent } from './components/report/report.component';
import { SubscriptionInactiveComponent } from './components/subscription-inactive/subscription-inactive.component';
import { ProfileSubscriptionComponent } from './components/profile-subscription/profile-subscription.component';
import { PaymentService } from './services/payment/payment.service';
import { ThankYouSubscriptionPage } from './pages/thankyou-subscription/thankyou-subscription.page';
import { TransportTypePopoverComponent } from './components/transport-type-popover/transport-type-popover.component';
import { CityPipe } from './pipes/city.pipe';
import { TravelPricePipe } from './pipes/travelPrice.pipe';
import { CarryPageGuard } from './services/profile/guard-carry-page.service';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactsDirective } from './directives/contacts.directive';
import { ExtLinkService } from './services/ext-link/ext-link.service';
import { NotificationsPage } from './pages/notifications/notifications.page';
import { SwgRatingComponent } from './components/swg-rating/swg-rating.components';
import { AccountService } from './services/auth/account.service';
import { PaymentErrorPage } from './pages/payment-error/payment-error.page';
import { PruningTranslationLoader } from './services/translate/pruning-translation-loader';
import { VerifyEmailPage } from './pages/verify-email/verify-email.page';
import { DeadlinesPage } from './pages/deadlines/deadlines.page';
import { ModalNewDeadlineComponent } from './components/modal-new-deadline/modal-new-deadline.component';
import { EnterChatComponent } from './components/enter-chat/enter-chat.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DeadlineService } from './services/deadlines/deadines.service';
import { VerifyEmailService } from './services/verify-email/verify-email.service';
import { AvatarService } from './services/profile/avatar.service';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { VisuraPage } from './pages/visura/visura.page';
import { EllipsizeStringPipe } from './pipes/ellipsize/ellipsize-string.pipe';
import { StartPage } from './pages/onboarding/start/start.page';
import { TutorialPage } from './pages/onboarding/tutorial/tutorial.page';
import { IntroPage } from './pages/onboarding/intro/intro.page';
import { OnboardinGuard } from './services/onboarding/guard-onboarding.service';
import { BackToButtonComponent } from './components/back-to-button/back-to-button.component';
import { DurcPage } from './pages/durc/durc.page';
import { GoogleAnalyticsService } from './services/analytics/google-analytics.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Injector } from '@angular/core';
import { FirebaseService } from './services/firebase/firebase.service';
import { PoliticallyExposedPerson } from './directives/politically-exposed-person.directive';
import { TitleService } from './services/title/title.service';

export function LanguageLoader(http: HttpClient) {
  return new PruningTranslationLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ProfilePage,
    HomePage,
    TabsPage,
    SwgHeaderComponent,
    CallbackPage,
    ProfileFormPrivateComponent,
    ProfileFormCompanyComponent,
    ProfileFormProfessionalComponent,
    ProfileSelectTypeComponent,
    ProfileAvatarComponent,
    FormErrorMessageComponent,
    SelectCityComponent,
    ModalContractComponent,
    SendPage,
    MySendsPage,
    MyCarriesPage,
    CarryPage,
    SendUpsertPage,
    ModalDateTimeComponent,
    SelectCityDirective,
    SelectDateDirective,
    PrivacyPolicyDirective,
    TermsConditionsDirective,
    ModalHtmlDirective,
    ModalHtmlComponent,
    RouteCardComponent,
    EmptyListComponent,
    FormatDatePipe,
    TransportIconPipe,
    RouteDetailPage,
    OwnerPipe,
    RouteStateComponent,
    CarryUpsertPage,
    FindCarryPage,
    FindSendPage,
    RouteNotFoundComponent,
    RouteCardFoundComponent,
    CarryDetailPage,
    TransportPipe,
    ChatPage,
    SendDetailPage,
    ProfileCreateSuccessComponent,
    IdentityCardPage,
    VisuraPage,
    SanitizeUrlPipe,
    IbanPage,
    DeadlinesPage,
    ChatBubbleComponent,
    PricePipe,
    RouteWaitingComponent,
    RouteActiveComponent,
    RouteDeliveredComponent,
    PaymentSummaryPage,
    ThankYouPage,
    VerifyEmailPage,
    MapPage,
    ReportComponent,
    SubscriptionInactiveComponent,
    ProfileSubscriptionComponent,
    ThankYouSubscriptionPage,
    TransportTypePopoverComponent,
    CityPipe,
    TravelPricePipe,
    ContactsComponent,
    ContactsDirective,
    NotificationsPage,
    SwgRatingComponent,
    PaymentErrorPage,
    ModalNewDeadlineComponent,
    EnterChatComponent,
    EllipsizeStringPipe,
    StartPage,
    TutorialPage,
    IntroPage,
    BackToButtonComponent,
    DurcPage,
    PoliticallyExposedPerson
  ],
  entryComponents: [
    SelectCityComponent,
    ProfileSelectTypeComponent,
    ModalContractComponent,
    ModalDateTimeComponent,
    ModalHtmlComponent,
    ProfileCreateSuccessComponent,
    TransportTypePopoverComponent,
    ContactsComponent,
    ModalNewDeadlineComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AuthService, useFactory: AuthFactory, deps: [Platform, Storage, SafariViewController, Events] },
    { provide: NgbDatepickerI18n, useClass: MyDatepickerI18n },
    StatusBar,
    SplashScreen,
    TranslateConfigService,
    ProfileService,
    AvatarService,
    AlertService,
    SpinnerService,
    SafariViewController,
    AppVersion,
    RouteService,
    AuthGuard,
    CompanyGuard,
    CarryPageGuard,
    InAppBrowser,
    TravelService,
    PreviousRouterService,
    Camera,
    Chooser,
    ChatService,
    OneSignal,
    NotificationService,
    PricePipe,
    LocalNotifications,
    Geolocation,
    GeoLocationService,
    BackgroundGeolocation,
    RatingService,
    PaymentService,
    ExtLinkService,
    AccountService,
    SocialSharing,
    DeadlineService,
    VerifyEmailService,
    OnboardinGuard,
    GoogleAnalyticsService,
    GoogleAnalytics,
    FirebaseService,
    TitleService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  public static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
  
 }
