import { Component, Input } from '@angular/core';
import { TRANSPORT_TYPE } from 'src/app/constants';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'transport-type-popover',
  templateUrl: './transport-type-popover.component.html',
  styleUrls: ['./transport-type-popover.component.scss'],
})
export class TransportTypePopoverComponent {

  @Input() type: TRANSPORT_TYPE;

  constructor(
    private popover: PopoverController,
  ) { }

  dismiss() {
    this.popover.dismiss();
  }
}
