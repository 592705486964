import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Profile, ProfessionalSubscription } from 'src/app/models/profile';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'profile-subscription',
  templateUrl: './profile-subscription.component.html',
  styleUrls: ['./profile-subscription.component.scss'],
})
export class ProfileSubscriptionComponent {

  @Input() profile: Profile;
  @Output() successPay: EventEmitter<void> = new EventEmitter();

  constructor(
    private spinner: SpinnerService,
    private auth: AuthService,
    private profileService: ProfileService,
    private alert: AlertService,
    private payment: PaymentService,
  ) { }

  isActive(): boolean {
    const { professionalSubscription = {} as ProfessionalSubscription } = this.profile;
    const { active = false } = professionalSubscription || {};
    return active;
  }

  async pay() {

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();

      const { paymentFormUrl } = await this.profileService.subscription(token);

      this.payment.pay(
        paymentFormUrl,
        () => this.successPay.emit(),
        () => this.alert.translate('PAYMENT_ERROR_MESSAGE'),
      );

      await this.spinner.dismiss();

    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }
}
