import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackPage } from './pages/callback/callback.page';
import { TabsPage } from './pages/tabs/tabs.page';
import { HomePage } from './pages/home/home.page';
import { ProfilePage } from './pages/profile/profile.page';
import { SendPage } from './pages/send/send.page';
import { MySendsPage } from './pages/my-sends/my-sends.page';
import { MyCarriesPage } from './pages/my-carries/my-carries.page';
import { CarryPage } from './pages/carry/carry.page';
import { SendUpsertPage } from './pages/send-upsert/send-upsert.page';
import { AuthGuard } from './services/auth/guard.service';
import { RouteDetailPage } from './pages/route-detail/route-detail.page';
import { CarryUpsertPage } from './pages/carry-upsert/carry-upsert.page';
import { FindCarryPage } from './pages/find-carry/find-carry.page';
import { FindSendPage } from './pages/find-send/find-send.page';
import { CarryDetailPage } from './pages/route-found/carry-detail/carry-detail.page';
import { CompanyGuard } from './services/profile/guard-company.service';
import { ChatPage } from './pages/chat/chat.page';
import { SendDetailPage } from './pages/route-found/send-detail/send-detail.page';
import { IdentityCardPage } from './pages/identity-card/identity-card.page';
import { IbanPage } from './pages/iban/iban.page';
import { PaymentSummaryPage } from './pages/payment-summary/payment-summary.page';
import { ThankYouPage } from './pages/thankyou/thankyou.page';
import { MapPage } from './pages/map/map.page';
import { ThankYouSubscriptionPage } from './pages/thankyou-subscription/thankyou-subscription.page';
import { CarryPageGuard } from './services/profile/guard-carry-page.service';
import { NotificationsPage } from './pages/notifications/notifications.page';
import { PaymentErrorPage } from './pages/payment-error/payment-error.page';
import { VerifyEmailPage } from './pages/verify-email/verify-email.page';
import { DeadlinesPage } from './pages/deadlines/deadlines.page';
import { VisuraPage } from './pages/visura/visura.page';
import { StartPage } from './pages/onboarding/start/start.page';
import { TutorialPage } from './pages/onboarding/tutorial/tutorial.page';
import { OnboardinGuard } from './services/onboarding/guard-onboarding.service';
import { IntroPage } from './pages/onboarding/intro/intro.page';
import { DurcPage } from './pages/durc/durc.page';

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        component: HomePage
      },
      {
        path: 'send',
        component: SendPage,
      },
      {
        path: 'my-sends',
        component: MySendsPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'carry',
        component: CarryPage,
        canActivate: [CarryPageGuard],
      },
      {
        path: 'my-carries',
        component: MyCarriesPage,
        canActivate: [CompanyGuard],
      },
      {
        path: '',
        redirectTo: '/tabs/home',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'callback',
    component: CallbackPage,
  },
  {
    path: 'profile',
    component: ProfilePage,
    canActivate: [AuthGuard],
  },
  {
    path: 'send-upsert',
    component: SendUpsertPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'send-upsert/:id',
    component: SendUpsertPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'carry-upsert',
    component: CarryUpsertPage,
    canActivate: [CompanyGuard],
  },
  {
    path: 'carry-upsert/:id',
    component: CarryUpsertPage,
    canActivate: [CompanyGuard],
  },
  {
    path: 'route-detail/:id',
    component: RouteDetailPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'carry-detail',
    component: CarryDetailPage,
  },
  {
    path: 'send-detail',
    component: SendDetailPage,
  },
  {
    path: 'find-carry',
    component: FindCarryPage,
  },
  {
    path: 'find-send',
    component: FindSendPage,
  },
  {
    path: 'chat/:travelId',
    component: ChatPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'identity-card',
    component: IdentityCardPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'visura',
    component: VisuraPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'iban',
    component: IbanPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'deadlines',
    component: DeadlinesPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-summary/:travelId',
    component: PaymentSummaryPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'thankyou',
    component: ThankYouPage,
  },
  {
    path: 'payment-error',
    component: PaymentErrorPage,
  },
  {
    path: 'thankyou-subscription',
    component: ThankYouSubscriptionPage,
  },
  {
    path: 'map/:travelId',
    component: MapPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    component: NotificationsPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'verify-email',
    component: VerifyEmailPage,
  },
  {
    path: 'onboarding/start',
    component: StartPage,
    canActivate: [OnboardinGuard],
  },
  {
    path: 'onboarding/intro',
    component: IntroPage,
  },
  {
    path: 'onboarding/tutorial',
    component: TutorialPage,
  },
  { path: 'durc', 
    component: DurcPage 
  },
  {
    path: '',
    redirectTo: '/onboarding/start',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
