import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-html',
  templateUrl: './modal-html.component.html',
  styleUrls: ['./modal-html.component.scss'],
})
export class ModalHtmlComponent {

  @Input() titleKey: string;
  @Input() contentKeys: string[];

  constructor(
    private modal: ModalController,
  ) { }

  close() {
    this.modal.dismiss();
  }
}
