import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AuthGuard } from '../auth/guard.service';
import { AlertService } from '../alert/alert.service';
import { ProfileService } from './profile.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PROFILE_TYPE } from 'src/app/constants';
import { ProfessionalSubscription } from 'src/app/models/profile';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard extends AuthGuard {

  constructor(
    protected auth: AuthService,
    protected alert: AlertService,
    protected profile: ProfileService,
  ) {
    super(auth, alert, profile);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const logged = await super.canActivate(route, state);
    if (!logged) {
      return false;
    }
    const user = await this.profile.getProfile();
    const { type = '', professionalSubscription = {} as ProfessionalSubscription } = user;
    const { active = false } = professionalSubscription || {};

    if (type === PROFILE_TYPE.company) {
      await this.alert.translate('GUARD_COMPANY');
      return false;
    }

    if (type === PROFILE_TYPE.professional && !active) {
      await this.alert.translate('GUARD_PROFESSIONAL');
      return false;
    }

    return true;
  }
}
