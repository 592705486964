import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  constructor() { }

  transform(value: string | Date | moment.Moment, args?: any): any {
    const format = args || 'DD MMMM YYYY';
    const date = moment(value);
    if (date.isValid()) {
      return date.format(format);
    }
    return '';
  }

}
