import { Injectable } from '@angular/core';
// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    //private firebaseAnalytics: FirebaseAnalytics
  ) {
   }

   trackPage(screen: string){
    // this.firebaseAnalytics.setCurrentScreen(screen);
   }

}
