import { Injectable } from '@angular/core';
import { SocialProfile } from 'src/app/models/profile';

@Injectable()
export abstract class AuthService {

  abstract async getToken(): Promise<string>;
  abstract async login(screenHint?: string, redirect?: string): Promise<void>;
  abstract async loginWithFilledFields(screenHint?: string, redirect?: string, value?: string): Promise<void>;
  abstract async logout();
  abstract async isLoggedIn(): Promise<boolean>;
  abstract async getSocialProfile(): Promise<SocialProfile>;

  abstract async handleAuthCallback(): Promise<string>;

}
