import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import Auth0Cordova from '@auth0/cordova';
import { Component } from '@angular/core';
import { GeoLocationService } from './services/geolocation/geolocation.service';
import { GoogleAnalyticsService } from './services/analytics/google-analytics.service';
import { Location } from '@angular/common';
import { NotificationService } from './services/notification/notification.service';
import { ProfileService } from './services/profile/profile.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from './services/translate/translate-config.service';

const GA_TRACKING_ID_ALL = 'G-KHFCLYN67X';
const GA_TRACKING_ID_FILTERED = 'G-41ESZH5RSM';
const GA_TRACKING_ID_TEST = 'G-3LB1V1N87N';
const GA_FAKE_MOBILE_PROPERTY_TRACKING_ID = '';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private alert: AlertController,
    private modal: ModalController,
    private platform: Platform,
    private popover: PopoverController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateConfigService,
    private profile: ProfileService,
    private notification: NotificationService,
    private geolocation: GeoLocationService,
    private router: Router,
    private location: Location,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {

    this.initializeApp();

    this.translate.setBrowserLanguage();

  }

  initializeApp() {
  
    this.platform.ready()
      .then((readySource) => {  
        this.platform.backButton.subscribeWithPriority(1, () => this.handleBackButton());
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByHexString('#f4f5f8');
        this.splashScreen.hide();

        console.log('Platform ready from', readySource);
        this.setupAnalytics();

        this.initNotification();

        this.geolocation.init();

        (window as any).handleOpenURL = (url: string) => {
          if (this.platform.is('cordova')) {
            Auth0Cordova.onRedirectUri(url);
          }
        };
        
      });
  }

  setupAnalytics() {
    if(this.platform.is('cordova')) {
      this.googleAnalyticsService.startTrackerWithId(`${GA_TRACKING_ID_ALL}`);
      this.googleAnalyticsService.startTrackerWithId(`${GA_TRACKING_ID_FILTERED}`);
      this.googleAnalyticsService.startTrackerWithId(`${GA_TRACKING_ID_TEST}`);
    } else {
      this.observeRoutingPath();
    }
  }

  observeRoutingPath() {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', `${GA_TRACKING_ID_ALL}`, { 'page_path': event.urlAfterRedirects } );
          gtag('config', `${GA_TRACKING_ID_FILTERED}`, { 'page_path': event.urlAfterRedirects } );
          gtag('config', `${GA_TRACKING_ID_TEST}`, { 'page_path': event.urlAfterRedirects } );
        }
      }
    )
  }
  
  initNotification() {
    if (!this.platform.is('cordova')) { return; }

    this.notification.init();

    this.profile.getProfile()
      .then(profile => {
        if (profile && profile.id) {
          this.notification.setTag(profile.id);
        }
      })
      .catch(err => console.log('No profile for notification tag'));
  }

  async handleBackButton() {
    const modal = await this.modal.getTop();
    if (modal) {
      return modal.dismiss();
    }

    const alert = await this.alert.getTop();
    if (alert) {
      return alert.dismiss();
    }

    const popover = await this.popover.getTop();
    if (popover) {
      return popover.dismiss();
    }

    if (this.router.url === '/tabs/home') {
      navigator[`app`].exitApp();
    } else {
      this.location.back();
    }
  }
}
