import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { FindRouteParams } from 'src/app/models/findRouteParams';
import { IonInfiniteScroll, IonRefresher } from '@ionic/angular';
import { Route } from 'src/app/models/route';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-find-send',
  templateUrl: './find-send.page.html',
  styleUrls: ['./find-send.page.scss'],
})
export class FindSendPage implements OnInit {

  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher, { static: false }) refresher: IonRefresher;

  routes: Route[] = [];
  findParams: FindRouteParams;

  constructor(
    private alert: AlertService,
    public spinner: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private profileService: ProfileService,
    private routeService: RouteService,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(first())
      .subscribe((params: FindRouteParams) => {
        this.findParams = {
          ...params,
          offset: 0
        };
        this.fetch();
      });
  }

  async fetch() {
    if (this.invalidParams()) {
      await this.alert.redirect(['/tabs/carry'], '', 'FIND_ROUTE_INVALID_PARAMS');
      return;
    }

    try {
      await this.spinner.show();

      let token;
      try {
        token = await this.profileService.getProfile() && await this.auth.getToken();
      } catch (err) {
      }

      const { body, headers } = await this.routeService.search(token, this.findParams);

      // INFINITE SCROLL DISABLED
      // this.routes = this.routes.concat(body);
      // this.findParams.offset += body.length;
      // const count = parseInt(headers.get('x-total-count'), 10);
      // this.infiniteScroll.complete();
      // this.infiniteScroll.disabled = this.routes.length >= count;
      this.routes = body;
      this.infiniteScroll.disabled = true;

      await this.spinner.dismiss();
    } catch (err) {
      this.infiniteScroll.disabled = true;
      this.infiniteScroll.complete();
      this.spinner.dismissWithAlertError(err);
    }

  }

  onRefresh() {
    this.routes = [];
    this.findParams.offset = 0;
    this.fetch();

    this.refresher.complete();
  }

  invalidParams() {
    const { type, from, to, date } = this.findParams;
    return !type || !from || !to || !date;
  }
}
