import { Component, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.page.html',
  styleUrls: ['./thankyou.page.scss'],
})
export class ThankYouPage implements OnInit {

  constructor(
    private events: Events,
  ) { }

  ngOnInit(): void {
    this.events.publish('send:pay:success');
  }
}
