export const environment = {
  production: true,
  AUTH0_DOMAIN: 'siwegoprod.eu.auth0.com',
  AUTH0_CLIENT_ID_WEB: 'JpwoAFazhR0MxQc1j0HHz89N6Nu7p4R7',
  AUTH0_CLIENT_ID_NATIVE: 'q1yk1lmjLKwN4A7B98HpuRkli0Z7D3CU',
  BUNDLE_ID: 'it.siwego.clientapp',
  API_URL: 'https://api.siwego.app',
  ONE_SIGNAL_APP_ID: '675ea5f3-9b5a-4827-8087-419baf15cad2',
  ONE_SIGNAL_PROJECT_NUMBER: '753421881650',
  MIN_TRAVEL_PRICE: 3,
  APP_NAME: 'SiWeGO',
  LOCATION_FREQUENCY: 10, // in minutes
  LEMONWAY_RETURN_URL: 'https://api.siwego.app/redirects/thankyou',
  LEMONWAY_SUBSCRIPTION_RETURN_URL: 'https://api.siwego.app/redirects/thankyou-subscription',
  LEMONWAY_CANCEL_URL: 'https://api.siwego.app/redirects/payment-cancel',
  LEMONWAY_ERROR_URL: 'https://api.siwego.app/redirects/payment-error',
  SHOW_STACK_ERROR: true,
  SUPPORT_EMAIL: 'support@siwego.com',
};
