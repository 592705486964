import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import { from, Observable, combineLatest, throwError, BehaviorSubject } from 'rxjs';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
const { AUTH0_DOMAIN, AUTH0_CLIENT_ID_WEB } = environment;
import { AuthService } from './auth.service';
import { SocialProfile } from 'src/app/models/profile';

@Injectable({
  providedIn: 'root'
}) export class AuthWebService extends AuthService {

  loggedIn: boolean;

  auth0Client = (from(
    createAuth0Client({
      domain: AUTH0_DOMAIN,
      client_id: AUTH0_CLIENT_ID_WEB,
      redirect_uri: `${window.location.origin}/callback`,
      audience: `https://${AUTH0_DOMAIN}/api/v2/`,
    })) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  isAuthenticated = this.auth0Client.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => {
      this.loggedIn = res;
    })
  );

  handleRedirectCallback = this.auth0Client.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  constructor() {
    super();
    this.isAuthenticated.toPromise();
  }

  async getToken() {
    const client = await this.auth0Client.toPromise();
    const token = await client.getTokenSilently();
    return token;
  }

  isLoggedIn(): Promise<boolean> {
    return this.isAuthenticated.toPromise()
      .then(() => this.loggedIn);
  }

  async login(screenHint = 'login', redirect: string = '/profile') {
    const client: Auth0Client = await this.auth0Client.toPromise();
    return client.loginWithRedirect({
      redirect_uri: `${window.location.origin}/callback`,
      appState: { target: redirect },
      screen_hint: screenHint
    });
  }

  async handleAuthCallback(): Promise<string> {
    let targetRoute: string;
    const authComplete = this.handleRedirectCallback.pipe(
      tap((cbRes: any) => {
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        return combineLatest(
          this.isAuthenticated
        );
      })
    );

    await authComplete.toPromise();
    return targetRoute;
  }

  logout() {
    this.auth0Client.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: AUTH0_CLIENT_ID_WEB,
        returnTo: `${window.location.origin}`
      });
    });
  }

  async getSocialProfile(): Promise<SocialProfile> {
    const client = await this.auth0Client.toPromise();
    const token = await client.getIdTokenClaims();
    const { family_name = '', given_name = '', birthdate } = token;
    return {
      firstName: given_name || '',
      lastName: family_name || '',
      birthDate: birthdate || '',
    };
  }

  async loginWithFilledFields(screenHint = 'login', redirect: string = '/profile', myEmail: string) {
    console.log('>>>>>>>>>auth web email', myEmail);
    const client: Auth0Client = await this.auth0Client.toPromise();
    return client.loginWithRedirect({
      redirect_uri: `${window.location.origin}/callback`, 
      appState: { target: redirect },
      login_hint: myEmail,
      screen_hint: screenHint,
      prompt: 'login'
    });
  }

}
