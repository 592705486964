import { Profile } from 'src/app/models/profile'
import { PROFILE_TYPE } from 'src/app/constants'
import { ProfileService } from 'src/app/services/profile/profile.service'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
const { API_URL } = environment
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Subscription } from 'rxjs'
import { AuthService } from '../auth/auth.service'

const DEFAULT_AVATAR = 'assets/icon/icon_profilo.svg'
@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  private avatarSubject = new BehaviorSubject<string>(
    'assets/icon/icon_profilo.svg'
  )
  private loadingSubject = new BehaviorSubject<boolean>(false)
  private profile: Profile

  avatar = this.avatarSubject.asObservable()
  loading = this.loadingSubject.asObservable()

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private auth: AuthService,
    private profileService: ProfileService
  ) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile
    })
  }

  async load(): Promise<void> {
    try {
      this.loadingSubject.next(true)

      const isLoggedIn = await this.auth.isLoggedIn()
      if (!isLoggedIn) {
        return
      }

      if (!this.profile || !this.profile.type || this.profile.type !== PROFILE_TYPE.private) {
        return
      }
      await this.fetchAvatar(await this.auth.getToken())
    } catch (error) {
      throw error
    } finally {
      this.loadingSubject.next(false)
    }
  }

  async fetchAvatar(token): Promise<void> {
    try {
      this.loadingSubject.next(true)
      const authHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` })
      const body = await this.http
        .get<any>(`${API_URL}/profiles/avatar`, { headers: authHeaders })
        .toPromise()
      if (body && body.url) {
        const data = await this.http
          .get(body.url, { responseType: 'text' })
          .toPromise()
        const avatar = 'data:image/jpeg;base64,' + data
        this.storage.set('avatar', avatar)
        this.avatarSubject.next(avatar)
      }
    } catch (error) {
      throw error
    } finally {
      this.loadingSubject.next(false)
    }
  }

  async uploadAvatar(token: string, doc: string): Promise<void> {
    try {
      if (!this.profile || !this.profile.type || this.profile.type !== PROFILE_TYPE.private) {
        return
      }
      const authHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` })
      this.loadingSubject.next(true)
      await this.http
        .post<any>(
          `${API_URL}/profiles/avatar`,
          { buffer: doc.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') },
          { headers: authHeaders }
        )
        .toPromise()
      await this.fetchAvatar(token)
    } catch (error) {
      throw error
    } finally {
      this.loadingSubject.next(false)
    }
  }

  async clear(): Promise<any> {
    await this.storage.remove('avatar')
    this.avatarSubject.next(DEFAULT_AVATAR)
  }

  getProfileType(): PROFILE_TYPE {
    if (!this.profile) {
      return null
    }
    return this.profile.type
  }
}
