import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const { API_URL } = environment;
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { RouteParams } from 'src/app/models/routeParams';
import { Route } from 'src/app/models/route';
import { FindRouteParams } from 'src/app/models/findRouteParams';
import * as moment from 'moment';
import { Travel } from 'src/app/models/travel';
import { ROUTE_TYPE } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private http: HttpClient,
  ) {
  }

  async fetch(routeParams: RouteParams): Promise<HttpResponse<Route[]>> {
    const { token, type, state, offset = 0, limit = 10 } = routeParams;

    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('state', state)
      .set('type', type);

    return this.http.get<Route[]>(`${API_URL}/routes`, { params, headers, observe: 'response' }).toPromise();
  }

  async create(token: string, route: Route): Promise<any> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<Route>(`${API_URL}/routes`, route, { headers }).toPromise();
  }

  async update(token: string, id: number | string, route: Route): Promise<any> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.put<Route>(`${API_URL}/routes/${id}`, route, { headers }).toPromise();
  }

  async get(token: string, id: number | string): Promise<Route> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<Route>(`${API_URL}/routes/${id}`, { headers }).toPromise();
  }

  async remove(token: string, id: number | string): Promise<Route> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.delete<Route>(`${API_URL}/routes/${id}`, { headers }).toPromise();
  }

  async search(token: string, findParams: FindRouteParams): Promise<HttpResponse<Route[]>> {
    let headers = {};
    let endpoint = 'search-open';
    if (token) {
      endpoint = 'search';
      headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    }
    const { offset, limit, type, from, to, date, gpsEnabled, transportType, professionalCarrier, packageSize } = findParams;

    let params = new HttpParams()
      .set('type', type)
      .set('from', from)
      .set('to', to)
      .set('date', moment(date).toISOString());

    if (gpsEnabled === 'true') {
      params = params.append('gpsEnabled', gpsEnabled);
    }
    if (transportType) {
      params = params.append('transportType', transportType);
    }
    if (professionalCarrier === 'true') {
      params = params.append('professionalCarrier', professionalCarrier);
    }
    if (packageSize && type === ROUTE_TYPE.send) {
      params = params.append('packageSize', packageSize);
    }
    if (Number(offset)) {
      params = params.append('offset', offset.toString());
    }
    if (Number(limit)) {
      params = params.append('limit', limit.toString());
    }

    return this.http.get<Route[]>(`${API_URL}/routes/${endpoint}`, { params, headers, observe: 'response' }).toPromise();
  }

  async match(token: string, id: number | string): Promise<Travel> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<Travel>(`${API_URL}/routes/${id}/match`, {}, { headers }).toPromise();
  }
}
