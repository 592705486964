import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    return formatPrice(value)
  }

}

export const formatPrice = (value) => {
  const price = Number(value) || 0;
  return `€ ${price.toFixed(2).replace('.', ',')}`;
}