import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Profile } from 'src/app/models/profile';
import { Storage } from '@ionic/storage';
import { UploadDocument } from 'src/app/models/uploadDocument';
import { WalletIban } from 'src/app/models/wallet';
import { Report } from 'src/app/models/report';
import { PaymentForm } from 'src/app/models/paymentForm';
const { API_URL, LEMONWAY_CANCEL_URL, LEMONWAY_ERROR_URL, LEMONWAY_SUBSCRIPTION_RETURN_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private profileSubject = new BehaviorSubject<Profile>(null);
  profile$ = this.profileSubject.asObservable();

  constructor(
    private http: HttpClient,
    private storage: Storage,
  ) {
  }

  async getProfile(): Promise<Profile> {
    const profile = await this.storage.get('profile')
    this.profileSubject.next(profile)
    return profile;
  }

  removeProfile(): Promise<any> {
    this.profileSubject.next(null)
    return this.storage.remove('profile');
  }

  create(token: string, profile: Profile) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<Profile>(`${API_URL}/profiles`, profile, { headers }).toPromise();
  }

  update(token: string, profile: Profile) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.put<Profile>(`${API_URL}/profiles`, profile, { headers }).toPromise();
  }

  async me(token: string): Promise<Profile> {
    try {
      const authHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
      const body = await this.http.get<Profile>(`${API_URL}/profiles/me`, { headers: authHeaders }).toPromise();
      await this.storage.set('profile', body);
      this.profileSubject.next(body)
      return body;
    } catch (err) {
      await this.removeProfile();
      const { status = 500 } = err;
      if (status === 404) {
        return null;
      }
      throw err;
    }
  }

  async uploadDocument(token: string, doc: UploadDocument): Promise<any> {
    const authHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(`${API_URL}/profiles/upload-document`, doc, { headers: authHeaders }).toPromise();
  }

  async registerIban(token: string, iban: WalletIban): Promise<any> {
    const body: any = { ...iban };
    body.iban = iban.data;
    delete body.data;

    const authHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(`${API_URL}/profiles/register-iban`, body, { headers: authHeaders }).toPromise();
  }

  async report(token: string, query: { from?: string; to?: string }): Promise<Report> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams({ fromObject: query });
    return this.http.get<Report>(`${API_URL}/profiles/kpi`, { headers, params }).toPromise();
  }

  async subscription(token: string): Promise<PaymentForm> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    // return this.http.post<PaymentForm>(`${API_URL}/profiles/professional-pay-fake`, {}, { headers })
    //   .toPromise()
    //   .then(res => res || { paymentFormUrl: '' });

    const body = { 
      returnUrl: LEMONWAY_SUBSCRIPTION_RETURN_URL,
      cancelUrl: LEMONWAY_CANCEL_URL,
      errorUrl: LEMONWAY_ERROR_URL
    };
    return this.http.post<PaymentForm>(`${API_URL}/profiles/professional-pay`, body, { headers }).toPromise();
  }
}
