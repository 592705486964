import { Component, Input } from '@angular/core';
import { FindRouteParams } from 'src/app/models/findRouteParams';
import { Router } from '@angular/router';
import { ROUTE_TYPE } from 'src/app/constants';

@Component({
  selector: 'route-not-found',
  templateUrl: './route-not-found.component.html',
  styleUrls: ['./route-not-found.component.scss'],
})
export class RouteNotFoundComponent {

  @Input() params: FindRouteParams;

  constructor(
    private router: Router
  ) { }

  isSend() {
    const { type = '' } = this.params || {};
    return type && type === ROUTE_TYPE.send;
  }

  isCarry() {
    const { type = '' } = this.params || {};
    return type && type === ROUTE_TYPE.carry;
  }

  publish() {
    const type = this.isCarry() ? 'send' : 'carry'; // se sto cercando un trasporto, vado a pubblicare una spedizione (e viceversa)
    this.router.navigate([`/${type}-upsert`], { queryParams: this.params });
  }
}
