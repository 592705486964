import { Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { FormBuilder, Validators, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { COUNTRIES, ITALY_CODE } from './options';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { setFormTouched } from 'src/app/utils/form';
import { Place } from 'src/app/models/place';
import { PROFILE_TYPE } from 'src/app/constants';
import { JOB_TYPES, COMMUTERS_TYPES } from './././options';
import moment, { Moment } from 'moment';
import { ExtLinkService } from 'src/app/services/ext-link/ext-link.service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';

export class ProfileFormBaseComponent implements OnInit {

  @Input() profile: Profile;
  @Output() submitForm: EventEmitter<Profile> = new EventEmitter<Profile>();
  form: FormGroup;
  countries = [];
  minDate = moment().subtract(99, 'years').toDate();
  maxDate = new Date();
  protected readonly dateFormat = 'DD/MM/YYYY';

  constructor(
    protected fb: FormBuilder,
    protected modal: ModalController,
    protected translate: TranslateService,
    protected alert: AlertService,
    protected extLink: ExtLinkService,
    protected storage: Storage,
  ) {
    const res = this.translate.instant(COUNTRIES.map(c => `COUNTRIES.${c}`));
    for (const key in res) {
      if (res.hasOwnProperty(key)) {
        this.countries.push({ value: key.replace('COUNTRIES.', ''), label: res[key] });
      }
    }
    this.countries.sort((a, b) => {
      if (a.label < b.label) { return -1; }
      if (a.label > b.label) { return 1; }
      return 0;
    });

  }

  ngOnInit(): void {
    const disabled = !this.isCreating();
    this.form = this.fb.group({
      firstName: [{ value: '', disabled }, Validators.required],
      lastName: [{ value: '', disabled }, Validators.required],
      birthDate: [{ value: '', disabled }, Validators.required],
      birthPlace: [{ value: '', disabled }, Validators.required],
      taxCode: [{ value: '', disabled }],
      cellPhone: ['', Validators.required],
      address: this.fb.group({
        city: ['', Validators.required],
        cap: ['', Validators.required],
        street: ['', Validators.required],
        country: [{ value: '', disabled }, Validators.required],
      }),
      job: [{ value: '', disabled: false }, Validators.required],
      commuter: [{ value: COMMUTERS_TYPES[0], disabled: false }],
      privacyPolicy: [false, Validators.requiredTrue],
      termsAndConditions: [false, Validators.requiredTrue],
      marketing: [false],
      thirdParty: [false],
    });

    if (this.isCreating()) {
      this.prefillFormFields();
    }
  
  }

  setValidatorsPecSdi() {
    const pecControl = this.form.get('vatInfo').get('pec');
    const sdiControl = this.form.get('vatInfo').get('sdiCode');

    this.form.get('address').get('country').valueChanges
      .subscribe(() => {
        pecControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        sdiControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      });

    pecControl.valueChanges.subscribe(() => sdiControl.updateValueAndValidity({ onlySelf: true, emitEvent: false }));
    sdiControl.valueChanges.subscribe(() => pecControl.updateValueAndValidity({ onlySelf: true, emitEvent: false }));
  }

  pecValidator(formControl: AbstractControl): ValidationErrors[] | ValidationErrors {
    if (formControl.parent && formControl.parent.parent) {
      const country = formControl.parent.parent.get('address').get('country').value;
      const sdiCode = formControl.parent.parent.get('vatInfo').get('sdiCode').value;
      if (country === ITALY_CODE && !sdiCode && !formControl.value) {
        return [Validators.email(formControl), { pecsdi: true }];
      }
    }
    if (formControl.value) {
      return Validators.email(formControl);
    }
    return null;
  }

  sdiValidator(formControl: AbstractControl): ValidationErrors[] | ValidationErrors {
    if (formControl.parent && formControl.parent.parent) {
      const country = formControl.parent.parent.get('address').get('country').value;
      const pec = formControl.parent.parent.get('vatInfo').get('pec').value;
      if (country === ITALY_CODE && !pec && !formControl.value) {
        return { pecsdi: true };
      }
    }
    return null;
  }

  onSubmit() {
    if (!this.form.valid || !this.isPecSdiValid()) {
      setFormTouched(this.form);
      this.alert.toastWarning('WARNING_FORM_INVALID');
      return;
    }
    
    if (!this.isAdult()) {
      this.alert.translate('ADULT_PROFILE_ERROR');
      return;
    } 
    
    if (this.isRetired()) {
      this.alert.translate('RETIRED_PROFILE_ERROR');
      return;
    }

    const profileData = this.form.getRawValue();
    const { birthDate } = profileData;
    this.submitForm.emit({
      ...profileData,
      birthDate: birthDate ? moment(birthDate, this.dateFormat).format('YYYY-MM-DD') : birthDate,
    });
  }

  isPecSdiValid() {
    console.log('>>>>>>>>>> isPecSdiValid() inherited from ProfileFormBaseComponent');
    if (this.profile.type !== PROFILE_TYPE.private) {
      const { address = {}, vatInfo = {} } = this.form.getRawValue();
      const { country } = address;
      const { pec, sdiCode } = vatInfo;
      if (country === ITALY_CODE && !pec && !sdiCode) {
        return false;
      }
    }
    return true;
  }

  isAdult() {
    console.log('>>>>>>>>>> isAdult() inherited from ProfileFormBaseComponent');
    const { birthDate } = this.form.getRawValue();
    const age = moment().diff(moment(birthDate, this.dateFormat), 'years');
    return age >= 18;
  }

  isRetired() {
    const { birthDate } = this.form.getRawValue();
    const age = moment().diff(moment(birthDate, this.dateFormat), 'years');
    return age > 75;
  }

  isCreating() {
    return !this.profile || !this.profile.id;
  }

  selectedAddressCity(place: Place) {
    const { address = '', cap = '', city = '', country = '' } = place;

    if (!this.isCreating()) {
      const oldCountry = this.profile.address.country;
      if (oldCountry !== country) {
        this.alert.translate('PROFILE_CHANGE_CITY_ERROR');
        return;
      }
    }

    this.form.get('address').get('city').setValue(city);
    this.form.get('address').get('street').setValue(address);
    this.form.get('address').get('cap').setValue(cap);
    this.form.get('address').get('country').setValue(country);
  }

  selectedBirthDate(date: Moment) {
    if (!this.isCreating()) { return; }
    this.form.get('birthDate').setValue(date.format(this.dateFormat));
  }

  selectedBirthplace(place: Place) {
    if (!this.isCreating()) { return; }
    const { description = '' } = place;
    this.form.get('birthPlace').setValue(description);
  }

  changeData() {
    this.extLink.email(environment.SUPPORT_EMAIL);
  }

  deleteAccount() {
    if (!this.profile || !this.profile.id) {
      this.alert.toastWarning('WARNING_MISSING_PROFILE');
      return;
    }
    const { id, type, email = '', firstName = '', lastName = '', birthDate } = this.profile;
    let body = `${this.translate.instant('PROFILE_DELETE_BODY')} ${email}.\n\n`;
    body += `Id: ${id},\n`;
    body += `${this.translate.instant('PROFILE_ACCOUNT_TYPE')}: ${this.translate.instant(`PROFILE_TYPE.${type}`)},\n`;
    body += `${this.translate.instant('FIRSTNAME')}: ${firstName},\n`;
    body += `${this.translate.instant('LASTNAME')}: ${lastName},\n`;
    body += `${this.translate.instant('BIRTHDATE')}: ${moment(birthDate).isValid() ? moment(birthDate).format('DD/MM/YYYY') : ''}`;
    this.extLink.email(environment.SUPPORT_EMAIL, this.translate.instant('PROFILE_DELETE_SUBJECT'), body);
  }

  prefillFormFields() {
    this.storage.get('onboardingFormSlideOne').then((obj) => {
      if (obj) {   
        console.log('obj.firstname', obj.firstName);
        this.form.get('firstName').patchValue(obj.firstName);
        console.log('obj.lastName', obj.lastName);
        this.form.get('lastName').patchValue(obj.lastName);
        console.log('obj.birthDate', obj.birthDate);
        this.form.get('birthDate').patchValue(obj.birthDate);
        console.log('obj.birthPlace', obj.birthPlace);
        this.form.get('birthPlace').patchValue(obj.birthPlace); 
      }
    }).then(() => {
      this.storage.remove('onboardingFormSlideOne');
    });
    this.storage.get('onboardingFormSlideTwo').then((obj) => {
      if (obj) {
        console.log('obj.city', obj.city);
        this.form.get('address').get('city').patchValue(obj.city);
        console.log('obj.cap', obj.cap);
        this.form.get('address').get('cap').patchValue(obj.cap);
        console.log('obj.street', obj.street);
        this.form.get('address').get('street').patchValue(obj.street);
        console.log('obj.country', obj.country);
        this.form.get('address').get('country').patchValue(obj.country);
      }
    }).then(() => {
      this.storage.remove('onboardingFormSlideTwo');
    });
    this.storage.get('onboardingFormSlideThree').then((obj) => {
      if(obj) { 
        console.log('obj.taxCode', obj.taxCode);
        this.form.get('taxCode').patchValue(obj.taxCode);
        console.log('obj.cellPhone', obj.cellPhone);
        this.form.get('cellPhone').patchValue(obj.cellPhone);
        console.log('obj.job', obj.job);
        this.form.get('job').patchValue(obj.job);
        console.log('obj.commuter', obj.commuter);
        this.form.get('commuter').patchValue(obj.commuter);
      }
    })
    .then(() => {
      this.storage.remove('onboardingFormSlideThree');
    });
    this.storage.get('privacyPolicy').then((obj) => {
      if(obj) {  
        this.form.get('privacyPolicy').patchValue(obj);
      }
    }).then(() => {
      this.storage.remove('privacyPolicy');
    });
  }

}
