import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { setFormTouched } from 'src/app/utils/form';
import { TRANSPORT_TYPE, ROUTE_TYPE, PACKAGE_SIZE } from 'src/app/constants';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Events } from '@ionic/angular';
import { FindRouteParams } from 'src/app/models/findRouteParams';
import { Route } from 'src/app/models/route';
const INPUT_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'app-carry-upsert',
  templateUrl: './carry-upsert.page.html',
  styleUrls: ['./carry-upsert.page.scss'],
})
export class CarryUpsertPage implements OnInit {

  form: FormGroup;
  transportTypes = Object.keys(TRANSPORT_TYPE);
  packageSizes = Object.keys(PACKAGE_SIZE);
  minDate = new Date();

  constructor(
    protected fb: FormBuilder,
    private alert: AlertService,
    private spinner: SpinnerService,
    private routeService: RouteService,
    private auth: AuthService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private events: Events,
    private router: Router,
  ) {

    this.form = this.fb.group({
      type: [ROUTE_TYPE.carry, Validators.required],
      from: ['', [Validators.required]],
      to: ['', Validators.required],
      date: ['', Validators.required],
      packageSize: [''],
      transportType: [],
      gpsEnabled: [false],
    });
  }

  getId() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    return id;
  }

  async ngOnInit() {
    const id = this.getId();
    if (!id) {
      this.activatedRoute.queryParams
        .subscribe((params: FindRouteParams) => {
          const value = { ...params };
          delete value.type;
          this.initForm(value);
          this.selectedDate(moment(value.date));
        });
      return;
    }

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const route = await this.routeService.get(token, id);

      route.date = moment(route.date).format(INPUT_FORMAT);
      this.initForm(route);

      await this.spinner.dismiss();
    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }

  selectedFrom(place) {
    const { description = '' } = place || {};
    this.form.get('from').setValue(description);
  }

  selectedTo(place) {
    const { description = '' } = place || {};
    this.form.get('to').setValue(description);
  }

  selectedDate(date: moment.Moment) {
    this.form.get(`date`).setValue(date.format(INPUT_FORMAT));
  }

  async onSubmit() {
    if (!this.form.valid) {
      setFormTouched(this.form);
      this.alert.toastWarning('WARNING_FORM_INVALID');
      return;
    }

    const route = this.getFormValue();

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();

      const id = this.getId();
      if (id) {
        await this.routeService.update(token, id, route);
      } else {
        await this.routeService.create(token, route);
      }
      await this.spinner.dismiss();
      await this.alert.toastSuccess();

      this.events.publish('carry:upsert', { id });

      if (!id) {
        this.router.navigate(['/tabs/my-carries'], { replaceUrl: true });
      } else {
        this.location.back();
      }

    } catch (err) {
      this.spinner.dismissWithAlertError(err);
    }
  }

  getFormValue() {
    const route = this.form.value;
    const { maximumPrice, packageSize, transportType, date } = route;

    route.date = moment(date, INPUT_FORMAT);

    if (typeof maximumPrice !== 'number') {
      delete route.maximumPrice;
    }
    if (typeof packageSize !== 'string') {
      delete route.packageSize;
    }
    if (typeof transportType !== 'string' || transportType === TRANSPORT_TYPE.all) {
      delete route.transportType;
    }

    return route;
  }

  initForm(value: Route | FindRouteParams) {
    const { transportType, packageSize } = value;
    if (!transportType) {
      value.transportType = TRANSPORT_TYPE.all;
    }
    if (!packageSize) {
      value.packageSize = ''
    }
    this.form.patchValue(value);
  }

  get showClearSize(): boolean {
    if (this.form) {
      const { packageSize = '' } = this.form.value;
      return !!packageSize
    }
    return false
  }

  clearSize() {
    this.form.patchValue({ packageSize: '' })
  }
}
