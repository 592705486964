import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Profile } from 'src/app/models/profile';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Camera, PictureSourceType, DestinationType, EncodingType, CameraOptions } from '@ionic-native/camera/ngx';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { UploadDocument } from 'src/app/models/uploadDocument';
import { DOCUMENT_TYPE, DOCUMENT_STATUS } from 'src/app/constants';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Wallet } from 'src/app/models/wallet';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-identity-card',
  templateUrl: './identity-card.page.html',
  styleUrls: ['./identity-card.page.scss'],
})
export class IdentityCardPage implements OnInit {

  @ViewChild('inputFileFront', { static: false }) inputFileFront: ElementRef;
  @ViewChild('inputFileBack', { static: false }) inputFileBack: ElementRef;

  profile: Profile;
  front: string;
  back: string;
  types = [DOCUMENT_TYPE.IDCard, DOCUMENT_TYPE.EUPassport, DOCUMENT_TYPE.NonEUPassport];
  doc: UploadDocument = {};
  isCreating = '';

  constructor(
    private profileService: ProfileService,
    private actionSheet: ActionSheetController,
    private translate: TranslateService,
    private camera: Camera,
    private auth: AuthService,
    private spinner: SpinnerService,
    private alert: AlertService,
    private alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private platform: Platform,
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      params => (this.isCreating = params && params.isCreating),
    );

    this.profile = await this.profileService.getProfile();
    const { firstName = '', lastName = '' } = this.profile || {};
    this.doc.fileName = `${firstName}_${lastName}_${moment().format('DD/MMMM/YYYY')}.jpeg`;

    this.doc.type = this.types[0];
  }

  showDocumentsStatus() {
    const docs = this.getIDDocuments();
    return docs && docs.length > 0;
  }

  getIDDocuments() {
    // considero solo i documenti di identità, esclusi quelli dell'IBAN
    const { wallet = {} as Wallet } = this.profile || {};
    const { docs = [] } = wallet || {};
    return docs.filter(({ type }) => type !== DOCUMENT_TYPE.IBANScan);
  }

  getLastIDDocument() {
    return [...this.getIDDocuments()].pop();
  }

  canUpload() {
    const docs = this.getIDDocuments();
    return docs.every(({ status }) => status !== DOCUMENT_STATUS.notVerifiedYet);
  }

  // tslint:disable:no-string-literal
  async chooseFile(part) {
    if (!this.platform.is('cordova')) {
      if (part === 'front') {
        this.inputFileFront.nativeElement.click();
      } else {
        this.inputFileBack.nativeElement.click();
      }
      return;
    }
    try {
      const texts = await this.translate
        .get(['UPLOAD_DOCUMENT', 'GALLERY', 'CLOSE', 'CAMERA', 'PDF_DOCUMENT'])
        .toPromise();
      const sheet = await this.actionSheet.create({
        mode: 'md',
        header: texts['UPLOAD_DOCUMENT'],
        buttons: [
          {
            text: texts['CAMERA'],
            icon: 'camera',
            handler: async () => this.pickPicture(PictureSourceType.CAMERA, part),
          },
          {
            text: texts['GALLERY'],
            icon: 'images',
            handler: async () => this.pickPicture(PictureSourceType.PHOTOLIBRARY, part),
          },
          {
            text: texts['CLOSE'],
            role: 'cancel',
            icon: 'close',
          },
        ],
      });
      await sheet.present();
    } catch (err) {
      console.log(err);
    }
  }

  async pickPicture(
    sourceType: PictureSourceType = PictureSourceType.CAMERA,
    part: string = 'front',
  ) {
    const options: CameraOptions = {
      destinationType: DestinationType.DATA_URL,
      encodingType: EncodingType.JPEG,
      correctOrientation: true,
      sourceType,
      targetWidth: 2048,
      targetHeight: 2048,
    };

    try {
      const imageData = await this.camera.getPicture(options);
      this[part] = `data:image/jpeg;base64,${imageData}`;
      return null;
    } catch (err) {
      console.log(err);
    }
  }

  async save() {
    const { wallet } = this.profile;
    const { id = '' } = wallet || {};
    if (!id) {
      this.alert.translate('ERROR_NO_WALLET');
      return;
    }

    try {
      const frontImage: any = await this.createImage('front');
      const backImage: any = await this.createImage('back');

      const { naturalWidth: frontWidth, naturalHeight: frontHeight } = frontImage;
      const { naturalWidth: backWidth, naturalHeight: backHeight } = backImage;

      const width = Math.max(frontWidth + 20, backWidth + 20);

      const canvas: any = document.createElement('canvas');
      canvas.width = width;
      canvas.height = frontHeight + backHeight + 40;
      const ctx = canvas.getContext('2d');

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(frontImage, 10, 10);
      ctx.drawImage(backImage, 10, frontHeight + 20);

      this.doc.buffer = canvas
        .toDataURL('image/jpeg')
        .replace(/^data:image\/(png|jpg|jpeg);base64,/, '');

      await this.spinner.show();

      const token = await this.auth.getToken();
      await this.profileService.uploadDocument(token, this.doc);

      await this.spinner.dismiss();

      this.profile = await this.profileService.me(token);

      await this.success();
    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }

  createImage(part) {
    return new Promise(resolve => {
      const image = new Image();
      // tslint:disable-next-line:only-arrow-functions space-before-function-paren
      image.onload = function () {
        resolve(image);
      };
      image.src = this[part];
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async success() {
    const texts = await this.translate
      .get(['UPLOAD_DOCUMENT_SUCCESS', 'UPLOAD_DOCUMENT_SUCCESS_MESSAGE'])
      .toPromise();
    const alert = await this.alertController.create({
      header: texts['UPLOAD_DOCUMENT_SUCCESS'],
      subHeader: texts['UPLOAD_DOCUMENT_SUCCESS_MESSAGE'],
      buttons: [
        {
          text: 'OK',
          handler: () => {
            if (this.isCreating === 'true') {
              this.alert.confirm({
                titleKey: 'COMPLETE_IBAN',
                messageKey: 'COMPLETE_IBAN_MESSAGE',
                okTextKey: 'COMPLETE_IBAN_OK',
                cancelTextKey: 'COMPLETE_IBAN_CANCEL',
                okHandler: () => this.router.navigate(['/iban'], { replaceUrl: true }),
              });
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async inputFileChange(event, part: string) {
    const [file = null] = event.target.files || [];
    if (file) {
      this[part] = await this.getBase64(event.target.files[0]);
    }
  }
}
