import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const { API_URL } = environment;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ratings } from '../../models/profile';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(private http: HttpClient) {}

  postRating(token: string, travelId: number, ratings: Ratings) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
      .post<Ratings>(`${API_URL}/travels/${travelId}/rating`, ratings, { headers })
      .toPromise();
  }

  getTravelRating(token: string, travelId: number) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
      .get<Ratings>(`${API_URL}/travels/${travelId}/rating`, { headers })
      .toPromise();
  }
}
