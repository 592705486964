import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const { API_URL, LEMONWAY_RETURN_URL, LEMONWAY_CANCEL_URL, LEMONWAY_ERROR_URL } = environment;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Travel } from 'src/app/models/travel';
import { PaymentForm } from 'src/app/models/paymentForm';

@Injectable({
  providedIn: 'root'
})
export class TravelService {

  constructor(
    private http: HttpClient,
  ) { }

  async get(token: string, id: number | string): Promise<Travel> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<Travel>(`${API_URL}/travels/${id}`, { headers }).toPromise();
  }

  async setPrice(token: string, id: number | string, price: number): Promise<any> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<Travel>(`${API_URL}/travels/${id}/price`, { price }, { headers }).toPromise();
  }

  async pickup(token: string, id: number | string, code: string): Promise<any> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post(`${API_URL}/travels/${id}/pickup`, { code }, { headers }).toPromise();
  }

  async dropoff(token: string, id: number | string, code: string): Promise<any> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post(`${API_URL}/travels/${id}/dropoff`, { code }, { headers }).toPromise();
  }

  async remove(token: string, id: number | string): Promise<any> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.delete(`${API_URL}/travels/${id}`, { headers }).toPromise();
  }

  async pay(token: string, id: number | string): Promise<PaymentForm> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    // return this.http.post<PaymentForm>(`${API_URL}/travels/${id}/pay-fake`, {}, { headers })
    //   .toPromise()
    //   .then(res => res || { paymentFormUrl: '' });

    const body = {
      returnUrl: LEMONWAY_RETURN_URL,
      cancelUrl: LEMONWAY_CANCEL_URL,
      errorUrl: LEMONWAY_ERROR_URL
    };
    return this.http.post<PaymentForm>(`${API_URL}/travels/${id}/pay`, body, { headers }).toPromise();
  }
}
