import { Component, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { Route } from 'src/app/models/route';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { IonInfiniteScroll, IonRefresher, Events } from '@ionic/angular';
import { RouteParams } from 'src/app/models/routeParams';
import { ROUTE_STATE, ROUTE_TYPE } from 'src/app/constants';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-my-sends',
  templateUrl: './my-sends.page.html',
  styleUrls: ['./my-sends.page.scss'],
})
export class MySendsPage implements OnDestroy {

  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher, { static: false }) refresher: IonRefresher;

  routeStates = Object.keys(ROUTE_STATE);
  routes: Route[] = [];
  offset = 0;
  state: ROUTE_STATE = ROUTE_STATE.waiting;
  profile: Profile;

  constructor(
    public spinner: SpinnerService,
    private auth: AuthService,
    private routeService: RouteService,
    private events: Events,
    private profileService: ProfileService,
    private zone: NgZone,
  ) {

    this.profileService.getProfile().then(p => this.profile = p);

    events.subscribe('send:upsert', ({ id }) => {
      if (!id) {
        this.state = ROUTE_STATE.waiting;
      }
      this.onTabChange();
    });

    events.subscribe('send:delete', ({ id }) => this.onTabChange());
    events.subscribe('send:refresh', ({ id }) => { console.log('refresh'); this.onTabChange(); });
    events.subscribe('send:pay:success', ({ id }) => {
      this.state = ROUTE_STATE.active;
      this.onTabChange();
    });

    this.fetch();
  }

  ngOnDestroy() {
    this.events.unsubscribe('send:upsert');
    this.events.unsubscribe('send:delete');
    this.events.unsubscribe('send:refresh');
  }

  async fetch() {
    if (this.spinner.spinning) { return; }

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();

      const params: RouteParams = {
        token,
        type: ROUTE_TYPE.send,
        state: this.state,
        offset: this.offset,
      };

      const { body, headers } = await this.routeService.fetch(params);
      this.routes = this.routes.concat(body);

      this.offset += body.length;

      const count = parseInt(headers.get('x-total-count'), 10);
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = this.routes.length >= count;

      await this.spinner.dismiss();
    } catch (err) {
      this.infiniteScroll.complete();
      this.spinner.dismissWithAlertError(err);
    }

  }

  onTabChange(event?) {
    this.zone.run(async () => {
      this.routes = [];
      this.offset = 0;

      this.refresher.complete();
      this.refresher.disabled = true;
      await this.fetch();
      this.refresher.disabled = false;
    });
  }
}
