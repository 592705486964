import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SelectCityComponent } from '../components/modal-select-city/select-city.component';
import { Place } from '../models/place';

@Directive({
  selector: '[appSelectCity]'
})
export class SelectCityDirective {

  @Input() type: string;
  @Input() titleKey: string;
  @Output() selected: EventEmitter<Place> = new EventEmitter<Place>();

  constructor(private modal: ModalController) {
  }

  @HostListener('click')
  async onClick() {
    const modal = await this.modal.create({
      backdropDismiss: false,
      showBackdrop: true,
      component: SelectCityComponent,
      componentProps: {
        title: this.titleKey,
        type: this.type
      }
    });

    modal.onDidDismiss()
      .then(({ data }) => {
        if (!data) { return; }
        this.selected.emit(data);
      });

    return await modal.present();
  }
}
