export enum PACKAGE_SIZE {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xLarge = 'xLarge',
  pallet_small = 'pallet_small',
  pallet_medium = 'pallet_medium',
  pallet_large = 'pallet_large',
  pallet_xLarge = 'pallet_xLarge',
  over = 'over',
}

export enum TRANSPORT_TYPE {
  all = 'all',
  foot_cycle = 'foot_cycle',
  city_car = 'city_car',
  wagon = 'wagon',
  suv = 'suv',
  luxury = 'luxury',
  van = 'van',
  truck = 'truck',
  other = 'other',
}

export enum ROUTE_STATE {
  waiting = 'waiting',
  active = 'active',
  delivered = 'delivered',
}

export enum ROUTE_TYPE {
  send = 'send',
  carry = 'carry',
}

export enum PROFILE_TYPE {
  private = 'private',
  company = 'company',
  professional = 'professional',
}

export enum DOCUMENT_TYPE {
  IDCard = 'IDCard',
  ProofOfAddress = 'ProofOfAddress',
  IBANScan = 'IBANScan',
  EUPassport = 'EUPassport',
  NonEUPassport = 'NonEUPassport',
  ResidencePermit = 'ResidencePermit',
  CompanyRegistration = 'CompanyRegistration',
  DriverLicence = 'DriverLicence',
  Status = 'Status',
  Durc = 'Durc'
}

export enum DOCUMENT_STATUS {
  onHold = 'onHold',
  notVerifiedYet = 'notVerifiedYet',
  accepted = 'accepted',
  notAccepted = 'notAccepted ',
  unreadable = 'unreadable',
  expired = 'expired',
  wrongType = 'wrongType',
  wrongName = 'wrongName'
}

export enum IBAN_STATUS {
  waiting = 'waiting',
  activated = 'activated',
  deactivated = 'deactivated',
  rejected = 'rejected',
}

export enum TRAVEL_STATUS {
  idle = 'idle',
  waiting_pickup = 'waiting_pickup',
  in_transit = 'in_transit',
  delivered = 'delivered'
}

export enum NOTIFICATION_TYPE {
  match = 'match',
  priceProposal = 'price_proposal',
  paymentSuccessful = 'payment_successful',
  travelCancel = 'travel_cancel',
  travelClosed = 'travel_closed',
  shipmentPickedUp = 'shipment_picked_up',
  shipmentDelivered = 'shipment_delivered',
  chat = 'chat',
  deadline = 'deadline',
}

export const LOGO_PATH = 'assets/logo-new.png';