import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-contract',
  templateUrl: 'modal-contract.component.html',
  styleUrls: ['modal-contract.component.scss'],
})
export class ModalContractComponent {

  constructor(
    private modal: ModalController,
  ) {
  }

  close() {
    this.modal.dismiss({ accept: false });
  }

  accept() {
    this.modal.dismiss({ accept: true });
  }
}
