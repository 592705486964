import { Component, Input } from "@angular/core";
import { Route } from "src/app/models/route";
import { AlertService } from "src/app/services/alert/alert.service";
import { SpinnerService } from "src/app/services/spinner/spinner.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { RouteService } from "src/app/services/routes/route.service";
import { Events } from "@ionic/angular";
import { Location } from "@angular/common";
import { ROUTE_TYPE } from "src/app/constants";
import { Profile } from "src/app/models/profile";
import { isMyRoute } from "src/app/utils/route";
import { TravelService } from "src/app/services/travel/travel.service";
import { Router } from "@angular/router";

@Component({
  selector: "route-waiting",
  templateUrl: "./route-waiting.component.html",
  styleUrls: ["./route-waiting.component.scss"],
})
export class RouteWaitingComponent {
  @Input() route: Route;
  @Input() profile: Profile;

  constructor(
    private alert: AlertService,
    private spinner: SpinnerService,
    private auth: AuthService,
    private routeService: RouteService,
    private events: Events,
    private location: Location,
    private travelService: TravelService,
    private router: Router
  ) {}

  hasTravel() {
    const { travels = [] } = this.route || {};
    return travels.length > 0;
  }

  isSend() {
    return this.route && this.route.type === ROUTE_TYPE.send;
  }

  isCarry() {
    return this.route && this.route.type === ROUTE_TYPE.carry;
  }

  isMyRoute() {
    return isMyRoute(this.route, this.profile);
  }

  isTravelActorProfile(actor: Profile) {
    const { id: profileId = "" } = this.profile || {};
    const { id = "" } = actor || {};
    return id === profileId;
  }

  async deleteRoute() {
    await this.alert.confirm({
      messageKey: `DELETE_ROUTE_${this.route.type}_MESSAGE`,
      okHandler: async () => {
        try {
          await this.spinner.show();
          const token = await this.auth.getToken();
          await this.routeService.remove(token, this.route.id);
          await this.spinner.dismiss();

          await this.alert.toastSuccess();

          const topic = this.isSend() ? "send:delete" : "carry:delete";
          const tab = this.isSend() ? "/tabs/my-sends" : "/tabs/my-carries";
          this.events.publish(topic, { id: this.route.id });
          this.router.navigate([tab], { replaceUrl: true });
        } catch (err) {
          await this.spinner.dismissWithAlertError(err);
        }
      },
    });
  }

  async deleteTravel() {
    await this.alert.confirm({
      messageKey: `DELETE_PROPOSAL_MESSAGE`,
      okHandler: async () => {
        try {
          await this.spinner.show();
          const token = await this.auth.getToken();

          const { travels = [] } = this.route || {};
          const travel = travels.find((t) => t.actor.id === this.profile.id);

          if (!travel) {
            return;
          }

          await this.travelService.remove(token, travel.id);
          await this.spinner.dismiss();

          await this.alert.toastSuccess();

          this.router.navigate(["/tabs/home"], { replaceUrl: true });
        } catch (err) {
          await this.spinner.dismissWithAlertError(err);
        }
      },
    });
  }
}
