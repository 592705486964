import { Component, Input } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'swg-header',
  templateUrl: './swg-header.component.html',
  styleUrls: ['./swg-header.component.scss'],
})
export class SwgHeaderComponent {
  @Input() title: string;

  constructor(
  ) {
  }
}
