import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alert/alert.service';
import { environment } from '../../../environments/environment';
import { DEFAULT_LANG, TranslateConfigService } from '../translate/translate-config.service';
const { SHOW_STACK_ERROR } = environment;

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinner: any;
  spinning: boolean;

  constructor(
    public loading: LoadingController,
    public translate: TranslateService,
    public translateConfig: TranslateConfigService,
    public alert: AlertService,
  ) { }

  show(): Promise<any> {

    if (this.spinning) {
      return Promise.resolve();
    }

    this.spinning = true;
    const message = this.translate.instant('Attendere');

    return this.loading.create({ message })
      .then(spinner => {
        this.spinner = spinner;
        spinner.present();
      });
  }

  isSpinning() {
    return this.spinning;
  }

  dismiss(): Promise<any> {
    return this.spinner.dismiss()
      .then(() => this.spinning = false)
      .catch(error => console.log(`Error in dismissing spinner: ${error}`));
  }

  dismissWithAlert(message: string, title?: string, innerValue?: string) {
    return this.spinner.dismiss().then(() => {
      this.spinning = false;
      return this.alert.translate(message, title, innerValue);
    });
  }

  async dismissWithAlertError(error) {
    const { status } = error;
    if (status === 401) {
      await this.spinner.dismiss();
      this.spinning = false;
      return this.alert.showAlert401();
    }

    const value = this.extractError(error);
    return this.dismissWithAlert('GENERIC_ERROR', 'SORRY', value);
  }

  extractError(e: any): string {
    if (typeof e === 'string') {
      return e;
    }

    const { error } = e;
    if (!error) {
      return this.extractError({ error: e });
    }

    const { message = '', stack = '', status = '' } = (typeof error !== 'string' ? error : e) || {};

    if (status === 401 || status === '401') {
      return this.translate.instant('ERRORS.UNAUTHORIZED');
    }

    if (typeof message === 'object') {
      const lang = this.translateConfig.getCurrentLanguage();
      let result = message[lang] || message[DEFAULT_LANG];
      if (SHOW_STACK_ERROR) {
        result += `\n\n${stack}`;
      }
      return result;
    }

    return `${status}\n${message}`;
  }
}
