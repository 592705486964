import { Injectable } from '@angular/core';

// const request = require('request');
// const http = require("https");
// import axios from 'axios';
// import unirest from 'unirest';
import https from "https";

import requests from 'requests';

const AUTH_URL = 'https://test.oauth.openapi.it/token';
const BASE_URL = 'https://test.visengine2.altravia.com/richiesta';
const SCOPE = 'POST:test.visengine2.altravia.com/richiesta';

@Injectable({
  providedIn: 'root'
})
export class DurcService {

  constructor(
  ) {
  }

  verifyDurc(a: string) {
    try {

      const options = {
        method: 'POST',
        url: 'https://test.visengine2.altravia.com/richiesta',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer 6282630b90a1a422763213d4'
        },
        body: {
          state: 0,
          test: true,
          hash_visura: "3cbbb9c833f1c94af35494820cda3370",
          "json_visura": {
            "$0": "federico",
            "$1": "STFFRC95E21B157P",
            "$2": "",
            "$3": "STFFRC95E21B157P",
            "$4": "contact@appsodio.com",
            "$5": "ahdwejw",
            "$6": "yrtyryrt",
            "$7": "piopoipiop",
            "$8": "fqweqweqe"
            },
          email_target: 'contact@appsodio.com',
        },
        json: true
      };
      
      requests(options, function (error, response, body) {
        console.log(error);
        console.log(response);
        console.log(body);
        if (error) throw new Error(error);
        console.log(response);
        console.log(body);
      });

      /*
      axios({
        method: 'post',
        url: 'test.oauth.openapi.it/token',
        headers: {
          "content-type": "application/json",
          "Authorization": "Basic REPLACE_BASIC_AUTH"
        },
        data: {
          scopes: ['POST:test.visengine2.altravia.com/richiesta'],
          expire: 2592000
        }
      })
      .then(function (response) {
        console.log('>>>response', response);
      })
      .catch(function (error) {
        console.log('>>>error', error);
      });
      */

    } catch(e) {
      console.log('>>>err', e);
    }
  }

  sendDurc() {
    try {


    } catch(e) {
      console.log('>>>err', e);
    }
  }

}
