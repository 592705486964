import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/auth/account.service';
import { LOGO_PATH } from 'src/app/constants';
import { ModalController } from '@ionic/angular';
import { NgForm } from "@angular/forms";
import { Onboarding } from 'src/app/models/onboarding';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { PROFILE_TYPE } from 'src/app/constants';
import { ProfileSelectTypeComponent } from 'src/app/components/profile-select-type/profile-select-type.component';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'intro',
  templateUrl: './intro.page.html',
  styleUrls: ['./intro.page.scss'],
})
export class IntroPage implements OnInit {

  logoPath = LOGO_PATH;
 
  emailValidFlag: boolean = false;
  privacyPolicyFlag: boolean = false;
  // politicallyExposedPersonFlag: boolean = false;

  onboarding: Onboarding = new Onboarding();

  constructor(
    private router: Router,
    private storage: Storage,
    private onboardingService: OnboardingService,
    private modalController: ModalController,
    private spinnerService: SpinnerService,
    private accountService: AccountService,
  ) {
  }

  ngOnInit() {
  }

  checkEmail(simpleForm: NgForm) {
    this.emailValidFlag = simpleForm.controls.email.errors ? false : true;
  }

  async jumpToTutorialPage(simpleForm: NgForm) {
    await this.storage.set('userEmail', simpleForm.value.email);
    await this.storage.set('privacyPolicy', simpleForm.value.privacyPolicy);
    // await this.storage.set('politicallyExposedPerson', simpleForm.value.politicallyExposedPersonFlag);
    if (this.emailValidFlag && this.privacyPolicyFlag) {
      await this.syncData(simpleForm);
      await this.openSelectType();
    }
  }

  async openSelectType() {
    const modalProfileType = await this.modalController.create({
      backdropDismiss: false,
      showBackdrop: false,
      component: ProfileSelectTypeComponent,
    });

    modalProfileType.onDidDismiss()
      .then(async ({ data }: { data: { type: PROFILE_TYPE } }) => {
        // console.log('>>>data', data);
        if (!data) { // TIP data is undefined, user has closed the modal without callback selection value
        } else if (data.type == PROFILE_TYPE.private) {
          await this.storage.set('profileType', data.type);
          this.router.navigate(['onboarding/tutorial']);
        } else {
          await this.accountService.loginWithFilledFields('signup', '/profile', this.onboarding.email);
          // this.router.navigate(['../profile']);
        } 
    });

    return await modalProfileType.present();
  }

  async syncData(simpleForm: NgForm) {
    try {
      await this.spinnerService.show();
      this.onboarding.email = simpleForm.value.email;
      this.onboarding.privacyPolicy = simpleForm.value.privacyPolicyFlag;
      // this.onboarding.politicallyExposedPerson = simpleForm.value.politicallyExposedPersonFlag;
      await this.onboardingService.create(this.onboarding);
      await this.spinnerService.dismiss();
    } catch (err) {
      console.log('>>>err', err);
      await this.spinnerService.dismissWithAlertError(err);
    }
  }

}
