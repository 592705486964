import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-thankyou-subscription',
  templateUrl: './thankyou-subscription.page.html',
  styleUrls: ['./thankyou-subscription.page.scss'],
})
export class ThankYouSubscriptionPage implements OnInit {

  isCreating = false;
  expiration = moment().add(1, 'years');

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        this.isCreating = params && params.isCreating;
      });
  }

  ok() {
    if (this.isCreating) {
      this.router.navigate(['/identity-card'], { replaceUrl: true, queryParams: { isCreating: 'true' } });
    } else {
      this.router.navigate(['/profile'], { replaceUrl: true });
    }
  }

}
