import { Route } from '../models/route';
import { Profile } from '../models/profile';
import { Travel } from '../models/travel';
import { ROUTE_TYPE } from '../constants';

export const amICarrier = (travel: Travel, profile: Profile) => {
  const { actor = {} as Profile, route = {} as Route } = travel || {};
  const { owner = {} as Profile, type = '' } = route || {};
  const { id = '' } = profile || {};

  if (type === ROUTE_TYPE.send) {
    if (id === actor.id) {
      return true;
    }
  } else if (type === ROUTE_TYPE.carry) {
    if (id === owner.id) {
      return true;
    }
  }

  return false;
};
