import { Route } from '../models/route';
import { Profile } from '../models/profile';
import { ROUTE_TYPE, TRAVEL_STATUS } from '../constants';
import * as moment from 'moment';
import { Travel, TravelSecurity } from '../models/travel';
import { getOwnerName } from '../pipes/owner.pipe';
import { formatPrice } from '../pipes/price.pipe';
import { TranslateService } from '@ngx-translate/core';

export const isMyRoute = (route: Route, profile: Profile) => {
  const { owner = {} as Profile } = route || {};
  const { id = '' } = profile || {};
  return id === owner.id;
};

export const getRouteColor = (route: Route, profile: Profile) => {
  const { type = '', owner = {} as Profile } = route || {};
  const { id = '' } = profile || {};
  if (owner.id === id) {
    return type === ROUTE_TYPE.carry ? 'secondary' : 'medium';
  }
  return type === ROUTE_TYPE.carry ? 'medium' : 'secondary';
};

export const shareText = (route: Route, travel: Travel, translate: TranslateService): string => {
  const { date, from = '', to = '' } = route
  const { actor = {} as Profile, price = 0, security = {} as TravelSecurity, pickupDate = null, travelStatus = TRAVEL_STATUS.idle } = travel || {}
  const { pickUpSecret = 0, dropOffSecret = 0, } = security || {}

  return `Ciao!
Ecco le informazioni della tua spedizione SiWeGO:

- Data di partenza: ${moment(date).format('DD MMMM YYYY')}
- Luogo di partenza: ${from}
- Luogo di arrivo: ${to}
- Trasportatore: ${getOwnerName(actor)}
- Costo trasporto: ${formatPrice(price)}
- Codice ritiro merce: ${pickUpSecret}
- Codice consegna merce: ${dropOffSecret}
- Data ritiro merce: ${pickupDate ? moment(pickupDate).format('DD/MM/YYYY - HH:mm') : '-'}
- Stato spedizione: ${translate.instant('TRAVEL_STATUS.' + travelStatus)}

Ricordati di condividere i codici di ritiro/consegna merce con l'altro capo della spedizione.
Dovranno essere comunicati al trasportatore SOLO nel momento del ritiro/consegna della merce.`
}