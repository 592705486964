import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent implements OnInit {

  @Input() messageKey: string;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.messageKey = this.messageKey || 'EMPTY_LIST';
  }
}
