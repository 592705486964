import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '../models/profile';
import { PROFILE_TYPE } from '../constants';

@Pipe({
  name: 'owner'
})
export class OwnerPipe implements PipeTransform {

  constructor() { }

  transform(value: Profile, args?: any): any {
    return getOwnerName(value)
  }
  
}

export const getOwnerName = (profile: Profile): string => {
  if (!profile) { return ''; }
  
  const { type = '', firstName = '', lastName = '', vatInfo = {} } = profile;
  const { companyName = '' } = vatInfo || {};
  switch (type) {
    case PROFILE_TYPE.private:
      return `${firstName} ${lastName && lastName[0]}.`;
    case PROFILE_TYPE.company:
      return companyName;
    case PROFILE_TYPE.professional:
      return companyName;
    default:
      return '';
  }
}
