
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { ProfileService } from '../profile/profile.service';
import { AuthService } from '../auth/auth.service';
import { PROFILE_TYPE } from 'src/app/constants';

@Injectable()
export class CarryPageGuard implements CanActivate {

  constructor(
    protected auth: AuthService,
    protected alert: AlertService,
    protected profile: ProfileService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    try {
      const loggedIn = await this.auth.isLoggedIn();
      if (!loggedIn) {
        return true;
      }

      const user = await this.profile.getProfile();
      if (user && user.type === PROFILE_TYPE.company) {
        await this.alert.translate('GUARD_COMPANY');
        return false;
      }

      return true;

    } catch (err) {
      return true;
    }

  }
}
