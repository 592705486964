import { Component, Input, ViewChild, NgZone, OnInit } from '@angular/core';
import { ModalController, IonSearchbar } from '@ionic/angular';
import { getCity, getProvince, getCountry, getAddress, getCap } from '../../utils/place';
import { Place } from 'src/app/models/place';
declare const google: any;

@Component({
  selector: 'select-city',
  templateUrl: 'select-city.component.html',
  styleUrls: ['select-city.component.scss'],
})
export class SelectCityComponent implements OnInit {

  @Input() title: string;
  @Input() type: string; // '(cities)', '(regions)', 'address'
  @Input() placeholder: string;
  @ViewChild(IonSearchbar, { static: false }) searchbar: IonSearchbar;

  predictions = [];
  search = '';
  input = true;

  GoogleAutocomplete;
  GooglePlaces;

  constructor(
    private modal: ModalController,
    private zone: NgZone,
  ) {
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.GooglePlaces = new google.maps.places.PlacesService(document.createElement('div'));
  }

  ngOnInit(): void {
    this.setPlaceholder();
  }

  ionViewDidEnter() {
    this.searchbar.setFocus();
  }

  changeSearch() {
    if (!this.input) { return; }

    if (this.search.length === 0) {
      this.predictions = [];
      return;
    }
    if (this.search.length < 3) {
      return;
    }

    const options: any = { input: this.search };
    if (this.type) {
      options.types = [this.type];
    }
    this.GoogleAutocomplete.getPlacePredictions(options, (predictions) => {
      this.zone.run(() => this.predictions = predictions);
    });
  }

  close() {
    this.modal.dismiss();
  }

  onSelected(prediction) {
    const { place_id = '', description = '' } = prediction || {};

    this.input = false;
    this.search = description;

    if (!place_id) { return; }

    const request = {
      placeId: place_id,
      fields: ['address_components'],
      types: ['political'],
    };

    this.GooglePlaces.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const result: Place = {
          city: getCity(place),
          province: getProvince(place),
          address: getAddress(place),
          country: getCountry(place),
          cap: getCap(place),
          description
        };
        this.modal.dismiss(result);
      }
    });
  }

  setPlaceholder() {
    switch (this.type) {
      case 'address':
        this.placeholder = 'SEARCH_ADDRESS_PLACEHOLDER';
        break;
      case '(regions)':
      case '(cities)':
      default:
        this.placeholder = 'SEARCH_CITY_PLACEHOLDER';
        break;
    }
  }
}
