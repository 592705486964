import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Storage } from '@ionic/storage';
import {
  BackgroundGeolocation,
  BackgroundGeolocationConfig,
  BackgroundGeolocationEvents,
  BackgroundGeolocationResponse,
  ServiceStatus,
  BackgroundGeolocationAuthorizationStatus,
} from '@ionic-native/background-geolocation/ngx';
import * as moment from 'moment';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../profile/profile.service';
const { API_URL, LOCATION_FREQUENCY } = environment;

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService implements OnDestroy {

  subscription: Subscription;

  constructor(
    private geolocation: Geolocation,
    private auth: AuthService,
    private http: HttpClient,
    private storage: Storage,
    private platoform: Platform,
    private backgroundGeolocation: BackgroundGeolocation,
    private translate: TranslateService,
    private profileService: ProfileService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async init() {
    if (!this.platoform.is('cordova')) {
      return; // se è l'app web non segnalo la posizione
    }

    try {
      const position = await this.geolocation.getCurrentPosition();
      if (position && position.coords) {
        await this.sendLocation(position.coords.latitude, position.coords.longitude);
      }
    } catch (err) {
      console.log('Error in send Location');
      console.log(err);
    }

    this.subscription = this.geolocation.watchPosition()
      .subscribe(position => {
        if (position && 'coords' in position) {
          this.sendLocation(position.coords.latitude, position.coords.longitude);
        }
      });

    try {
      const config: BackgroundGeolocationConfig = {
        distanceFilter: 50,
        startOnBoot: true,
        debug: false,
        stopOnTerminate: false,
        notificationTitle: this.translate.instant('NOTIFICATION_TRACKING_TITLE'),
        notificationText: this.translate.instant('NOTIFICATION_TRACKING_TEXT'),
      };

      await this.backgroundGeolocation.configure(config);

      this.backgroundGeolocation
        .on(BackgroundGeolocationEvents.location)
        .subscribe(async (location: BackgroundGeolocationResponse) => {
          const { latitude, longitude } = location;

          try {
            await this.sendLocation(latitude, longitude);
            this.backgroundGeolocation.finish();
          } catch (err) {
            this.backgroundGeolocation.finish();
          }
        });

      const enabled = await this.isEnabledBackground();
      if (enabled) {
        this.startBackground();
      }
    } catch (err) {
      console.log('err in background geolocation', err);
    }
  }

  async sendLocation(latitude: number, longitude: number): Promise<void> {
    try {
      const last = await this.storage.get('location-timestamp');
      if (last && moment().diff(last, 'minutes') < LOCATION_FREQUENCY) {
        return;
      }

      const token = await this.auth.getToken();
      if (!token) {
        throw new Error('No token');
      }

      const profile = await this.profileService.getProfile();
      if (!profile) {
        throw new Error('No profile');
      }

      const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      await this.http.post(`${API_URL}/profiles/location`, { latitude, longitude }, { headers }).toPromise();
      await this.storage.set('location-timestamp', Date.now());
    } catch (err) {
      console.log('Error in send Location', err);
    }
  }

  async clear() {
    await this.storage.remove('location-timestamp');
    await this.stopBackground();
  }

  async startBackground() {
    if (this.platoform.is('cordova')) {
      await this.storage.set('enabled-background', true);
      await this.backgroundGeolocation.start();
    }
  }

  async stopBackground() {
    if (this.platoform.is('cordova')) {
      await this.storage.remove('enabled-background');
      await this.backgroundGeolocation.stop();
    }
  }

  async checkStatusBackground(): Promise<ServiceStatus> {
    return await this.backgroundGeolocation.checkStatus();
  }

  async isBackgroundAuthorized(): Promise<boolean> {
    if (this.platoform.is('cordova')) {
      const { authorization } = await this.checkStatusBackground();
      return authorization === BackgroundGeolocationAuthorizationStatus.AUTHORIZED;
    }
    return false;
  }

  async isEnabledBackground(): Promise<boolean> {
    const authorized = await this.isBackgroundAuthorized();
    return authorized && await this.storage.get('enabled-background');
  }

  async showSettings(): Promise<void> {
    return this.backgroundGeolocation.showAppSettings();
  }
}
