export interface Deadline {
  id?: number;
  title: string;
  description: string;
  expiration: string;
}

export enum DeadlineType {
  TYPE_STAMP = 'TYPE_STAMP',
  TYPE_INSURANCE = 'TYPE_INSURANCE',
  TYPE_REVISION = 'TYPE_REVISION',
}

export const momentDeadlineFormat = 'DD/MM/YYYY';
