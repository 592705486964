import { AlertService } from './../../services/alert/alert.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AvatarService } from 'src/app/services/profile/avatar.service';
import { PROFILE_TYPE } from 'src/app/constants';

@Component({
  selector: 'profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
})
export class ProfileAvatarComponent implements OnInit {
  avatar$: Observable<string>;
  loading$: Observable<boolean>;
  profileType: PROFILE_TYPE;

  constructor(
    private avatarService: AvatarService,
    private alert: AlertService,
  ) {
  }

  async ngOnInit() {
    try {
      if (!this.showAvatar()) { return; }
      this.avatarService.load();
      this.avatar$ = this.avatarService.avatar;
      this.loading$ = this.avatarService.loading;
    } catch (error) {
      console.log(error)
      this.alert.toastWarning('AVATAR_LOADING_ERROR');
    }
  }

  showAvatar() {
    this.profileType = this.avatarService.getProfileType();
    return this.profileType !== PROFILE_TYPE.company &&
     this.profileType !== PROFILE_TYPE.professional;
  }
}
