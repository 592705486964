import { Component, OnDestroy, OnInit } from '@angular/core'
import { ProfileService } from 'src/app/services/profile/profile.service'
import { Profile } from 'src/app/models/profile'
import { PROFILE_TYPE } from 'src/app/constants'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit, OnDestroy {
  profile: Profile
  private profileSubscription: Subscription;

  constructor(
    private profileService: ProfileService,
  ) {}

  async ngOnInit() {
    try {
      this.profileSubscription = this.profileService.profile$.subscribe(
        (profile) => {
          this.profile = profile
        }
      )
    } catch (err) {
      console.log(err)
    }
  }

  canTransport() {
    const { type = '' } = this.profile || {}
    return type !== PROFILE_TYPE.company
  }

  ngOnDestroy() {
    this.profileSubscription.unsubscribe()
  }
}
