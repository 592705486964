import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
const { API_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class VerifyEmailService {
  constructor(private http: HttpClient) {}

  verifyEmail(id: number, email: string, wid: string) {
    return this.http
      .post(`${API_URL}/profiles/confirm-email`, { id, email, wid })
      .toPromise();
  }
}
