import { Component, Input } from '@angular/core';
import { ChatMessage, ChatMessageAttribute } from 'src/app/models/chatMessage';
import { isPriceMessage } from 'src/app/utils/chat';
import { ExtLinkService } from 'src/app/services/ext-link/ext-link.service';

@Component({
  selector: 'chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
})
export class ChatBubbleComponent {

  @Input() message: ChatMessage;
  @Input() email: string;

  constructor(
    private extLink: ExtLinkService,
  ) { }

  isMy() {
    const { author = '' } = this.message;
    return this.email === author;
  }

  async download() {
    const url = await this.message.media.getContentUrl();
    this.extLink.openBrowser(url);
  }

  isPriceMessage() {
    return isPriceMessage(this.message);
  }

  isMediaMessage() {
    return this.message.type === 'media';
  }
}
