import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { RouteFoundPage } from '../route-found.page';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carry-detail',
  templateUrl: 'carry-detail.page.html',
  styleUrls: ['../route-found.page.scss']
})
export class CarryDetailPage extends RouteFoundPage {

  constructor(
    protected location: Location,
    protected spinner: SpinnerService,
    protected auth: AuthService,
    protected profile: ProfileService,
    protected routeService: RouteService,
    protected alert: AlertService,
    protected router: Router,
  ) {
    super(location, spinner, auth, profile, routeService, alert, router);
  }

}
