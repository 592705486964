import { Component, OnInit, ViewChild, NgZone, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TravelService } from 'src/app/services/travel/travel.service';
import { Travel } from 'src/app/models/travel';
import { Route } from 'src/app/models/route';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { getChatMember, isPriceMessage } from 'src/app/utils/chat';
import { Profile } from 'src/app/models/profile';
import { ChatMessage } from 'src/app/models/chatMessage';
import { IonContent, AlertController, Events } from '@ionic/angular';
import { amICarrier } from 'src/app/utils/travel';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { environment } from '../../../environments/environment';
import { PricePipe } from 'src/app/pipes/price.pipe';
import { TRAVEL_STATUS } from 'src/app/constants';
import { isMyRoute } from 'src/app/utils/route';
const { MIN_TRAVEL_PRICE } = environment;
const pageSize = 15;
const MAX_ATTACHMENT_PAYLOAD = 5242880

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit, OnDestroy {

  @ViewChild(IonContent, { static: false }) private ionContent: IonContent;
  @ViewChild('file', { static: true }) private fileInput: ElementRef;

  travel: Travel;
  channel: any; // channel di twilio
  chatMember: Profile; // il tizio con cui sto parlando
  messages: ChatMessage[] = [];
  text: string;
  profile: Profile;
  hasPrevPage: boolean;
  lastIndexMessage: number;
  spinnerFetch: boolean;
  isTyping: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private auth: AuthService,
    private travelService: TravelService,
    private chat: ChatService,
    private alertController: AlertController,
    private profileService: ProfileService,
    private translate: TranslateService,
    private zone: NgZone,
    private alert: AlertService,
    private pricePipe: PricePipe,
    private router: Router,
    private events: Events,
  ) {
    events.subscribe('travel:cancelled', ({ id }) => this.alert.redirect(['/tabs/home'], '', 'TRAVEL_CANCELLED'));
    events.subscribe('travel:closed', ({ id }) => this.alert.redirect(['/tabs/home'], '', 'TRAVEL_CLOSED'));
    events.subscribe('travel:paymentSuccessful', ({ id }) => this.refreshTravel());
  }

  ngOnDestroy() {
    this.events.unsubscribe('travel:cancelled');
    this.events.unsubscribe('travel:closed');
    this.events.unsubscribe('travel:paymentSuccessful');
  }

  async ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('travelId');
    if (!id) { return; }

    try {
      await this.spinner.show();
      this.profile = await this.profileService.getProfile();

      const token = await this.auth.getToken();
      this.travel = await this.travelService.get(token, id);
      this.chatMember = getChatMember(this.travel, this.profile);

      await this.spinner.dismiss();

      if (this.isDelivered()) {
        this.router.navigate(['/route-detail', this.travel.route.id], { replaceUrl: true });
        return;
      }

      this.spinnerFetch = true;

      const { chatId } = this.travel;
      if (chatId) {
        const chatToken = await this.chat.getChatToken(token);

        try {
          this.channel = await this.chat.getChannel(chatToken, chatId);

          this.channel.on('messageAdded', (message: ChatMessage) => this.addMessage(message));
          this.channel.on('typingStarted', (member) => this.zone.run(() => this.isTyping = member.isTyping));
          this.channel.on('typingEnded', (member) => this.zone.run(() => this.isTyping = member.isTyping));

          const count = await this.channel.getMessagesCount();
          this.lastIndexMessage = count ? count - 1 : 0;

          await this.fetchMessages();
        } catch (err) {
          this.spinnerFetch = false;
        }

        this.scrollBottom();
      }

    } catch (err) {
      console.log(err);
      await this.spinner.dismissWithAlertError(err);
    }
  }

  async send() {
    const message = this.text;
    this.text = '';
    try {
      await this.channel.sendMessage(message);
    } catch (err) {
      this.text = message;
      const value = this.spinner.extractError(err);
      this.alert.translate('GENERIC_ERROR', 'SORRY', value);
    }
  }

  attach() {
    this.fileInput.nativeElement.click();
  }

  async onFileInputChange(files: FileList) {
    if (files && files[0]) {
      try {
        if(files[0].size > MAX_ATTACHMENT_PAYLOAD) throw this.translate.instant('MAX_ATTACHMENT_PAYLOAD_ERROR')
        const data = new FormData()
        data.append('file', files[0], files[0].name);
        this.spinner.show();
        await this.channel.sendMessage(data);
        this.spinner.dismiss();
      } catch (err) {
        this.spinner.dismiss();
        const value = this.spinner.extractError(err);
        this.alert.translate('GENERIC_ERROR', 'SORRY', value);
      }
    }
  }


  async addMessage(message: ChatMessage) {
    this.messages.push(message);
    this.scrollBottom();

    if (isPriceMessage(message)) {
      const token = await this.auth.getToken();
      this.travel = await this.travelService.get(token, this.travel.id);
    }
  }

  async fetchMessages() {
    if (!this.channel) { return; }
    const { items, hasPrevPage } = await this.channel.getMessages(pageSize, this.lastIndexMessage);
    this.lastIndexMessage = (items && items.length > 0 && items[0].index - 1) || 0;
    this.messages.unshift(...items);
    this.hasPrevPage = hasPrevPage;
    this.spinnerFetch = false;
  }

  scrollBottom() {
    setTimeout(() => this.ionContent.scrollToBottom(100), 200);
  }

  goBackUrl() {
    const { route = {} as Route } = this.travel || {};
    const { id = '' } = route || {};
    if (!id) {
      return '/tabs/home';
    }
    return `/route-detail/${id}`;
  }

  async onScroll(event) {
    const { detail = {} } = event;
    const { scrollTop } = detail;
    if (scrollTop > 30 || !this.hasPrevPage || this.spinnerFetch) { return; }
    this.spinnerFetch = true;
    try {
      await this.fetchMessages();
    } catch (err) {
      console.log(err);
    }
  }

  amICarrier() {
    return amICarrier(this.travel, this.profile);
  }

  async changePrice() {
    const { route } = this.travel;
    const { maximumPrice = 0 } = route;

    // tslint:disable-next-line:max-line-length
    const texts = await this.translate.get(['PROPOSE_NEW_PRICE', 'PROPOSE_NEW_PRICE_SUB', 'MAX_PRICE_INDICATED', 'CANCEL', 'OK'], {
      min: this.pricePipe.transform(MIN_TRAVEL_PRICE),
      max: this.pricePipe.transform(maximumPrice),
    }).toPromise();

    const alert = await this.alertController.create({
      header: texts.PROPOSE_NEW_PRICE,
      subHeader: texts.PROPOSE_NEW_PRICE_SUB + (maximumPrice ? `\n\n${texts.MAX_PRICE_INDICATED}` : ''),
      inputs: [
        {
          name: 'price',
          type: 'number',
          value: this.travel.price,
          min: MIN_TRAVEL_PRICE,
          label: 'CIAO',
          id: 'price-chat-input'
        },
      ],
      buttons: [
        {
          text: texts.CANCEL,
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: texts.OK,
          handler: ({ price }) => {
            const floatPrice = Number(price);
            if (floatPrice < MIN_TRAVEL_PRICE) {
              const el = document.getElementById('price-chat-input') as HTMLInputElement;
              if (el) {
                el.value = '';
              }
              return false;
            }
            this.handlerSetPrice(floatPrice);
          },
        }
      ]
    });

    await alert.present();
  }

  async handlerSetPrice(price: number) {
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const { id } = this.travel;
      await this.travelService.setPrice(token, id, price);
      await this.spinner.dismiss();
    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }

  typing() {
    if (this.channel && this.text) {
      this.channel.typing();
    }
  }

  isIdle() {
    const { travelStatus = '' } = this.travel || {};
    return travelStatus === TRAVEL_STATUS.idle;
  }

  isDelivered() {
    const { travelStatus = '' } = this.travel || {};
    return travelStatus === TRAVEL_STATUS.delivered;
  }

  goToPay() {
    const { priceUpdatedAt, id } = this.travel;
    if (!priceUpdatedAt) {
      this.alert.translate('WAIT_PRICE_FOR_PAY');
      return;
    }
    this.router.navigate(['/payment-summary', id]);
  }

  async deleteTravel() {
    await this.alert.confirm({
      messageKey: `DELETE_PROPOSAL_MESSAGE`,
      okHandler: async () => {
        try {
          await this.spinner.show();
          const token = await this.auth.getToken();

          await this.travelService.remove(token, this.travel.id);
          await this.spinner.dismiss();

          await this.alert.toastSuccess();

          let url = '/tabs/home';
          if (isMyRoute(this.travel.route, this.profile)) {
            url = this.goBackUrl();
          }
          this.router.navigate([url], { replaceUrl: true });
        } catch (err) {
          await this.spinner.dismissWithAlertError(err);
        }
      }
    });
  }

  async refreshTravel() {
    const id = this.activatedRoute.snapshot.paramMap.get('travelId');
    if (!id) { return; }

    try {
      await this.spinner.show();
      this.profile = await this.profileService.getProfile();

      const token = await this.auth.getToken();
      this.travel = await this.travelService.get(token, id);
      await this.spinner.dismiss();

    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }
}
