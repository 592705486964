import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert/alert.service';

const parseDate = (date): NgbDateStruct => {
  const d = moment(date);
  const year = d.year();
  const month = d.month() + 1;
  const day = d.date();
  return { year, month, day };
};

@Component({
  selector: 'modal-datetime',
  templateUrl: 'modal-datetime.component.html',
  styleUrls: ['modal-datetime.component.scss'],
})
export class ModalDateTimeComponent implements OnInit {

  @Input() title: string;
  @Input() minDate: string | Date;
  @Input() maxDate?: string | Date;

  ngbDate: NgbDateStruct;
  ngbMinDate: NgbDateStruct;
  ngbMaxDate: NgbDateStruct;

  constructor(
    private modal: ModalController,
    private alert: AlertService,
  ) {
  }

  ngOnInit(): void {
    this.ngbMinDate = parseDate(this.minDate);
    this.ngbMaxDate = parseDate(this.maxDate ? moment(this.maxDate) : moment(this.minDate).add(5, 'years'));
  }

  onSelected() {
    setTimeout(() => {
      const { year, month, day } = this.ngbDate;
      const date = moment({ year, month: month - 1, day });

      this.modal.dismiss({ date });
    });
  }

  close() {
    this.modal.dismiss();
  }

}
