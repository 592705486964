import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const { API_URL } = environment;
import { InAppBrowserOptions, InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private iab: InAppBrowser,
    private translate: TranslateService,
    private platform: Platform,
  ) { }

  async pay(url: string, success?: () => any, error?: () => void, cancel?: () => void) {
    const lang = this.translate.getDefaultLang();
    const PAY_URL = `${url}&lang=${lang}`;

    if (!this.platform.is('cordova')) {
      window.open(PAY_URL, '_self');
      return;
    }

    const CLOSE = await this.translate.get('CLOSE').toPromise();

    const options: InAppBrowserOptions = {
      location: 'no',
      hidenavigationbuttons: 'yes',
      closebuttoncaption: CLOSE,
      usewkwebview: 'yes',
      cleardata: 'yes',
    };

    const browser = this.iab.create(PAY_URL, '_blank', options);
    browser.on('loadstart').subscribe(async (e) => {
      if (e.url.includes('success') || e.url.includes('thankyou')) {
        browser.close();
        if (typeof success === 'function') {
          success();
        }
      } else if (e.url.includes('error')) {
        browser.close();
        if (typeof error === 'function') {
          error();
        }
      } else if (e.url.includes('cancel') || e.url.includes('home')) {
        browser.close();
        if (typeof cancel === 'function') {
          cancel();
        }
      }
    });
    browser.on('loadstop').subscribe(async () => {
      await browser.insertCSS({
        file: `${API_URL}/static/credit-card.css`,
      });
    });
  }
}
