import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PROFILE_TYPE } from 'src/app/constants';

@Component({
  selector: 'profile-select-type',
  templateUrl: './profile-select-type.component.html',
  styleUrls: ['./profile-select-type.component.scss'],
})
export class ProfileSelectTypeComponent {

  constructor(
    private modal: ModalController,
  ) {
  }

  selectPrivate() {
    this.modal.dismiss({ type: PROFILE_TYPE.private });
  }
  selectCompany() {
    this.modal.dismiss({ type: PROFILE_TYPE.company });
  }
  selectProfessional() {
    this.modal.dismiss({ type: PROFILE_TYPE.professional });
  }

  close() {
    this.modal.dismiss();
  }
}
