import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TRANSPORT_TYPE, ROUTE_TYPE, PACKAGE_SIZE } from 'src/app/constants';
import * as moment from 'moment';
import { setFormTouched } from 'src/app/utils/form';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/analytics/google-analytics.service'
import { Platform } from '@ionic/angular';
const INPUT_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'app-send',
  templateUrl: './send.page.html',
  styleUrls: ['./send.page.scss'],
})
export class SendPage {
  form: FormGroup;
  transportTypes = Object.keys(TRANSPORT_TYPE);
  packageSizes = Object.keys(PACKAGE_SIZE);
  minDate = new Date();
  date = moment();

  constructor(private alert: AlertService, private fb: FormBuilder, private router: Router, private googleAnalyticsService: GoogleAnalyticsService, private platform: Platform) {
    this.form = this.fb.group({
      type: [ROUTE_TYPE.carry, Validators.required],
      from: ['', [Validators.required]],
      to: ['', Validators.required],
      date: ['', Validators.required],
      arrivalDate: [''],
      maximumPrice: [],
      packageSize: ['', Validators.required],
      transportType: [],
      gpsEnabled: [false],
      professionalCarrier: [false],
    });
    this.selectedDate(moment());
  }

  selectedFrom(place) {
    const { description = '' } = place || {};
    this.form.get('from').setValue(description);
  }

  selectedTo(place) {
    const { description = '' } = place || {};
    this.form.get('to').setValue(description);
  }

  selectedDate(date: moment.Moment) {
    this.date = date;
    this.form.get(`date`).setValue(date.format(INPUT_FORMAT));

    const arrivalDateValue = this.form.get(`arrivalDate`).value;

    // If arrival is before trip date, set it null
    if (arrivalDateValue) {
      if (moment(arrivalDateValue, INPUT_FORMAT).isBefore(date, 'day')) {
        this.form.get(`arrivalDate`).setValue(null);
      }
    }
  }

  selectedArrivalDate(date: moment.Moment) {
    this.form.get(`arrivalDate`).setValue(date.format(INPUT_FORMAT));
  }

  resetForm() {
    this.form.patchValue({
      from: '',
      to: '',
      date: '',
      arrivalDate: [''],
      maximumPrice: null,
      packageSize: null,
      transportType: null,
      gpsEnabled: false,
      professionalCarrier: false,
    });
  }

  async onSubmit() {
    if (!this.form.valid) {
      setFormTouched(this.form);
      this.alert.toastWarning('WARNING_FORM_INVALID');
      console.log(this.form);
      return;
    }

    const params = this.form.value;
    params.date = moment(params.date, INPUT_FORMAT);

    if (params.arrivalDate) {
      params.arrivalDate = moment(params.arrivalDate, INPUT_FORMAT);
    }

    const { transportType } = params;
    if (transportType === TRANSPORT_TYPE.all) {
      delete params.transportType;
    }

    this.router.navigate(['/find-carry'], { queryParams: params });
  }

  lookForCarryHitToGoogleAnalytics() {
    if (this.platform.is('cordova')) {
      this.googleAnalyticsService.trackClickEvent('event', 'look_for_carry_from_native', 'look for a carry', '', '', 0, true);
    } else {
      this.googleAnalyticsService.trackClickEvent('event', 'look_for_carry_from_web', 'look for a carry', '', '', 0, false);
    }
  }
  
}
