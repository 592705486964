import {Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DeadlineType, momentDeadlineFormat } from '../../models/Deadline';
import { Moment } from 'moment';

@Component({
  selector: 'modal-new-deadline',
  templateUrl: './modal-new-deadline.component.html',
  styleUrls: ['./modal-new-deadline.component.scss'],
})
export class ModalNewDeadlineComponent implements OnInit {

  @Input() id;
  @Input() title = '';
  @Input() description = '';
  @Input() expiration = '';
  form: FormGroup;
  deadlineTypes = Object.values(DeadlineType);
  minDate = new Date();
  maxDate = new Date();

  constructor(
    private modal: ModalController,
    protected fb: FormBuilder,
  ) {
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);
    this.form = this.fb.group({
      type: [this.title, [Validators.required]],
      description: [this.description, [Validators.required]],
      expiration: [this.expiration, [Validators.required]],
    });
  }

  ngOnInit() {
    this.form.patchValue({
      type: this.title,
      description: this.description,
      expiration: this.expiration,
    });
  }

  setExpiration(selected: Moment) {
    this.form.get('expiration').setValue(selected.format(momentDeadlineFormat));
  }

  async onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    await this.modal.dismiss({
      id: this.id,
      ...this.form.getRawValue(),
    });
  }

  async close() {
    await this.modal.dismiss();
  }
}
