import { Component, OnInit } from '@angular/core';
import { Events, ModalController, IonInfiniteScroll } from '@ionic/angular';
import moment from 'moment';
import { ModalNewDeadlineComponent } from 'src/app/components/modal-new-deadline/modal-new-deadline.component';
import { AuthService } from '../../services/auth/auth.service';
import { DeadlineService } from '../../services/deadlines/deadines.service';
import { AlertService } from '../../services/alert/alert.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { Deadline, momentDeadlineFormat } from '../../models/Deadline';

@Component({
  selector: 'app-deadlines',
  templateUrl: './deadlines.page.html',
  styleUrls: ['./deadlines.page.scss'],
})
export class DeadlinesPage implements OnInit {
  offset = 0;
  infiniteScroll?: IonInfiniteScroll;
  deadlines: Deadline[] = [];

  constructor(
    private events: Events,
    private modal: ModalController,
    private auth: AuthService,
    private deadlineService: DeadlineService,
    private spinner: SpinnerService,
    private alert: AlertService,
  ) { }

  async ngOnInit() {
    await this.getDeadlines();
  }

  async getDeadlines(event?: CustomEvent) {
    if (this.spinner.spinning) {
      return;
    }
    if (event) {
      this.infiniteScroll = event.target as unknown as IonInfiniteScroll;
    }
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const { offset } = this;
      const { body: deadlines, headers } = await this.deadlineService.getDeadlines(token, { offset });
      deadlines.forEach(deadline => {
        const { expiration } = deadline;
        deadline.expiration = moment(expiration).format(momentDeadlineFormat);
      });
      if (!offset) {
        this.deadlines = [];
      }
      this.deadlines = this.deadlines.concat(deadlines);
      this.offset += deadlines.length;

      const count = +headers.get('x-total-count');
      if (this.infiniteScroll) {
        await this.infiniteScroll.complete();
        this.infiniteScroll.disabled = this.deadlines.length >= count;
      }
      await this.spinner.dismiss();
    } catch (error) {
      this.offset = 0;
      this.deadlines = [];
      await this.spinner.dismissWithAlertError(error);
    }
  }

  async doDeleteDeadline(deadline: Deadline) {
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      await this.deadlineService.deleteDeadline(token, deadline.id);
      this.deadlines = this.deadlines.filter(({ id }) => id !== deadline.id);
      await this.spinner.dismiss();
    } catch (error) {
      await this.spinner.dismissWithAlertError(error);
    }
  }

  async deleteDeadline(deadline: Deadline) {
    this.alert.confirm({
      messageKey: 'DEADLINE.DELETE_MSG',
      okHandler: () => this.doDeleteDeadline(deadline),
    });
  }

  async upsertDeadline(deadline?: Deadline) {
    const modal = await this.modal.create({
      component: ModalNewDeadlineComponent,
      componentProps: deadline,
      cssClass: 'modal-new-deadline',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (!data) {
      return;
    }
    const { id, type, description, expiration } = data;
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const body = {
        title: type,
        description,
        expiration: moment(expiration, momentDeadlineFormat).toISOString(),
      };
      if (deadline) {
        await this.deadlineService.putDeadline(token, id, body);
      } else {
        await this.deadlineService.postDeadline(token, body);
      }
      await this.spinner.dismiss();
      this.offset = 0;
      await this.getDeadlines();
    } catch (error) {
      await this.spinner.dismissWithAlertError(error);
    }
  }

  async onRefresh(event) {
    this.offset = 0;
    await this.getDeadlines();
    event.target.complete();
  }
}
