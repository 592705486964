import getCountryISO3 from 'country-iso-2-to-3';

export const getCity = (place) => {
  const { address_components = [] } = place || {};
  const component = address_components.find(({ types = [] }) => types.indexOf('administrative_area_level_3') > -1);
  const { long_name = '' } = component || {};
  if (!long_name) {
    const component2 = address_components.find(({ types = [] }) => types.indexOf('locality') > -1);
    const { long_name: longName2 = '' } = component2 || {};
    return longName2;
  }
  return long_name;
};

export const getProvince = (place) => {
  const { address_components = [] } = place || {};
  const component = address_components.find(({ types = [] }) => types.indexOf('administrative_area_level_2') > -1);
  const { short_name = '' } = component || {};
  return short_name;
};

export const getCountry = (place) => {
  const { address_components = [] } = place || {};
  const component = address_components.find(({ types = [] }) => types.indexOf('country') > -1);
  const { short_name = '' } = component || {};
  return getCountryISO3(short_name);
};

export const getAddress = (place) => {
  const { address_components = [] } = place || {};
  const componentRoute = address_components.find(({ types = [] }) => types.indexOf('route') > -1);
  const { long_name = '' } = componentRoute || {};
  const componentNumber = address_components.find(({ types = [] }) => types.indexOf('street_number') > -1);
  const { short_name: longNameNumber = '' } = componentNumber || {};
  return `${long_name}${longNameNumber ? ', ' + longNameNumber : ''}`;
};

export const getCap = (place) => {
  const { address_components = [] } = place || {};
  const component = address_components.find(({ types = [] }) => types.indexOf('postal_code') > -1);
  const { long_name = '' } = component || {};
  return long_name;
};
