import { Directive, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalHtmlComponent } from '../components/modal-html/modal-html.component';

@Directive({
  selector: '[appModalHtml]'
})

export class ModalHtmlDirective {

  @Input() titleKey: string;
  @Input() contentKeys: string[];

  constructor(
    protected modal: ModalController, 
    ) {
    }

  @HostListener('click')
  async onClick() {
    const modal = await this.modal.create({
      component: ModalHtmlComponent,
      cssClass: 'modal-html',
      componentProps: {
        titleKey: this.titleKey,
        contentKeys: this.contentKeys,
      }
    });

    return await modal.present();
  }

}
