import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Profile, ProfessionalSubscription } from 'src/app/models/profile';
import { PROFILE_TYPE } from 'src/app/constants';

@Component({
  selector: 'subscription-inactive',
  templateUrl: './subscription-inactive.component.html',
  styleUrls: ['./subscription-inactive.component.scss'],
})
export class SubscriptionInactiveComponent {

  @Input() profile: Profile;

  constructor(
    private translate: TranslateService,
  ) { }

  isProfessional(): boolean {
    const { type = '' } = this.profile || {};
    return type === PROFILE_TYPE.professional;
  }

  isActive(): boolean {
    const { professionalSubscription = {} as ProfessionalSubscription } = this.profile;
    const { active = false } = professionalSubscription || {};
    return active;
  }
}
