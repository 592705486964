import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PACKAGE_SIZE, TRANSPORT_TYPE, ROUTE_TYPE } from 'src/app/constants';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { setFormTouched } from 'src/app/utils/form';
import { Platform } from '@ionic/angular';
import { GoogleAnalyticsService } from 'src/app/services/analytics/google-analytics.service'
import { platform } from 'os';
const INPUT_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'app-carry',
  templateUrl: './carry.page.html',
  styleUrls: ['./carry.page.scss'],
})
export class CarryPage {

  form: FormGroup;
  packageSizes = Object.keys(PACKAGE_SIZE);
  transportTypes = Object.keys(TRANSPORT_TYPE);
  minDate = new Date();

  constructor(
    private alert: AlertService,
    private fb: FormBuilder,
    private router: Router,
    private platform: Platform,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.form = this.fb.group({
      type: [ROUTE_TYPE.send, Validators.required],
      from: ['', [Validators.required]],
      to: ['', Validators.required],
      date: ['', Validators.required],
      transportType: [],
      packageSize: [],
    });
    this.selectedDate(moment());
  }

  selectedFrom(place) {
    const { description = '' } = place || {};
    this.form.get('from').setValue(description);
  }

  selectedTo(place) {
    const { description = '' } = place || {};
    this.form.get('to').setValue(description);
  }

  selectedDate(date: moment.Moment) {
    this.form.get(`date`).setValue(date.format(INPUT_FORMAT));
  }

  resetForm() {
    this.form.patchValue({
      from: '',
      to: '',
      date: '',
      transportType: null,
      packageSize: null,
    });
  }

  async onSubmit() {
    if (!this.form.valid) {
      setFormTouched(this.form);
      this.alert.toastWarning('WARNING_FORM_INVALID');
      return;
    }

    const params = this.form.value;
    params.date = moment(params.date, INPUT_FORMAT);

    const { transportType } = params;
    if (transportType === TRANSPORT_TYPE.all) {
      delete params.transportType;
    }

    this.router.navigate(['/find-send'], { queryParams: params });
  }
  
  offerCarryHitToGoogleAnalytics() {
    if (this.platform.is('cordova')) {
      this.googleAnalyticsService.trackClickEvent('event', 'offer_a_carry_from_native', 'offer a carry', '', '', 0, true);
    } else {
      this.googleAnalyticsService.trackClickEvent('event', 'offer_a_carry_from_web', 'offer a carry', '', '', 0, false);
    }
  }

}
