import { Platform, Events } from '@ionic/angular';
import { AuthNativeService } from './auth.native.service';
import { AuthWebService } from './auth.web.service';
import { Storage } from '@ionic/storage';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';

export function AuthFactory(platform: Platform, storage: Storage, safari: SafariViewController, events: Events) {
  if (platform.is('cordova')) {
    return new AuthNativeService(storage, safari, platform, events);
  } else {
    return new AuthWebService();
  }
}
