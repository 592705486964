import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { COMMUTERS_TYPES, JOB_TYPES } from '../../../components/profile-form/options';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment, { Moment } from 'moment';
import { AccountService } from 'src/app/services/auth/account.service';
import { Address } from 'src/app/models/onboarding';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ExtLinkService } from 'src/app/services/ext-link/ext-link.service';
import { GoogleAnalyticsService } from 'src/app/services/analytics/google-analytics.service';
import { IonSlides } from '@ionic/angular';
import { LOGO_PATH } from 'src/app/constants';
import { ModalController } from '@ionic/angular';
import { Onboarding } from 'src/app/models/onboarding';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { Place } from 'src/app/models/place';
import { ProfileFormBaseComponent } from '../../../components/profile-form/profile-form-base.component';
import { Storage } from '@ionic/storage';
import { TitleService } from 'src/app/services/title/title.service';
import { TranslateService } from '@ngx-translate/core';
import { setFormTouched } from 'src/app/utils/form';

@Component({ 
  selector: 'app-tutorial', 
  templateUrl: './tutorial.page.html',
  styleUrls: ['./tutorial.page.scss'],
})
export class TutorialPage extends ProfileFormBaseComponent implements OnInit {

  @ViewChild(IonSlides, { static: false }) ionSlides: any;
  sliderOptions = { initialSlide: 0, speed: 400 };
  sliderPager: boolean = false;

  logoPath: string = LOGO_PATH;
  jobTypes: string[] = JOB_TYPES;
  commutersTypes: string[] = COMMUTERS_TYPES;

  onboardingFormSlideOne: FormGroup;
  onboardingFormSlideTwo: FormGroup;
  onboardingFormSlideThree: FormGroup;

  onboarding: Onboarding = new Onboarding();

  constructor(
    private account: AccountService,
    private titleService: TitleService,
    private onboardingService: OnboardingService,
    private googleAnalyticsService: GoogleAnalyticsService,
    protected formBuilder: FormBuilder,
    protected modalController: ModalController,
    protected translateService: TranslateService,
    protected alertService: AlertService,
    protected extLinkService: ExtLinkService,
    protected storage: Storage,
  ) { 
    super(formBuilder, modalController, translateService, alertService, extLinkService, storage);
  }

  ngOnInit() {
    this.storage.get('userEmail').then((m) => { this.onboarding.email = m; });
    this.titleService.setTitle('slide 0');

    this.onboardingFormSlideOne = this.formBuilder.group({
      firstName: [{ value: null, disabled: false }, [Validators.required]],
      lastName: [{ value: null, disabled: false }, [Validators.required]],
      birthDate: [{ value: null, disabled: false }, [Validators.required]],
      birthPlace: [{ value: null, disabled: false }, [Validators.required]],
    });
    this.onboardingFormSlideTwo = this.formBuilder.group({
      city: [{ value: null, disabled: false }, [Validators.required]],
      cap: [{ value: null, disabled: false }, [Validators.required]],
      street: [{ value: null, disabled: false }, [Validators.required]],
      country: [{ value: null, disabled: false }, [Validators.required]],
    });
    this.onboardingFormSlideThree = this.formBuilder.group({
      taxCode: [{ value: null, disabled: false }, [Validators.required]],
      cellPhone: [{ value: null, disabled: false }, [Validators.required]],
      job: [{ value: JOB_TYPES[3], disabled: false }],
      commuter: [{ value: COMMUTERS_TYPES[0], disabled: false }],
    });
  }

  async skipToAuth0Page() {
    await this.account.loginWithFilledFields('signup', '/profile', this.onboarding.email);
  }

  selectedBirthDate(date: Moment) {
    this.onboardingFormSlideOne.get('birthDate').setValue(date.format(this.dateFormat));
  }

  selectedBirthplace(place: Place) {
    const { description = '' } = place;
    this.onboardingFormSlideOne.get('birthPlace').setValue(description);
  }

  selectedAddressCity(place: Place) {
    const { address = '', cap = '', city = '', country = '' } = place;
    this.onboardingFormSlideTwo.get('city').setValue(city);
    this.onboardingFormSlideTwo.get('street').setValue(address);
    this.onboardingFormSlideTwo.get('cap').setValue(cap);
    this.onboardingFormSlideTwo.get('country').setValue(country);
  }

  nextWithBtn() {
    this.ionSlides.slideNext();
    this.next();
  }

  next() {
    this.ionSlides.getActiveIndex()
      .then((index: number) => {
        console.log('>>> slider index', index);
        switch(index) {
          case 0: { 
            this.titleService.setTitle(`slide ${index}`); 
            break;
          }
          case 1: {
            this.storage.set('onboardingFormSlideOne', this.onboardingFormSlideOne.value)
              .then(() => { 
                this.onboarding.firstName = this.onboardingFormSlideOne.get('firstName').value; 
                this.onboarding.lastName = this.onboardingFormSlideOne.get('lastName').value;
                this.onboarding.birthPlace = this.onboardingFormSlideOne.get('birthPlace').value;
                let sampleDate: string =  this.onboardingFormSlideOne.get('birthDate').value;
                this.onboarding.birthDate = sampleDate ? moment(sampleDate, 'DD-MM-YYYY').toDate() : null;

                this.syncData(this.onboarding);
                this.googleAnalyticsService.trackClickEvent('event', 'swipe', 'from slide 0 to slide 1');
                this.titleService.setTitle(`slide ${index}`); 
                }
              );
            break;
          }
          case 2: {
            this.storage.set('onboardingFormSlideTwo', this.onboardingFormSlideTwo.value)
              .then(() => {
                this.onboarding.address = new Address();

                if (this.onboardingFormSlideTwo.get('city').value === '') { 
                  this.onboarding.address.city = null 
                } else { 
                  this.onboarding.address.city = this.onboardingFormSlideTwo.get('city').value
                };
                // console.log('address.city', this.onboarding.address.city);

                if (this.onboardingFormSlideTwo.get('cap').value === '') { 
                  this.onboarding.address.cap = null 
                } else { 
                  this.onboarding.address.cap = this.onboardingFormSlideTwo.get('cap').value
                };
                // console.log('address.cap', this.onboarding.address.cap);

                this.onboarding.address.street = this.onboardingFormSlideTwo.get('street').value;
                if (this.onboardingFormSlideTwo.get('street').value === '') { 
                  this.onboarding.address.street = null 
                } else { 
                  this.onboarding.address.street = this.onboardingFormSlideTwo.get('street').value
                };
                // console.log('address.street', this.onboarding.address.street);

                if (this.onboardingFormSlideTwo.get('country').value === '') { 
                  this.onboarding.address.country = null 
                } else { 
                  this.onboarding.address.country = this.onboardingFormSlideTwo.get('country').value
                };
                // console.log('address.country', this.onboarding.address.country);
                this.syncData(this.onboarding);
                this.googleAnalyticsService.trackClickEvent('event', 'swipe', 'from slide 1 to slide 2');
                this.titleService.setTitle(`slide ${index}`); 
                }
              );
            break;
          }
          case 3: {
            this.storage.set('onboardingFormSlideThree', this.onboardingFormSlideThree.value)
              .then(() => {
                this.onboarding.taxCode= this.onboardingFormSlideThree.get('taxCode').value;
                // console.log('taxCode', this.onboarding.taxCode );
                this.onboarding.cellPhone = this.onboardingFormSlideThree.get('cellPhone').value;
                // console.log('cellPhone', this.onboarding.cellPhone );
                this.onboarding.job = this.onboardingFormSlideThree.get('job').value;
                // console.log('job', this.onboarding.job);
                this.onboarding.commuter = this.onboardingFormSlideThree.get('commuter').value;
                // console.log('commuter', this.onboarding.commuter);
                
                this.syncData(this.onboarding);
                this.googleAnalyticsService.trackClickEvent('event', 'swipe', 'from slide 2 to slide 3');
                this.titleService.setTitle(`slide ${index}`); 
                }
              );
            break;
          }
          default: { 
          };
        }
      }
    );
  }

  async syncData(onboarding: Onboarding) {
    try {
      await this.onboardingService.create(onboarding);
    } catch (err) {
      console.log('>>>err ', err);
    }
  }

  async jumpToAuth0Page() {
    if (!this.onboardingFormSlideOne.valid || !this.onboardingFormSlideTwo.valid || !this.onboardingFormSlideThree.valid) {
      setFormTouched(this.onboardingFormSlideOne);
      setFormTouched(this.onboardingFormSlideTwo);
      setFormTouched(this.onboardingFormSlideThree);
      // this.alert.toastWarning('WARNING_FORM_INVALID');
      if (!this.onboardingFormSlideOne.valid) {
        this.ionSlides.slideTo(0, 1000);
      } else if (!this.onboardingFormSlideTwo.valid) {
        this.ionSlides.slideTo(1, 1000);
      } else if (!this.onboardingFormSlideThree.valid) {
        this.ionSlides.slideTo(2, 1000);
      }
      return;
    }

    if (!this.isAdult()) {
      await this.alertService.translate('ADULT_PROFILE_ERROR');
      return;
    }

    if (this.isRetired()) {
      await this.alertService.translate('RETIRED_PROFILE_ERROR');
      return;
    }

    this.titleService.setTitle('slide 5'); 
    await this.account.loginWithFilledFields('signup', '/profile', this.onboarding.email);
  }

  isAdult() {
    const age = moment().diff(moment(this.onboarding.birthDate, this.dateFormat), 'years');
    return age >= 18;
  }

  isRetired() {
    const age = moment().diff(moment(this.onboarding.birthDate, this.dateFormat), 'years');
    return age > 75;
  }

}
