import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ProfileService } from "src/app/services/profile/profile.service";
import { DOCUMENT_TYPE } from "src/app/constants";
import { SpinnerService } from "src/app/services/spinner/spinner.service";
import { AlertService } from "src/app/services/alert/alert.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Location } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { Platform, ActionSheetController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import {
  Camera,
} from "@ionic-native/camera/ngx";
import { UploadDocument } from "src/app/models/uploadDocument";
import * as moment from "moment";
import { Wallet } from "src/app/models/wallet";
import { Profile } from "src/app/models/profile";
import { DurcService } from "src/app/services/durc/durc.service";

@Component({
  selector: 'app-durc',
  templateUrl: './durc.page.html',
  styleUrls: ['./durc.page.scss'],
})
export class DurcPage implements OnInit, OnDestroy {
  @ViewChild("inputFileDurc", { static: false }) inputFileDurc: ElementRef;

  profile: Profile;

  dataValueChange: Subscription;
  
  durc: string;
  fileName: string;
  fileExt: string;
  defaultFileName: string;
  doc: UploadDocument = {
    type: DOCUMENT_TYPE.Durc,
  };

  constructor(
    private profileService: ProfileService,
    private spinner: SpinnerService,
    private alert: AlertService,
    private auth: AuthService,
    private location: Location,
    protected fb: FormBuilder,
    private platform: Platform,
    private translate: TranslateService,
    private actionSheet: ActionSheetController,
    private camera: Camera,
    private durcService: DurcService
  ) {
  }

  ngOnDestroy() {
    if (this.dataValueChange) {
      this.dataValueChange.unsubscribe();
    }
  }

  async ngOnInit() {
    const profile = await this.profileService.getProfile();
    const { firstName = "", lastName = "" } = profile;
    this.defaultFileName = `VISURA_${firstName}_${lastName}_${moment().format(
      "DD/MMMM/YYYY"
    )}`;
  }

  disabled() {
    return !this.durc;
  }

  showDocumentsStatus() {
    const docs = this.getIDDocuments();
    return docs && docs.length > 0;
  }

  getIDDocuments() {
    // considero solo i documenti di identità, esclusi quelli dell'IBAN
    const { wallet = {} as Wallet } = this.profile || {};
    const { docs = [] } = wallet || {};
    return docs.filter(({ type }) => type !== DOCUMENT_TYPE.CompanyRegistration);
  }

  getLastIDDocument() {
    return [...this.getIDDocuments()].pop();
  }

  async save() {
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();

      this.doc.fileName = `${this.defaultFileName}.${this.fileExt}`;
      const splitFile = this.durc.split(",");
      splitFile.shift();
      this.doc.buffer = splitFile.join("");

      // await this.profileService.uploadDocument(token, this.doc);
      let myToken = '';
      this.durcService.verifyDurc(myToken);

      await this.spinner.dismiss();
      await this.alert.toastSuccess();

      await this.profileService.me(token);
      this.location.back();
    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }

  async chooseFile() {
    if (!this.platform.is("cordova")) {
      this.inputFileDurc.nativeElement.click();
      return;
    }
    try {
      const texts = await this.translate
        .get(["UPLOAD_DOCUMENT", "GALLERY", "CLOSE", "CAMERA", "PDF_DOCUMENT"])
        .toPromise();
      const sheet = await this.actionSheet.create({
        mode: "md",
        header: texts.UPLOAD_DOCUMENT,
        buttons: [
          {
            text: texts.PDF_DOCUMENT,
            icon: "document",
            handler: async () => this.inputFileDurc.nativeElement.click(),
          },
          {
            text: texts.CLOSE,
            role: "cancel",
            icon: "close",
          },
        ],
      });
      await sheet.present();
    } catch (err) {
      console.log(err);
    }
  }

  async inputFileChange(event) {
    const [file = null] = event.target.files || [];
    if (file) {
      this.durc = await this.getBase64(event.target.files[0]);
      this.fileName = file.name;
      this.fileExt = file.name.split(".").pop().toLowerCase();
    }
  }

  getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

}