
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AlertService } from '../alert/alert.service';
import { ProfileService } from '../profile/profile.service';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    protected auth: AuthService,
    protected alert: AlertService,
    protected profile: ProfileService,
    protected storage?: Storage,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    try {
      const loggedIn = await this.auth.isLoggedIn();
      
      if (!loggedIn) {
        await this.alert.showAlertEnterSignup();
        return false;
      }

      if (state.url !== '/profile') {
        const user = await this.profile.getProfile();
        if (!user) {
          await this.alert.redirect(['/profile'], null, 'GUARD_PROFILE');
          return false;
        }
      }

      return true;

    } catch (err) {
      await this.alert.translate('GUARD_LOGGEDIN');
      return false;
    }

    
    /*try {
      const show = await this.storage.get('showLoginAlert');
      const loggedIn = await this.auth.isLoggedIn();

      if(!show) {
        await this.storage.set('showLoginAlert', true);
        return false;
      }

      if (!loggedIn) {
        await this.alert.showAlertEnterSignup();
        return false;
      }

      if (state.url !== '/profile') {
        const user = await this.profile.getProfile();
        if (!user) {
          await this.alert.redirect(['/profile'], null, 'GUARD_PROFILE');
          return false;
        }
      }

      return true;

    } catch (err) {
      await this.alert.translate('GUARD_LOGGEDIN');
      return false;
    }*/

  }
}
