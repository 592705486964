import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Onboarding } from 'src/app/models/onboarding';
const { API_URL, LEMONWAY_CANCEL_URL, LEMONWAY_ERROR_URL, LEMONWAY_SUBSCRIPTION_RETURN_URL } = environment;
// const API_URL = 'http://localhost:3100';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    private http: HttpClient,
  ) {
  }

  create(onboarding: Onboarding) {
    return this.http.post<Onboarding>(`${API_URL}/onboarding`, onboarding).toPromise();
  }

}
