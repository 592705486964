import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert/alert.service';
import { VerifyEmailService } from '../../services/verify-email/verify-email.service';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.page.html',
  styleUrls: ['./verify-email.page.scss'],
})
export class VerifyEmailPage implements OnInit {
  email: string;
  id: number;
  wid: string;
  done = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private alert: AlertService,
    private router: Router,
    private spinner: SpinnerService,
    private verifyEmailService: VerifyEmailService,
  ) {
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.id = +this.activatedRoute.snapshot.queryParamMap.get('id');
    this.wid = this.activatedRoute.snapshot.queryParamMap.get('wid');
    if (!this.email || !this.id || !this.wid) {
      this.goHome();
    }
  }

  async ngOnInit() {
    if (!this.email || !this.id || !this.wid) {
      return;
    }
    await this.spinner.show();
    try {
      await this.verifyEmailService.verifyEmail(this.id, this.email, this.wid);
      this.done = true;
      await this.spinner.dismiss();
    } catch (e) {
      const errorValue = this.spinner.extractError(e);
      await this.spinner.dismissWithAlertError(errorValue);
      await this.goHome();
    }
  }

  goHome() {
    return this.router.navigate(['/'], { replaceUrl: true });
  }
}
