import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { PricePipe } from 'src/app/pipes/price.pipe';
import { Profile } from 'src/app/models/profile';
import { PROFILE_TYPE } from 'src/app/constants';
import { Platform } from '@ionic/angular';
const INPUT_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {

  @Input() profile: Profile;

  show = false;
  boxes = [
    { key: 'tot_payed', value: 0, color: '#51a3a3', price: true, },
    { key: 'tot_received', value: 0, color: '#dfcc74', price: true, },
    { key: 'tot_to_be_received', value: 0, color: '#cb904d', price: true, },
    { key: 'shipment_count', value: 0, color: '#51a3a3', price: false, },
    { key: 'transport_count', value: 0, color: '#dfcc74', price: false, },
  ];
  form: FormGroup;
  from: string;
  to: string;
  minDate = moment().subtract(5, 'years').toDate();

  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private pricePipe: PricePipe,
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private platform: Platform,
  ) {
    this.form = this.fb.group({
      from: [''],
      to: [''],
    });
  }

  async ngOnInit() {

    try {
      const token = await this.auth.getToken();
      const query: { from?: string; to?: string } = {};
      if (this.from) {
        query.from = this.from;
      }
      if (this.to) {
        query.to = this.to;
      }
      const report = await this.profileService.report(token, query);

      for (const key of Object.keys(report)) {
        const box = this.boxes.find(b => b.key === key);
        if (box) {
          box.value = box.price ? this.pricePipe.transform(report[key]) : report[key];
        }
      }
      this.show = true;
    } catch (err) {
      console.log(err);
    }
  }

  async setFrom(fromDate?: Date) {
    if (fromDate) {
      this.from = fromDate.toISOString();
      this.form.get('from').setValue(moment(fromDate).format(INPUT_FORMAT));
    } else {
      this.from = '';
      this.form.get('from').setValue('');
    }
    await this.spinner.show();
    await this.ngOnInit();
    await this.spinner.dismiss();
  }

  async setTo(toDate?: Date) {
    if (toDate) {
      this.to = toDate.toISOString();
      this.form.get('to').setValue(moment(toDate).format(INPUT_FORMAT));
    } else {
      this.to = '';
      this.form.get('to').setValue('');
    }
    await this.spinner.show();
    await this.ngOnInit();
    await this.spinner.dismiss();
  }

  isPrivate() {
    return this.profile && this.profile.type === PROFILE_TYPE.private;
  }

  isDesktop() {
    return this.platform.is('desktop')
  }
}
