import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class ExtLinkService {

  constructor(private iab: InAppBrowser, private translate: TranslateService, private platform: Platform) { }

  call(phone: string) {
    window.open('tel:' + (phone || '').trim().replace(/ /g, '').replace(/\(|\)/g, ''), '_system');
  }

  email(address: string, subject?: string, body?: string) {
    if (body && (this.platform.is('android') || this.platform.is('ios'))) {
      body = body.replace(/\n/g, '<br>');
    }

    let uri = `mailto:${address}?`;
    if (subject) {
      uri += `subject=${encodeURIComponent(subject.trim())}`;
    }
    if (body) {
      uri += `&body=${encodeURIComponent(body.trim())}`;
    }
    this.iab.create(uri, '_system');
  }

  openBrowser(url: string, target?: string) {
    const close = this.translate.instant('CLOSE');
    this.iab.create(url, target || '_blank', `location=no,closebuttoncaption=${close}`);
  }

}
