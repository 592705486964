import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import Auth0Cordova from '@auth0/cordova';
import { environment } from '../../../environments/environment';
const { AUTH0_DOMAIN, AUTH0_CLIENT_ID_NATIVE, BUNDLE_ID } = environment;
import { Storage } from '@ionic/storage';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { Platform, Events } from '@ionic/angular';
import { SocialProfile } from 'src/app/models/profile';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
}) export class AuthNativeService extends AuthService {

  auth0Client = new Auth0Cordova({
    clientID: AUTH0_CLIENT_ID_NATIVE,
    clientId: AUTH0_CLIENT_ID_NATIVE,
    domain: AUTH0_DOMAIN,
    packageIdentifier: BUNDLE_ID,
  });

  constructor(
    public storage: Storage,
    private safari: SafariViewController,
    private platform: Platform,
    private events: Events,
  ) {
    super();
  }

  async isLoggedIn(): Promise<boolean> {
    const token = await this.storage.get('access_token');
    const expiresAt = await this.storage.get('expires_at');
    return token && Date.now() < JSON.parse(expiresAt);
  }

  async getToken(): Promise<string> {
    return await this.storage.get('access_token');
  }

  login(screenHint = 'login') {
    return new Promise<void>((resolve, reject) => {
      const options = {
        scope: 'openid profile email',
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        screen_hint: screenHint,
      };
      this.auth0Client.authorize(options, async (err, authResult) => {
        if (err) {
          return reject(err);
        }

        // Set access token
        await this.storage.set('access_token', authResult.accessToken);
        await this.storage.set('id_token', authResult.idToken);

        // Set access token expiration
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        await this.storage.set('expires_at', expiresAt);

        this.events.publish('loggedIn');
        return resolve();
      });
    });
  }

  async logout() {
    await this.storage.remove('access_token');
    await this.storage.remove('expires_at');
    await this.storage.remove('id_token');

    const returnTo = `${BUNDLE_ID}://${AUTH0_DOMAIN}/cordova/${BUNDLE_ID}/callback`;
    const logoutUrl = `https://${AUTH0_DOMAIN}/v2/logout?client_id=${AUTH0_CLIENT_ID_NATIVE}&returnTo=${returnTo}`;

    this.events.publish('loggedIn');

    if (this.platform.is('android')) {
      window.open(logoutUrl, '_system');
      return;
    }

    const available = await this.safari.isAvailable();
    if (available) {
      this.safari.show({ url: logoutUrl })
        .subscribe((result: any) => {
          if (result.event === 'loaded') {
            console.log('event', result.event);
            this.safari.hide();
          }
        }, (error: any) => console.error(error));
    } else {
      window.open(logoutUrl, '_system');
    }
  }

  async handleAuthCallback() {
    return Promise.resolve('/');
  }

  async getSocialProfile(): Promise<SocialProfile> {
    const idToken = await this.storage.get('id_token');
    const token = jwtDecode(idToken);
    const { family_name = '', given_name = '', birthdate } = token;
    return {
      firstName: given_name || '',
      lastName: family_name || '',
      birthDate: birthdate || '',
    };
  }

  async loginWithFilledFields(screenHint?: string, redirect?: string, value?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const options = {
        scope: 'openid profile email',
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        screen_hint: screenHint,
        login_hint: value,
        prompt: 'login'
      };
      this.auth0Client.authorize(options, async (err, authResult) => {
        if (err) {
          return reject(err);
        }

        // Set access token
        await this.storage.set('access_token', authResult.accessToken);
        await this.storage.set('id_token', authResult.idToken);

        // Set access token expiration
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        await this.storage.set('expires_at', expiresAt);

        this.events.publish('loggedIn');
        return resolve();
      });
    });
  }

}
