import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Profile } from 'src/app/models/profile';
import { AlertService } from 'src/app/services/alert/alert.service';
import { PROFILE_TYPE } from 'src/app/constants';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'profile-create-success',
  templateUrl: './profile-create-success.component.html',
  styleUrls: ['./profile-create-success.component.scss'],
})
export class ProfileCreateSuccessComponent implements OnInit {

  profile: Profile;

  constructor(
    private modal: ModalController,
    private profileService: ProfileService,
    private alert: AlertService,
    private router: Router,
    private spinner: SpinnerService,
    private auth: AuthService,
    private payment: PaymentService,
  ) {
  }

  async ngOnInit() {
    try {
      this.profile = await this.profileService.getProfile();
      if (!this.profile) { throw new Error(); }
    } catch (err) {
      console.log(err);
    }
  }

  async ok() {
    const { type = '' } = this.profile;
    if (type === PROFILE_TYPE.company) {
      this.dismiss();
      return;
    }

    if (type === PROFILE_TYPE.private) {
      await this.alert.confirm({
        titleKey: 'DO_YOU_WANT_CARRY',
        messageKey: 'DO_YOU_WANT_CARRY_MESSAGE',
        okTextKey: 'YES',
        cancelTextKey: 'NO',
        cancelHandler: () => this.dismiss(),
        okHandler: () => this.dismiss('/identity-card')
      });
      return;
    }

    if (type === PROFILE_TYPE.professional) {
      await this.alert.confirm({
        messageKey: 'SUBSCIRPTION_MESSAGE',
        okTextKey: 'ENABLE_SUBSCRIPTION',
        cancelTextKey: 'CANCEL',
        cancelHandler: () => this.dismiss('/identity-card'),
        okHandler: async () => {
          await this.dismiss();

          try {
            await this.spinner.show();
            const token = await this.auth.getToken();

            const { paymentFormUrl } = await this.profileService.subscription(token);
            this.payment.pay(
              paymentFormUrl,
              () => this.router.navigate(['/thankyou-subscription'], { queryParams: { isCreating: 'true' } }),
              () => this.alert.translate('PAYMENT_ERROR_MESSAGE'),
            );

            await this.spinner.dismiss();

          } catch (err) {
            await this.spinner.dismissWithAlertError(err);
          }
        }
      });
      return;
    }
  }

  async dismiss(url?: string) {
    const { type = '' } = this.profile;
    await this.modal.dismiss({ type });
    if (url) {
      this.router.navigate([url], { queryParams: { isCreating: 'true' } });
    } else {
      this.router.navigate(['../tabs/home']);
    }
  }

}
