import { Component, Input } from '@angular/core';

@Component({
  selector: 'enter-chat',
  templateUrl: './enter-chat.component.html',
  styleUrls: ['./enter-chat.component.scss'],
})
export class EnterChatComponent {

  @Input() travelId: number;

  constructor(
  ) { }
}
