import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'back-to-button',
  templateUrl: './back-to-button.component.html',
  styleUrls: ['./back-to-button.component.scss'],
})
export class BackToButtonComponent {

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit() {
    }

    backToApp() {
        this.router.navigate(['../tabs/home']);
    }
}