import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const { ONE_SIGNAL_APP_ID, ONE_SIGNAL_PROJECT_NUMBER, API_URL } = environment;
import { OneSignal, OSNotification, OSNotificationOpenedResult, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { Router } from '@angular/router';
import { LocalNotifications, ILocalNotification } from '@ionic-native/local-notifications/ngx';
import { NOTIFICATION_TYPE } from 'src/app/constants';
import { Events } from '@ionic/angular';
import { HttpResponse, HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Notification } from 'src/app/models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private oneSignal: OneSignal,
    private router: Router,
    private localNotifications: LocalNotifications,
    private events: Events,
    private http: HttpClient,
  ) { }

  init() {
    this.oneSignal.startInit(ONE_SIGNAL_APP_ID, ONE_SIGNAL_PROJECT_NUMBER);
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
    this.oneSignal.handleNotificationReceived().subscribe((notification: OSNotification) => this.receiveNotification(notification));
    this.oneSignal.handleNotificationOpened().subscribe((result: OSNotificationOpenedResult) => this.openNotification(result.notification));
    this.oneSignal.endInit();

    this.localNotifications.on('click').subscribe((event: ILocalNotification) => {
      const { data, id, } = event;
      this.goTo(data);
    });
  }

  setTag(id: string) {
    this.oneSignal.sendTag('user_id', id);
  }

  removeTag() {
    this.oneSignal.deleteTag('user_id');
  }

  async receiveNotification(notification: OSNotification) {
    const { isAppInFocus, payload = {} as OSNotificationPayload, androidNotificationId = 0 } = notification;
    if (!isAppInFocus) { return; }

    const { additionalData = {}, body = '', title = '' } = payload || {};
    const { resourceId = '', type = '' } = additionalData;

    if (type !== NOTIFICATION_TYPE.chat) {
      this.events.publish('carry:refresh', { id: resourceId });
      this.events.publish('send:refresh', { id: resourceId });

      if (type === NOTIFICATION_TYPE.priceProposal) {
        this.events.publish('route:refresh', { travelId: resourceId });
      } else {
        this.events.publish('route:refresh', { id: resourceId });
      }
    }

    const permission = await this.localNotifications.hasPermission();
    if (!permission) { return; }

    // Se ho l'app aperta nella pagina della chat non mostro nessuna notifica relativa al travel
    if (this.router.url === `/chat/${resourceId}`) {
      if (type === NOTIFICATION_TYPE.chat || type === NOTIFICATION_TYPE.priceProposal) {
        return;
      }
      if (type === NOTIFICATION_TYPE.travelCancel) {
        this.events.publish('travel:cancelled', { id: resourceId });
        return;
      }
      if (type === NOTIFICATION_TYPE.travelClosed) {
        this.events.publish('travel:closed', { id: resourceId });
        return;
      }
    }

    // Se pagamento effettuato e sono ancora nella pagina della chat
    if (type === NOTIFICATION_TYPE.paymentSuccessful) {
      const { travelId = '' } = additionalData;
      if (this.router.url === `/chat/${travelId}`) {
        this.events.publish('travel:paymentSuccessful', { id: travelId });
      }
    }

    this.localNotifications.schedule({
      id: Math.random() * 10000,
      title,
      text: body,
      icon: 'res://ic_stat_onesignal_default',
      smallIcon: 'res://ic_stat_onesignal_default',
      data: additionalData,
      foreground: true,
    });

  }

  openNotification(notification: OSNotification) {
    const { payload = {} as OSNotificationPayload } = notification || {};
    const { additionalData = {} } = payload || {};
    this.goTo(additionalData);
  }

  goTo(additionalData: any) {
    const { resourceId = '', type = '' } = additionalData;

    switch (type) {
      case NOTIFICATION_TYPE.match:
      case NOTIFICATION_TYPE.paymentSuccessful:
      case NOTIFICATION_TYPE.shipmentPickedUp:
      case NOTIFICATION_TYPE.shipmentDelivered:
        this.router.navigate(['/route-detail', resourceId]);
        break;
      case NOTIFICATION_TYPE.chat:
      case NOTIFICATION_TYPE.priceProposal:
        this.router.navigate(['/chat', resourceId]);
        break;
      case NOTIFICATION_TYPE.travelCancel:
      case NOTIFICATION_TYPE.travelClosed:
        this.router.navigate(['/tabs/home']);
        break;
      case NOTIFICATION_TYPE.deadline:
        this.router.navigate(['/deadlines']);
        break;
    }
  }

  async fetch({ token, offset, limit }: { token: string, offset: number, limit: number }): Promise<HttpResponse<Notification[]>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    let params = new HttpParams();
    if (Number(offset)) {
      params = params.append('offset', offset.toString());
    }
    if (Number(limit)) {
      params = params.append('limit', limit.toString());
    }

    // return Promise.resolve(new HttpResponse({
    //   body: [{
    //     type: NOTIFICATION_TYPE.priceProposal,
    //     resourceId: '3',
    //     title: {
    //       it: 'Ciao Ciao Ciao Ciao Ciao Ciao Ciao Ciao Ciao Ciao',
    //       en: 'Hello'
    //     },
    //     description: {
    //       it: 'Prova Prova Prova Prova Prova Prova Prova Prova',
    //       en: 'Test'
    //     },
    //     date: new Date()
    //   }],
    //   headers: new HttpHeaders({ ['x-total-count']: '1' })
    // }));

    return this.http.get<Notification[]>(`${API_URL}/notification`, { params, headers, observe: 'response' }).toPromise();
  }
}
