import { Injectable } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(
    private googleAnalytics: GoogleAnalytics
  ) { 
  }

  setAnonymizeIp() {
    this.googleAnalytics.setAnonymizeIp(true)
      .then(() => { console.log('Google analytics function is ready now'); })
      .catch(e => { console.log('Error run GoogleAnalytics function', e); });
  }

  startTrackerWithId(s: string) {
    this.googleAnalytics.startTrackerWithId(s)
      .then(() => { console.log('Google analytics is ready now'); })
      .catch(e => { console.log('Error starting GoogleAnalytics', e); });
  }

  setUserId() {
    // this.googleAnalytics.setUserId(); // set string as a unique identifier
  }

  trackView(s: string) {
    this.googleAnalytics.trackView(s, undefined, false);
  }

  trackClickEvent(
    eventName: string = 'event', 
    eventType: string,
    eventDescription: string, 
    eventLabel?: string,
    eventCategory?: string, 
    eventValue?: number,
    isComesFromNative?: boolean
    ) {
      if (isComesFromNative) {
        this.googleAnalytics.trackEvent('button', eventType, `${eventDescription}`)
          .then(() => { console.log('Track click event correctly'); })
          .catch(e => { console.log('Error starting trackEvent', e); });
      } else {
        gtag(eventName, 'level_start', { levelName: `${eventType}` });
      }
  } 

}
