import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExtLinkService } from 'src/app/services/ext-link/ext-link.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {

  PHONE = '(+39) 3201918580';
  SITE = 'https://www.siwego.com';
  EMAIL = environment.SUPPORT_EMAIL;

  constructor(
    private modal: ModalController,
    private extLink: ExtLinkService,
  ) { }

  close() {
    this.modal.dismiss();
  }

  call() {
    this.extLink.call(this.PHONE);
  }

  openSite() {
    this.extLink.openBrowser(this.SITE);
  }

  email() {
    this.extLink.email(this.EMAIL);
  }
}
