import { Pipe, PipeTransform } from '@angular/core';
import { TRANSPORT_TYPE } from '../constants';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'transport'
})
export class TransportPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) { }

  transform(value: string, args?: any): any {

    const key = value || 'all';
    if (Object.keys(TRANSPORT_TYPE).indexOf(key) === -1) {
      return '';
    }
    return this.translate.instant(`TRANSPORT_TYPES.${key}`);
  }

}
