import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Route } from 'src/app/models/route';
import { TRAVEL_STATUS, ROUTE_STATE, ROUTE_TYPE } from 'src/app/constants';
import { Events, PopoverController } from '@ionic/angular';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { isMyRoute, getRouteColor } from 'src/app/utils/route';
import { Travel } from 'src/app/models/travel';
import { TransportTypePopoverComponent } from 'src/app/components/transport-type-popover/transport-type-popover.component';

@Component({
  selector: 'app-route-detail',
  templateUrl: 'route-detail.page.html',
  styleUrls: ['route-detail.page.scss']
})
export class RouteDetailPage implements OnDestroy {

  route: Route;
  profile: Profile;
  activeTravel: Travel;
  rate: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private routeService: RouteService,
    private auth: AuthService,
    private events: Events,
    profileService: ProfileService,
    private cd: ChangeDetectorRef,
    private popover: PopoverController,
  ) {

    profileService.getProfile().then(p => this.profile = p);
    events.subscribe('route:refresh', ({ id, travelId }) => {
      if (!this.route) { return; }
      if (id === this.route.id || this.route.travels.find(travel => travel.id === travelId)) {
        this.fetchRoute(this.route.id);
      }
    });
  }

  ngOnDestroy() {
    this.events.unsubscribe('route:refresh');
  }

  async ionViewDidEnter() {
    await this.refresh();
  }

  async refresh() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.fetchRoute(id);
  }

  async fetchRoute(id: number | string) {
    if (!id) { return; }
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const route = await this.routeService.get(token, id);
      setTimeout(() => {
        this.route = this.filterMyTravel(route);
        this.cd.detectChanges();
      });
      await this.spinner.dismiss();
    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }

  /** Nasconde le offerte non mie */
  filterMyTravel(route: Route) {
    const { travels = [] } = route || {};
    if (!isMyRoute(route, this.profile) && travels.length > 0) {
      route.travels = travels.filter(travel => {
        const { actor = {} as Profile } = travel;
        const { id } = this.profile;
        return actor.id === id;
      });
    }
    return route;
  }

  isSend() {
    return this.route && this.route.type === ROUTE_TYPE.send;
  }

  isCarry() {
    return this.route && this.route.type === ROUTE_TYPE.carry;
  }

  isWaiting() {
    return this.route && this.route.state === ROUTE_STATE.waiting;
  }

  isActive() {
    return this.route && this.route.state === ROUTE_STATE.active;
  }

  isDelivered() {
    return this.route && this.route.state === ROUTE_STATE.delivered;
  }

  getColor() {
    return getRouteColor(this.route, this.profile);
  }

  getActiveTravel() {
    const { travels = [] } = this.route;
    const travel = travels.find(({ travelStatus }) => {
      const activeStatus = [TRAVEL_STATUS.waiting_pickup, TRAVEL_STATUS.in_transit, TRAVEL_STATUS.delivered];
      return activeStatus.includes(travelStatus);
    });
    this.activeTravel = travel;
    return travel;
  }

  onSuccessCode() {
    const topic = this.isSend() ? 'send:upsert' : 'carry:upsert';
    this.events.publish(topic, { id: this.route.id });

    this.ionViewDidEnter();
  }

  hasChat() {
    const { travelStatus = TRAVEL_STATUS.idle } = this.activeTravel || {};
    return !this.isDelivered() && [TRAVEL_STATUS.waiting_pickup, TRAVEL_STATUS.in_transit].includes(travelStatus);
  }

  async showTransportType(event: any) {
    const popover = await this.popover.create({
      event,
      component: TransportTypePopoverComponent,
      componentProps: {
        type: this.route.transportType,
      },
      translucent: true,
      animated: true,
      mode: 'ios',
    });
    return await popover.present();
  }

  isLoggedUserRoute(): boolean {
    return isMyRoute(this.route, this.profile)
  }

  getEnterChatId(): number {
    if (!this.route) {
      return 0;
    }
    if (isMyRoute(this.route, this.profile)) {
      return 0;
    }
    const { travels = [], state = ROUTE_STATE.waiting } = this.route
    if (state === ROUTE_STATE.delivered) {
      return 0;
    }
    const travel = travels.find(t => {
      const { actor = {} as Profile } = t;
      const { id } = this.profile;
      return actor.id === id;
    })
    const { id = 0 } = travel || {}
    return id;
  }
}