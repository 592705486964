import { FormGroup } from '@angular/forms';

export const setFormTouched = (form: FormGroup) => {
  if (form && form.markAllAsTouched) {
    form.markAllAsTouched();
  }
  const items = document.body.getElementsByTagName('ion-item');
  for (const item of Array.from(items)) {
    item.className = item.className.replace(/\bion-untouched\b/g, 'ion-touched');
  }
};
