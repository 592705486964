import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
  })
export class OnboardinGuard implements CanActivate {

    constructor(
        private router: Router,
        private storage: Storage,
    ) { 
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isShowed = await this.storage.get('showOnboarding');
        if (isShowed) {
            this.router.navigate(['../tabs/home']);
            return false;
        } 
        return true;
    }

}