import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertConfirmOptions } from './interfaces';
import { AccountService } from '../auth/account.service';
import { GeoLocationService } from '../geolocation/geolocation.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alert: AlertController,
    private translations: TranslateService,
    private translateParser: TranslateParser,
    private router: Router,
    public toastController: ToastController,
    public account: AccountService,
    public geolocation: GeoLocationService,
  ) { }

  translate(key: string, titleKey?: string, innerValue?: string) {

    return this.translations.get(['APP_NAME', 'CLOSE', 'ERRORS.DETAIL', key, titleKey || ''])
      .toPromise()
      .then(translations => {

        const header = translations[titleKey || 'APP_NAME'];
        const subHeader = translations[key];
        const buttons = [translations.CLOSE];
        const alertParams = { header, subHeader, buttons };

        if (innerValue) {
          const message = this.translateParser.interpolate(translations['ERRORS.DETAIL'], { value: innerValue });
          Object.assign(alertParams, { message });
        }

        return this.alert.create(alertParams)
          .then(alert => alert.present());
      });
  }

  async redirect(route: string[], titleKey?: string, messageKey?: string) {
    const translations = await this.translations.get(['APP_NAME', titleKey || '', messageKey, '']).toPromise();
    const header = translations[titleKey || 'APP_NAME'];
    const message = translations[messageKey];
    const buttons = [{
      text: 'OK',
      handler: () => this.router.navigate(route, { replaceUrl: true }),
    }];
    const alertParams = { header, message, buttons, backdropDismiss: false };
    const alert = await this.alert.create(alertParams);
    return await alert.present();
  }

  async showAlert401() {
    const translations = await this.translations.get(['APP_NAME', 'ERRORS.UNAUTHORIZED']).toPromise();
    const header = translations.APP_NAME;
    const message = translations['ERRORS.UNAUTHORIZED'];
    const buttons = [{
      text: 'OK',
      handler: async () => await this.account.login(),
    }];
    const alertParams = { header, message, buttons, backdropDismiss: false };
    const alert = await this.alert.create(alertParams);
    return await alert.present();
  }

  async showAlertEnterSignup() {
    const translations = await this.translations.get(['APP_NAME', 'GUARD_LOGGEDIN', 'ENTER', 'SIGNUP']).toPromise();
    const header = translations.APP_NAME;
    const message = translations.GUARD_LOGGEDIN;
    const buttons = [
      {
        text: translations.ENTER,
        handler: async () => await this.account.login(),
      },
      {
        text: translations.SIGNUP,
        handler: async () => await this.account.login('signup'),
      }
    ];
    const alertParams = { header, message, buttons };
    const alert = await this.alert.create(alertParams);
    return await alert.present();
  }

  async showBackgroundAuthorizeError() {
    const translations = await this.translations.get(['APP_NAME', 'PREFERENCE_TRACKING_UNAUTHORIZED', 'CLOSE']).toPromise();
    const header = translations.APP_NAME;
    const message = translations.PREFERENCE_TRACKING_UNAUTHORIZED;
    const buttons = [
      {
        text: translations.CLOSE,
        role: 'cancel',
      },
      {
        text: 'OK',
        handler: async () => await this.geolocation.showSettings(),
      }];
    const alertParams = { header, message, buttons, backdropDismiss: false };
    const alert = await this.alert.create(alertParams);
    return await alert.present();
  }

  async confirm(options: AlertConfirmOptions) {
    const { titleKey, messageKey, okTextKey, cancelTextKey, okHandler, cancelHandler } = options;

    const t = await this.translations.get([
      titleKey || 'APP_NAME',
      messageKey,
      okTextKey || 'OK',
      cancelTextKey || 'CANCEL'
    ]
    ).toPromise();

    const alert = await this.alert.create({
      header: t[titleKey || 'APP_NAME'],
      message: t[messageKey],
      buttons: [
        {
          text: t[cancelTextKey || 'CANCEL'],
          role: 'cancel',
          handler: () => {
            if (cancelHandler) { cancelHandler(); }
          }
        }, {
          text: t[okTextKey || 'OK'],
          handler: () => {
            if (okHandler) { okHandler(); }
          }
        }
      ]
    });

    await alert.present();
  }

  async toastSuccess(messageKey = 'GENERIC_SUCCESS_MESSAGE', duration = 2000) {
    const t = await this.translations.get([messageKey]).toPromise();
    const toast = await this.toastController.create({
      message: t[messageKey],
      duration,
      color: 'success',
    });
    toast.present();
  }

  async toastWarning(messageKey: string, duration = 2000, position?: 'top' | 'bottom' | 'middle') {
    const t = await this.translations.get([messageKey]).toPromise();
    const toast = await this.toastController.create({
      message: t[messageKey],
      duration,
      color: 'warning',
      position: position || 'top',
    });
    toast.present();
  }

}
