export class Onboarding {
    email?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: Date | string;
    birthPlace?: string;
    address?: Address;
    taxCode?: string;
    cellPhone?: string;
    job?: string;
    commuter?: string;
    privacyPolicy?: boolean;
    termsAndConditions?: boolean;
    marketing?: boolean;
    thirdParty?: boolean;
  }
  
  export class Address {
    city?: string;
    cap?: string;
    street?: string;
    country?: string;
  }