import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TravelService } from 'src/app/services/travel/travel.service';
import { Travel, TravelTotals } from 'src/app/models/travel';
import { ROUTE_TYPE, TRAVEL_STATUS } from 'src/app/constants';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.page.html',
  styleUrls: ['./payment-summary.page.scss'],
})
export class PaymentSummaryPage implements OnInit {

  travel: Travel;
  fee: number;
  toPay: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private auth: AuthService,
    private travelService: TravelService,
    private router: Router,
    private payment: PaymentService,
  ) {
  }

  async ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('travelId');
    if (!id) { return; }

    try {
      await this.spinner.show();

      const token = await this.auth.getToken();
      this.travel = await this.travelService.get(token, id);

      const { travelStatus, route, priceUpdatedAt } = this.travel;
      if (travelStatus !== TRAVEL_STATUS.idle || !priceUpdatedAt) {
        await this.router.navigate(['/route-detail', route.id]);
        await this.spinner.dismiss();
        return;
      }

      this.calcPrices();
      await this.spinner.dismiss();

    } catch (err) {
      console.log(err);
      await this.spinner.dismissWithAlertError(err);
    }
  }

  getCarrier() {
    if (!this.travel) { return null; }

    const { route, actor } = this.travel;
    const { type, owner } = route;
    if (type === ROUTE_TYPE.carry) {
      return owner;
    } else {
      return actor;
    }
  }

  calcPrices() {
    const { price, totals = {} as TravelTotals } = this.travel;
    this.fee = totals.fee || 0;
    this.toPay = totals.toPay || price;
  }

  async pay() {
    if (!this.travel) { return; }

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const { paymentFormUrl } = await this.travelService.pay(token, this.travel.id);

      this.payment.pay(
        paymentFormUrl,
        () => this.router.navigate(['/thankyou'], { replaceUrl: true }),
        () => this.router.navigate(['/payment-error'], { replaceUrl: true }),
      );

      await this.spinner.dismiss();

    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }
}
