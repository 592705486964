import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Route } from 'src/app/models/route';
import { TRAVEL_STATUS, ROUTE_TYPE } from 'src/app/constants';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertController, Platform } from '@ionic/angular';
import { Travel } from 'src/app/models/travel';
import { Profile } from 'src/app/models/profile';
import { isMyRoute } from 'src/app/utils/route';
import { TranslateService } from '@ngx-translate/core';
import { TravelService } from 'src/app/services/travel/travel.service';
import { Router } from '@angular/router';
import { DEFAULT_LANG, TranslateConfigService } from 'src/app/services/translate/translate-config.service';
import { shareText } from 'src/app/utils/route';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'route-active',
  templateUrl: './route-active.component.html',
  styleUrls: ['./route-active.component.scss'],
})
export class RouteActiveComponent {

  @Input() route: Route;
  @Input() travel: Travel;
  @Input() profile: Profile;
  @Output() successCode: EventEmitter<Profile> = new EventEmitter<Profile>();

  constructor(
    private alert: AlertService,
    private spinner: SpinnerService,
    private auth: AuthService,
    private translate: TranslateService,
    private alertController: AlertController,
    private travelService: TravelService,
    private router: Router,
    private translateConfig: TranslateConfigService,
    private platform: Platform,
    private social: SocialSharing,
  ) { }

  isMyRoute() {
    return isMyRoute(this.route, this.profile);
  }

  isSend() {
    return this.route && this.route.type === ROUTE_TYPE.send;
  }

  isCarry() {
    return this.route && this.route.type === ROUTE_TYPE.carry;
  }

  isWaiting() {
    const { travelStatus = TRAVEL_STATUS.idle } = this.travel;
    return travelStatus === TRAVEL_STATUS.waiting_pickup;
  }

  isInTransit() {
    const { travelStatus = TRAVEL_STATUS.idle } = this.travel;
    return travelStatus === TRAVEL_STATUS.in_transit;
  }

  async insertCode(type: string) {

    const title = type === 'pickup' ? 'PICKUP_PACKAGE_MESSAGE' : 'DROPOFF_PACKAGE_MESSAGE';
    const subtitle = type === 'pickup' ? 'INSERT_PICKUP_SECRET_CODE' : 'INSERT_DROPOFF_SECRET_CODE';

    const texts = await this.translate.get([title, subtitle, 'CANCEL', 'OK']).toPromise();
    const alert = await this.alertController.create({
      header: texts[title],
      subHeader: texts[subtitle],
      inputs: [
        {
          name: 'code',
          type: 'number',
        },
      ],
      buttons: [
        {
          text: texts.CANCEL,
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: texts.OK,
          handler: async ({ code }) => {
            try {
              await this.spinner.show();
              const token = await this.auth.getToken();

              if (type === 'pickup') {
                await this.travelService.pickup(token, this.travel.id, code);
              } else {
                const data = await this.travelService.dropoff(token, this.travel.id, code);
                this.showDropoffMessage(data);
              }

              await this.spinner.dismiss();
              await this.alert.toastSuccess();

              this.successCode.emit();

            } catch (err) {
              await this.spinner.dismissWithAlertError(err);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async tracking() {
    await this.router.navigate(['/map', this.travel.id]);
  }

  async showDropoffMessage(data) {
    if (!data || !data.message || typeof data.message !== 'object') {
      return;
    }

    const { message } = data;
    const lang = this.translateConfig.getCurrentLanguage();
    const result = message[lang] || message[DEFAULT_LANG] || '';
    if (!result) {
      return;
    }
    const appName = this.translate.instant('APP_NAME');

    const alert = await this.alertController.create({
      header: appName,
      message: result,
      buttons: ['OK'],
    });

    await alert.present();
  }

  copyToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    const success = document.execCommand('copy');
    document.body.removeChild(textArea);
    if (success) {
      this.alert.translate('SHARE.SUCCESS');
    }
  }

  async share() {
    const text = shareText(this.route, this.travel, this.translate)
    await this.platform.ready()

    try {
      if (this.platform.is('cordova')) {
        this.social.share(text)
      } else {
        this.copyToClipboard(text)
      }
    } catch (error) {
      console.log(error)
    }
  }
}
