import { Component, OnInit } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform, Events } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AccountService } from 'src/app/services/auth/account.service';
const { APP_NAME } = environment;
import { version } from '../../../../package.json';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage implements OnInit {

  version = version;
  appName = APP_NAME;
  loggedIn = false;

  constructor(
    platform: Platform,
    appVersion: AppVersion,
    events: Events,
    private auth: AuthService,
    private account: AccountService,
  ) {

    if (platform.is('cordova')) {
      platform.ready()
        .then(() => appVersion.getVersionNumber())
        .then(version => this.version = version)
        .catch(err => console.log('Error in version number', err));
    }

    events.subscribe('loggedIn', () => this.ngOnInit());
  }

  async ngOnInit() {
    this.loggedIn = await this.auth.isLoggedIn();
  }

  enter() {
    this.account.login();
  }

  signup() {
    this.account.login('signup');
  }
}
