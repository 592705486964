import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsizeString'
})

export class EllipsizeStringPipe implements PipeTransform {
  
  transform(value: any, ...args: any[]): any {
    return this.shortTitle(value);
  }

  shortTitle(s: string): string {
    return (s.length > 28) ? s.substring(0,28) + "..." : s;
  }

}
