import { OnInit } from '@angular/core';
import { Route } from 'src/app/models/route';
import { Location } from '@angular/common';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RouteService } from 'src/app/services/routes/route.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Router } from '@angular/router';
import { Profile } from 'src/app/models/profile';

export class RouteFoundPage implements OnInit {

  route: Route;
  user: Profile;
  token: string;

  constructor(
    protected location: Location,
    protected spinner: SpinnerService,
    protected auth: AuthService,
    protected profile: ProfileService,
    protected routeService: RouteService,
    protected alert: AlertService,
    protected router: Router,
  ) { }

  ngOnInit() {
    this.route = this.location.getState() as Route;
  }

  async match() {
    const { id } = this.route;
    if (!id) { return; }

    try {
      await this.spinner.show();
      this.token = await this.auth.getToken();
      if (!this.token) {
        throw new Error()
      }
    } catch (error) {
      await this.spinner.dismiss();
      await this.alert.showAlertEnterSignup();
      return;
    }

    try {
      this.user = await this.profile.getProfile();
      if (!this.user) {
        throw new Error()
      }
      await this.spinner.dismiss();
    } catch (error) {
      await this.spinner.dismiss();
      await this.alert.redirect(['/profile'], null, 'GUARD_PROFILE');
      return;
    }

    await this.alert.confirm({
      messageKey: 'MATCH_CONFIRM',
      okHandler: async () => {
        try {
          await this.spinner.show();
          const travel = await this.routeService.match(this.token, id) || {};

          await this.spinner.dismiss();

          this.router.navigate([`/chat/${travel.id}`], { replaceUrl: true });

        } catch (err) {
          console.log('error', err)
          const { status } = err;

          /* Se è un errore nel match, cerco se ho già fatto match precedentemente e lo mando alla chat */
          if (status === 400) {
            try {
              const route = await this.routeService.get(this.token, this.route.id);
              const { travels = [] } = route;
              const travel = travels.find(t => t.actor && t.actor.id === this.user.id);
              if (travel && travel.id) {
                await this.spinner.dismiss();
                this.router.navigate([`/chat/${travel.id}`], { replaceUrl: true });
                return;
              }
            } catch (e) {
            }
          }

          this.spinner.dismissWithAlertError(err);
        }
      }
    });
  }
}
