import { Pipe, PipeTransform } from '@angular/core';
import { TRANSPORT_TYPE } from '../constants';

@Pipe({
  name: 'transportIcon'
})
export class TransportIconPipe implements PipeTransform {

  constructor() { }

  transform(value: string, args?: any): any {
    switch (value) {
      case TRANSPORT_TYPE.foot_cycle:
        return 'assets/icon/transports/foot_cycle.svg';
      case TRANSPORT_TYPE.city_car:
        return 'assets/icon/transports/city_car.svg';
      case TRANSPORT_TYPE.wagon:
        return 'assets/icon/transports/wagon.svg';
      case TRANSPORT_TYPE.suv:
        return 'assets/icon/transports/suv.svg';
      case TRANSPORT_TYPE.luxury:
        return 'assets/icon/transports/luxury.svg';
      case TRANSPORT_TYPE.van:
        return 'assets/icon/transports/van.svg';
      case TRANSPORT_TYPE.truck:
        return 'assets/icon/transports/truck.svg';
      case TRANSPORT_TYPE.other:
        return 'assets/icon/transports/other.svg';
      case TRANSPORT_TYPE.all:
      default:
        return 'assets/icon/transports/city_car.svg';
    }
  }

}
