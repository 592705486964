import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/auth/account.service';
import { LOGO_PATH } from 'src/app/constants';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({ 
  selector: 'app-start',
  templateUrl: './start.page.html',
  styleUrls: ['./start.page.scss'],
})
export class StartPage implements OnInit {

  logoPath = LOGO_PATH;

  constructor(
    private router: Router,
    private storage: Storage,
    private accountService: AccountService,
  ) {
  }

  async ngOnInit() {
    await this.storage.set('showOnboarding', true);
  }

  async jumpToLoginPage() {
    await this.accountService.loginWithFilledFields('login');
  }

  jumpToIntroPage() {
    this.router.navigate(['onboarding/intro']);
  }
  
}
