import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Client } from 'twilio-chat'; // Chat js web
import { Storage } from '@ionic/storage';
import { ChatToken } from 'src/app/models/chatToken';
import jwtDecode from 'jwt-decode';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
const { API_URL } = environment;
declare let TwilioChat: any; // plugin native

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private storage: Storage,
  ) {
  }

  async getClient(chatToken) {
    if (this.platform.is('cordova')) {
      return await TwilioChat.Client.create(chatToken);
    }
    return await Client.create(chatToken);
  }

  async getChannel(chatToken: string, channelSid: string) {
    const client = await this.getClient(chatToken);
    return await client.getChannelBySid(channelSid);
  }

  async getChatToken(token: string): Promise<string> {
    try {

      const savedChatToken = await this.storage.get('chat-token');
      if (savedChatToken) {
        const { exp } = jwtDecode(savedChatToken);
        if (Date.now() < exp * 1000) {
          return savedChatToken;
        }
      }

      const authHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
      const { token: chatToken } = await this.http.get<ChatToken>(`${API_URL}/chat/twilio-token`, { headers: authHeaders }).toPromise();
      await this.storage.set('chat-token', chatToken);
      return chatToken;
    } catch (err) {
      await this.storage.remove('chat-token');
      throw err;
    }
  }

  async clear(): Promise<any> {
    await this.storage.remove('chat-token');
  }
}
