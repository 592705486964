import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const { API_URL } = environment;
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Deadline } from '../../models/Deadline';

@Injectable({
  providedIn: 'root'
})
export class DeadlineService {

  constructor(private http: HttpClient) {}

  postDeadline(token: string, deadline: Deadline) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
      .post<Deadline>(`${API_URL}/deadlines`, deadline, { headers })
      .toPromise();
  }

  putDeadline(token: string, id: number, deadline: Deadline) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
        .put<Deadline>(`${API_URL}/deadlines/${id}`, deadline, { headers })
        .toPromise();
  }

  deleteDeadline(token: string, id: number) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
        .delete<Deadline>(`${API_URL}/deadlines/${id}`, { headers })
        .toPromise();
  }

  getDeadlines(token: string, deadlinesParams: any) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const { limit = 20, offset = 0 } = deadlinesParams || {};
    const params = new HttpParams({
      fromObject: { limit, offset },
    });
    return this.http
      .get<Deadline[]>(`${API_URL}/deadlines`, { headers, params, observe: 'response' })
      .toPromise();
  }
}
