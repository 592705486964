import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.page.html',
  styleUrls: ['./callback.page.scss'],
})
export class CallbackPage implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  async ngOnInit() {
    const targetRoute = await this.auth.handleAuthCallback();
    this.router.navigate([targetRoute], { replaceUrl: true });
  }

}
