import { AvatarService } from 'src/app/services/profile/avatar.service';
import { Injectable } from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { ChatService } from '../chat/chat.service';
import { NotificationService } from '../notification/notification.service';
import { AuthService } from './auth.service';
import { GeoLocationService } from '../geolocation/geolocation.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private profile: ProfileService,
    private chat: ChatService,
    private avatarService: AvatarService,
    private notification: NotificationService,
    private geolocation: GeoLocationService,
    private auth: AuthService,
    private router: Router,
    private platform: Platform,
  ) {
  }

  async logout() {
    await this.profile.removeProfile();
    await this.chat.clear();
    await this.avatarService.clear();
    this.notification.removeTag();
    this.auth.logout();
    this.geolocation.clear();
  }

  async login(screenHint?: string) {
    try {

      await this.auth.login(screenHint);

      if (!this.platform.is('cordova')) {
        return;
      }

      const token = await this.auth.getToken();

      if (!token) {
        throw new Error('No exists');
      }

      const prof = await this.profile.me(token);

      if (!prof || !prof.type) {
        this.router.navigate(['/profile']);
      } else {
        const { id } = prof;
        this.notification.setTag(id.toString());
        this.avatarService.load();
      }

    } catch (err) {
      console.log(err);
    }
  }

  async loginWithFilledFields(screenHint?: string, redirect?: string, value?: string) {
    try {
      await this.auth.loginWithFilledFields(screenHint, redirect, value);

      if (!this.platform.is('cordova')) {
        return;
      }

      const token = await this.auth.getToken();
      if (!token) {
        throw new Error('No exists');
      }

      const prof = await this.profile.me(token);
      if (!prof || !prof.type) {
        this.router.navigate(['/profile']);
      } else {
        const { id } = prof;
        this.notification.setTag(id.toString());
        this.avatarService.load();
      }
      
    } catch (err) {
      console.log(err);
    }
  }

}
