import { Directive, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactsComponent } from '../components/contacts/contacts.component';

@Directive({
  selector: '[appContacts]'
})
export class ContactsDirective {

  constructor(protected modal: ModalController) {
  }

  @HostListener('click')
  async onClick() {
    const modal = await this.modal.create({
      component: ContactsComponent,
      cssClass: 'modal-html',
    });

    return await modal.present();
  }
}
