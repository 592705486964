import { Component, ChangeDetectorRef } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalController, Platform } from '@ionic/angular';
import { ProfileSelectTypeComponent } from 'src/app/components/profile-select-type/profile-select-type.component';
import { ModalContractComponent } from 'src/app/components/modal-contract/modal-contract.component';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Router } from '@angular/router';
import { PROFILE_TYPE } from 'src/app/constants';
import { ProfileCreateSuccessComponent } from 'src/app/components/profile-create-success/profile-create-success.component';
import { GeoLocationService } from 'src/app/services/geolocation/geolocation.service';
import { AccountService } from 'src/app/services/auth/account.service';
import jwtDecode from 'jwt-decode';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { RatingsKeys } from 'src/app/models/profile';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-profile',
  templateUrl: 'profile.page.html',
  styleUrls: ['profile.page.scss']
})
export class ProfilePage {

  segment = '1';
  profile: Profile;
  modalType;
  trackingEnabled: boolean;
  email: string;

  profileTypeFromOnboarding: boolean = false;

  constructor(
    private profileService: ProfileService,
    private auth: AuthService,
    private modal: ModalController,
    private spinner: SpinnerService,
    private alert: AlertService,
    private router: Router,
    private geolocation: GeoLocationService,
    private cd: ChangeDetectorRef,
    private account: AccountService,
    private platform: Platform,
    private notification: NotificationService,
    private storage: Storage,
  ) {
    this.initProfile();
  }

  get isCordova() {
    return this.platform.is('cordova');
  }

  async initProfile() {
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      console.log('>>>token initProfile()', token);
      const decoded = jwtDecode(token);
      console.log('>>>decoded', decoded);
      this.email = decoded['https://www.siwego.com/email'];
      this.profile = await this.profileService.me(token);
      console.log('>>>>>>profile', this.profile);
      this.cd.detectChanges();

      await this.grabOnboardData();
      
      this.trackingEnabled = await this.geolocation.isEnabledBackground();
      await this.spinner.dismiss();

      if (this.profile) { // reset tag OneSignal
        this.notification.setTag(this.profile.id.toString());
      }

    } catch (err) {
      console.log(err);
      await this.spinner.dismissWithAlertError(err);
    }
  }

  async grabOnboardData() {
    this.storage.get('profileType')
      .then((value) => {
        console.log('>>>profileType', value);
        console.log('>>>profile', this.profile);
        if (value == null) {
          if (!this.profile || !this.profile.type) {
            this.openSelectType();
          }
        } else { 
          this.profileTypeFromOnboarding = true;
  
          console.log('outside seTimeout', this.profile);
          setTimeout(() => { // TODO soluzione brutale. Senza timeout c'è un qualcosa di asincrono che manda in errore la this.profile
            console.log('pre', this.profile);
            this.profile = { type: PROFILE_TYPE.private };
            console.log('post', this.profile);
          }, 1500);

          console.log('>>>>>>>>>>profile', this.profile);
        }
      }).then(() => {
        this.storage.set('profileType', null);
        }
      );
  }

  onSubmit(values) {
    if (this.isCreating() && this.isProfessional()) {
      this.openAcceptContract(values);
      return;
    }
    this.save(values);
  }

  async save(values) {
    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      if (this.isCreating()) {
        console.log('>>>>>>isCreating', this.isCreating());
        const body = { ...values, type: this.profile.type };
        console.log('>>>>>>body', body);
        console.log('>>>>>>token', token);
        await this.profileService.create(token, body);
        console.log('>>>STEP 1');
        await this.initProfile();
        console.log('>>>STEP 2');
        await this.openCreateSuccess();
        console.log('>>>STEP 3');
      } else {
        await this.profileService.update(token, values);
        await this.initProfile();
        await this.alert.toastSuccess();
      }
    } catch (err) {
      console.log('>>>STEP 4');
      await this.spinner.dismissWithAlertError(err);
    }
  }

  async openSelectType() {
    if (!this.modalType) {
      this.modalType = await this.modal.create({
        backdropDismiss: false,
        showBackdrop: false,
        component: ProfileSelectTypeComponent,
      });
    }

    this.modalType.onDidDismiss()
      .then(async ({ data }: { data: { type: PROFILE_TYPE } }) => {
        // TODO capire come fare il logout se non completo il mio profilo
        // if (!data || !data.type) {
        //   this.location.back();
        //   return;
        // }
        if (data && data.type) {
          let socialProfile = {};
          try {
            socialProfile = await this.auth.getSocialProfile();
          } catch (e) { }
          this.profile = { type: data.type, ...socialProfile };
          console.log('>>>>>>this.profile in onDidDismiss()', this.profile);
        }
      });

    return await this.modalType.present();
  }

  isCreating() {
    return !this.profile || !this.profile.id;
  }
  isPrivate() {
    return this.profile && this.profile.type === PROFILE_TYPE.private;
  }
  isCompany() {
    return this.profile && this.profile.type === PROFILE_TYPE.company;
  }
  isProfessional() {
    return this.profile && this.profile.type === PROFILE_TYPE.professional;
  }

  async openAcceptContract(values) {
    const modal = await this.modal.create({
      backdropDismiss: false,
      showBackdrop: false,
      component: ModalContractComponent,
    });

    modal.onDidDismiss()
      .then(({ data }) => {
        console.log('>>>', data);
        if (!data || !data.accept) { return; }

        this.save(values);
      });

    return await modal.present();
  }

  async logout() {
    await this.alert.confirm({
      messageKey: 'LOGOUT_MESSAGE',
      okHandler: async () => {
        await this.account.logout();
        this.router.navigate(['/'], { replaceUrl: true });
      }
    });
  }

  async openCreateSuccess() {
    const modal = await this.modal.create({
      backdropDismiss: false,
      showBackdrop: false,
      component: ProfileCreateSuccessComponent,
    });
    return await modal.present();
  }

  async onChangeTracking(event) {
    if (!event.target.checked) {
      this.geolocation.stopBackground();
      return;
    }
    try {
      const authorized = await this.geolocation.isBackgroundAuthorized();
      if (!authorized) {
        throw new Error('Not Authorized');
      }
      await this.geolocation.startBackground();
    } catch (err) {
      event.target.checked = false;
      this.alert.showBackgroundAuthorizeError();
    }
  }

  async onSubscriptionSuccess() {
    await this.initProfile();
    this.router.navigate(['/thankyou-subscription'], { replaceUrl: true });
  }

  segmentChanged(event) {
    this.segment = event.target.value;
  }

  hideRatings() {
    return !this.profile;
  }
}
