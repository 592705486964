import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalDateTimeComponent } from '../components/modal-select-datetime/modal-datetime.component';
import { Moment } from 'moment';

@Directive({
  selector: '[appSelectDate]'
})
export class SelectDateDirective {

  @Input() titleKey: string;
  @Input() minDate: Date;
  @Input() maxDate?: Date;
  @Output() selected: EventEmitter<Moment> = new EventEmitter<Moment>();

  constructor(private modal: ModalController) {
  }

  @HostListener('click')
  async onClick() {
    const modal = await this.modal.create({
      backdropDismiss: false,
      cssClass: 'modal-selectdate',
      showBackdrop: true,
      component: ModalDateTimeComponent,
      componentProps: {
        maxDate: this.maxDate,
        minDate: this.minDate,
        title: this.titleKey,
      }
    });

    modal.onDidDismiss()
      .then(({ data }) => {
        if (!data || !data.date) { return; }
        this.selected.emit(data.date);
      });

    return await modal.present();
  }
}
