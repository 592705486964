import { Component, Input, OnInit } from '@angular/core';
import { Route } from 'src/app/models/route';
import { Travel } from 'src/app/models/travel';
import { Profile } from 'src/app/models/profile';
import { isMyRoute } from 'src/app/utils/route';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RatingService } from 'src/app/services/rating/rating.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { Ratings } from 'src/app/models/profile';

@Component({
  selector: 'route-delivered',
  templateUrl: './route-delivered.component.html',
  styleUrls: ['./route-delivered.component.scss'],
})
export class RouteDeliveredComponent implements OnInit {

  @Input() route: Route;
  @Input() travel: Travel;
  @Input() profile: Profile;

  hideRatings = false;
  ratings: Ratings = {
    availability: 0,
    courtesy: 0,
    punctuality: 0,
    readiness: 0,
  };

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private ratingService: RatingService,
    private spinnerService: SpinnerService,
  ) { }

  async ngOnInit() {
    const { id: travelId } = this.travel;
    if (!travelId) { return; }
    try {
      const token = await this.authService.getToken();
      const ratings = await this.ratingService.getTravelRating(token, travelId) || {};
      // Deprecated vote was already given. Hide new ratings to prevent errors.
      this.hideRatings = !!(ratings as any).vote;
      Object.keys(this.ratings).forEach(key => {
        this.ratings[key] = ratings[key] || this.ratings[key];
      });
      this.ratings = { ...this.ratings };
    } catch (error) {}
  }

  getOtherProfile() {
    if (isMyRoute(this.route, this.profile)) {
      const { actor } = this.travel;
      return actor;
    }
    const { owner } = this.route;
    return owner;
  }

  onRateChange(givenRating: number, ratingKey: keyof Ratings) {
    this.ratings[ratingKey] = Math.ceil(givenRating);
    if (!Object.keys(this.ratings).every(key => this.ratings[key])) {
      // If not all ratings are set, do nothing else.
      return;
    }
    const { id: travelId } = this.travel;
    const titleKey = 'RATING_MSG_TITLE';
    const messageKey = 'RATING_MSG';
    const cancelHandler = () => {
      Object.keys(this.ratings).forEach(key => {
        this.ratings[key] = 0;
      });
      this.ratings = { ...this.ratings };
    };
    const okHandler = async () => {
      await this.spinnerService.show();
      try {
        const token = await this.authService.getToken();
        await this.ratingService.postRating(token, travelId, this.ratings);
        await this.spinnerService.dismissWithAlert('RATING_SUCCESS', 'THANKYOU');
      } catch (error) {
        cancelHandler();
        await this.spinnerService.dismissWithAlertError(error);
      }
    };
    this.alertService.confirm({ messageKey, titleKey, cancelHandler, okHandler });
  }

  hasGivenRating() {
    return Object.keys(this.ratings).some(key => this.ratings[key]);
  }
}
