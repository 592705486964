import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

const firstUpper = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

@Injectable()
export class MyDatepickerI18n extends NgbDatepickerI18n {

  getDayAriaLabel(date: NgbDateStruct): string {
    const { year = 0, month = 0, day = 0 } = date || {};
    return `${day}-${month}-${year}`;
  }

  getWeekdayShortName(weekday: number): string {
    const weekdays = moment.weekdaysShort(true).map(firstUpper);
    return weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    const months = moment.monthsShort().map(firstUpper);
    return months[month - 1];
  }
  getMonthFullName(month: number): string {
    const months = moment.months().map(firstUpper);
    return months[month - 1];
  }
}
