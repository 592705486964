import { Route } from '../models/route';
import { Profile } from '../models/profile';
import { Travel } from '../models/travel';
import { ChatMessage, ChatMessageAttribute } from '../models/chatMessage';

export const getChatMember = (travel: Travel, profile: Profile): Profile => {
  const { actor = {} as Profile, route = {} as Route } = travel || {};
  const { owner = {} as Profile } = route || {};
  const { id = '' } = profile || {};

  const { id: actorId } = actor;

  if (id === actorId) {
    return owner;
  }

  return actor;
};

export const isPriceMessage = (message: ChatMessage) => {
  const { attributes = {} as ChatMessageAttribute } = message || {};
  const { type = '' } = attributes || {};
  return type === 'set_price';
};
