import { Component, } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { TravelService } from 'src/app/services/travel/travel.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert/alert.service';
declare const google: any;
const zoom = 15;

@Component({
  selector: 'app-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
})
export class MapPage {

  map: any;
  marker: any;
  position;
  infowindow: any;
  updatedAt: Date;

  constructor(
    private platform: Platform,
    private spinner: SpinnerService,
    private auth: AuthService,
    private travelService: TravelService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private alert: AlertService,
  ) {
  }

  ionViewDidEnter() {
    this.loadMap();
  }

  async loadMap() {

    await this.getTravel();

    this.map = this.createMap();
    this.addMarker();
    this.updateMarker();
  }

  async getTravel() {
    const id = this.activatedRoute.snapshot.paramMap.get('travelId');

    try {
      await this.spinner.show();
      const token = await this.auth.getToken();
      const travel = await this.travelService.get(token, id);
      if (travel && travel.location) {
        this.position = {
          lat: travel.location.latitude,
          lng: travel.location.longitude,
        };
        this.updatedAt = travel.location.updatedAt;
      } else {
        this.alert.toastWarning('NO_LOCATION_FOUND', 3000, 'bottom');
      }
      await this.spinner.dismiss();
    } catch (err) {
      await this.spinner.dismissWithAlertError(err);
    }
  }

  createMap() {
    const ROME = { lat: 41.9109, lng: 12.4818 };
    return new google.maps.Map(document.getElementById('map'), {
      zoom: 5,
      center: ROME,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
    });
  }

  addMarker() {
    if (!this.position) {
      return;
    }

    this.infowindow = new google.maps.InfoWindow();
    this.marker = new google.maps.Marker({
      position: this.position,
      map: this.map
    });
    this.marker.addListener('click', () => {
      this.infowindow.open(this.map, this.marker);
    });
  }

  updateMarker() {
    if (!this.position) {
      return;
    }

    if (!this.marker) {
      this.addMarker();
    }

    this.marker.setPosition(this.position);

    const content = `
      <p>
        ${this.translate.instant('LAST_LOCATION_DATE')}
        <br/>
        <b>${moment(this.updatedAt).format('DD/MM/YYYY HH:mm')}</b>
      </p>
    `;

    this.infowindow.setContent(content);
    this.map.setCenter(this.position);
    this.map.setZoom(zoom);
  }

  async refresh() {
    await this.getTravel();
    this.updateMarker();
  }

}
