import { Directive } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalHtmlDirective } from './modal-html.directive';

@Directive({
  selector: '[appTermsConditions]'
})

export class TermsConditionsDirective extends ModalHtmlDirective {

  constructor(protected modal: ModalController) {
    super(modal);

    this.titleKey = 'TERMS_AND_CONDITIONS';
    this.contentKeys = [
      'TERMS_AND_CONDITIONS_TEXT_1',
      'TERMS_AND_CONDITIONS_TEXT_2',
    ];
  }
}
