import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'city'
})
export class CityPipe implements PipeTransform {

  constructor() { }

  transform(value: string): any {
    const addressFormatted = value || '';
    const splitted = addressFormatted.split(',');
    if (Array.isArray(splitted)) {
      splitted.splice(0, splitted.length - 3);
      return splitted.join(',');
    }
    return addressFormatted;
  }

}
