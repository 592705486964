import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
})
export class FormErrorMessageComponent {

  @Input() control: AbstractControl;
  @Input() patternErrorKey: string;

  constructor(
    private translate: TranslateService,
  ) { }

  getErrors(): string[] {
    return Object.keys(this.control.errors)
      .filter(field => this.control.errors[field])
      .map(field => {

        let errorKey = field;
        if (Number(field) >= 0) {
          const error = this.control.errors[field];
          errorKey = error && Object.keys(error)[0];
        }

        const { requiredLength, min, max } = this.control.errors[field];

        switch (errorKey) {
          case 'required':
            return this.translate.instant('FORM_ERRORS.REQUIRED');
          case 'minlength':
            return this.translate.instant('FORM_ERRORS.MIN_LENGTH', { length: requiredLength });
          case 'maxlength':
            return this.translate.instant('FORM_ERRORS.MAX_LENGTH', { length: requiredLength });
          case 'min':
            return this.translate.instant('FORM_ERRORS.MIN', { min });
          case 'max':
            return this.translate.instant('FORM_ERRORS.MAX', { max });
          case 'email':
            return this.translate.instant('FORM_ERRORS.EMAIL');
          case 'pattern':
            return this.translate.instant(this.patternErrorKey || 'Pattern error');
          case 'pecsdi':
            return this.translate.instant('FORM_ERRORS.PECSDI');
        }

        return 'Generic error';
      });
  }

}
