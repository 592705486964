import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JOB_TYPES, COMMUTERS_TYPES } from '../options';
import { ModalController, Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ProfileFormBaseComponent } from '../profile-form-base.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ExtLinkService } from 'src/app/services/ext-link/ext-link.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AvatarService } from 'src/app/services/profile/avatar.service';
import { toBase64File } from './../../../utils/file';
import moment from 'moment';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'profile-form-private',
  templateUrl: './profile-form-private.component.html',
  styleUrls: ['./../profile-form-base.component.scss'],
})
export class ProfileFormPrivateComponent
  extends ProfileFormBaseComponent
  implements OnInit {
  @ViewChild('inputFile', { static: false }) inputFile: ElementRef;

  jobTypes = JOB_TYPES;
  commutersTypes = COMMUTERS_TYPES;

  constructor(
    protected fb: FormBuilder,
    protected modal: ModalController,
    protected translate: TranslateService,
    protected alert: AlertService,
    protected profileService: ProfileService,
    protected avatarService: AvatarService,
    protected extLink: ExtLinkService,
    protected storage: Storage,
    private camera: Camera,
    private auth: AuthService,
    private platform: Platform,
  ) {
    super(fb, modal, translate, alert, extLink, storage);
  }

  ngOnInit() {
    super.ngOnInit();
    const disabled = !this.isCreating();

    this.form.removeControl('taxCode');
    this.form.addControl('taxCode', this.fb.control({ value: '', disabled }, Validators.required));

    this.form.addControl('job', this.fb.control({ value: '', disabled }, Validators.required));
    this.form.addControl('commuter', this.fb.control({ value: COMMUTERS_TYPES[0], disabled }, Validators.required));

    if (this.profile) {
      const { birthDate } = this.profile;
      this.form.patchValue({
        ...this.profile,
        birthDate: birthDate ? moment(birthDate).format(this.dateFormat) : birthDate,
      });
    }
  }

  isCordova() {
    return this.platform.is('cordova');
  }

  uploadPhoto() {
    this.inputFile.nativeElement.click();
  }

  async takePhoto() {
    const options: CameraOptions = {
      quality: 100,
      targetHeight: 200,
      targetWidth: 200,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
    };

    try {
      const imageData = await this.camera.getPicture(options);
      await this.uploadAvatar('data:image/jpeg;base64,' + imageData);
    } catch (error) {
      this.alert.toastWarning('GENERIC_ERROR');
    }
  }

  async loadFile(event) {
    try {
      const files = event.target.files;
      const imageData = await toBase64File(files[0]);
      await this.uploadAvatar(imageData);
    } catch (error) {
      this.alert.toastWarning('GENERIC_ERROR');
    }
  }

  async uploadAvatar(imageData: string) {
    try {
      const token = await this.auth.getToken();
      await this.avatarService.uploadAvatar(token, imageData);
    } catch (error) {
      if (error.status === 413) {
        return this.alert.toastWarning('WARNING_PAYLOAD_TOO_LARGE');
      }
      this.alert.toastWarning('GENERIC_ERROR');
    }
  }
}
