import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
export const DEFAULT_LANG = 'en';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(DEFAULT_LANG);
  }

  setBrowserLanguage() {
    const ALLOWED_LANGS = ['en', 'it', 'is', 'ro', 'fr'];

    const lang = this.translate.getBrowserLang();
    const language = ALLOWED_LANGS.indexOf(lang) > -1 ? lang : DEFAULT_LANG;

    moment.locale(language);
    this.translate.use(language);
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang || DEFAULT_LANG;
  }

}
