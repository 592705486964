import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, IonRefresher } from '@ionic/angular';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Notification } from 'src/app/models/notification';
import { TranslateService } from '@ngx-translate/core';
const LIMIT = 10;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {

  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher, { static: false }) refresher: IonRefresher;

  offset = 0;
  notifications: Notification[] = [];
  lang = 'it';

  constructor(
    public spinner: SpinnerService,
    private notificationService: NotificationService,
    private auth: AuthService,
    private translate: TranslateService,
  ) {
    this.lang = this.translate.getDefaultLang();
  }

  async ngOnInit() {
    this.fetch();
  }

  onRefresh() {
    this.notifications = [];
    this.offset = 0;
    this.fetch();

    this.refresher.complete();
  }

  async fetch() {

    try {
      await this.spinner.show();

      const token = await this.auth.getToken();

      const params = {
        token,
        limit: LIMIT,
        offset: this.offset
      };

      const { body, headers } = await this.notificationService.fetch(params);

      this.notifications = this.notifications.concat(body);
      this.offset += body.length;
      const count = parseInt(headers.get('x-total-count'), 10);
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = this.notifications.length >= count;

      await this.spinner.dismiss();
    } catch (err) {
      this.infiniteScroll.disabled = true;
      this.infiniteScroll.complete();
      this.spinner.dismissWithAlertError(err);
    }

  }

  goToDetail(notification: Notification) {
    const { resourceId = '' } = notification;
    if (!resourceId) {
      return;
    }
    this.notificationService.goTo(notification);
  }

}
