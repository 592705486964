import { Component, Input } from '@angular/core';
import { Route } from 'src/app/models/route';
import { Router } from '@angular/router';

@Component({
  selector: 'route-card-found',
  templateUrl: './route-card-found.component.html',
  styleUrls: ['./route-card-found.component.scss'],
})
export class RouteCardFoundComponent {

  @Input() route: Route;

  constructor(
    private router: Router
  ) { }

  goToDetail() {
    const { type = '' } = this.route || {};
    this.router.navigate([`/${type}-detail`], { state: this.route });
  }
}
