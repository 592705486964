import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Events } from '@ionic/angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'swg-rating',
  templateUrl: './swg-rating.component.html',
  styleUrls: ['./swg-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SwgRatingComponent,
      multi: true
    }
  ]
})
export class SwgRatingComponent implements ControlValueAccessor, OnInit {

  public readonly eventInfo = (() => {
    const id = new Date().getTime();
    const topic = `star-rating:${id}:changed`;
    return {
      topic
    };
  })();

  // tslint:disable-next-line:variable-name
  private _rating: number;
  private onChange: any;
  private onTouched: any;
  public disabled: boolean;

  @Output()
  ratingChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input()
  readonly = 'false';
  @Input()
  activeColor = '#374140';
  @Input()
  defaultColor = '#374140';
  @Input()
  activeIcon = 'assets/icon/gift.svg';
  @Input()
  defaultIcon = 'assets/icon/gift-outline.svg';
  @Input()
  halfIcon = 'assets/icon/gift-half.svg';
  @Input()
  halfStar = 'false';
  @Input()
  maxRating = 5;
  @Input()
  fontSize = '28px';
  Math: any;
  parseFloat: any;
  iconsArray: number[] = [];

  constructor(private events: Events) {
    this.Math = Math;
    this.parseFloat = parseFloat;
  }

  ngOnInit(): void {
    this.rating = typeof this.rating === 'number' ? this.rating : 5; // default after input`s initialization
    for (let i = 0; i < this.maxRating; i++) {
      this.iconsArray.push(i);
    }
  }

  writeValue(obj: number): void {
    this.rating = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled ? 'true' : 'false';
  }

  @Input() public set rating(val: number) {
    this._rating = val;
    // for form
    if (this.onChange) {
      this.onChange(val);
    }
  }

  public get rating(): number {
    return this._rating;
  }

  changeRating(event) {

    if (this.readonly && this.readonly === 'true') { return; }
    // event is different for firefox and chrome
    const id = event.target.id ? parseInt(event.target.id, 10) : parseInt(event.target.parentElement.id, 10);
    if (this.halfStar && this.halfStar === 'true') {
      this.rating = ((this.rating - id > 0) && (this.rating - id <= 0.5)) ? id + 1 : id + .5;
    } else {
      this.rating = id + 1;
    }

    // subscribe this event to get the changed value in your parent compoanent
    this.events.publish(`star-rating:changed`, this.rating); // common event for all instances included for backwards compatibility
    this.events.publish(this.eventInfo.topic, this.rating); // common event for all instances
    // unique event
    this.ratingChanged.emit(this.rating);
  }
}
